import { useCallback, useEffect, useRef, useState } from 'react';
import { usePrevState } from '@hooks/use-prev-value';
import { useElementInViewport } from '@hooks/use-element-in-viewport';

interface IScrollChat {
  lastChildDummyRef: React.RefObject<HTMLDivElement>;
  handleScrollForDummy: () => void;
  turnViewingHistoryOff: () => void;
}

/**
 * Function stops autoscroll if a user's viewing the history
 * @param messagesLength {number} It's needed to understand what type of scroll to use.
 * First fetchMessages() courses instant scroll
 * @return lastChildDummyRef - ref for dummy
 * @return  handleScrollForDummy {Function} - Function to catch if a user's scrolling manually
 * @return  turnViewingHistoryOff - {Function} - Function to enable autoscroll if user sends a message
 */

const useScrollToBottom = (messagesLength: number): IScrollChat => {
  const extraSpace = 75;
  const [previousLength] = usePrevState(messagesLength);
  const lastChildDummyRef = useRef<HTMLDivElement>(null);
  const [isViewingHistory, setIsViewingHistory] = useState(false);
  const isInViewPort = useElementInViewport(lastChildDummyRef.current, extraSpace);
  const turnViewingHistoryOn = useCallback(() => setIsViewingHistory(true), []);
  const turnViewingHistoryOff = useCallback(() => setIsViewingHistory(false), []);

  /**
   * Effect scrolls to bottom after each render if a user isn't viewing history
   */
  useEffect(() => {
    if (lastChildDummyRef.current && !isViewingHistory) {
      lastChildDummyRef.current.scrollIntoView({
        behavior: messagesLength && !previousLength ? 'auto' : 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  });

  /**
   * Function stops autoscroll if a user's viewing the history
   */
  const handleScrollForDummy = (): void => {
    if (lastChildDummyRef.current && isInViewPort()) {
      turnViewingHistoryOff();
    } else {
      turnViewingHistoryOn();
    }
  };

  return {
    lastChildDummyRef,
    handleScrollForDummy,
    turnViewingHistoryOff,
  };
};

export default useScrollToBottom;
