import { Chat } from '@components/chat';
import { ChoosePhone } from '@components/choose-phone';
import { CustomerIdentificationForm } from '@components/customer-identification-form';
import { Rating } from '@components/rating';
import { useBehaviorSubject } from '@hooks/use-behavior-subject';
import { ServiceContext } from '@services/service.provider';
import classnames from 'classnames';
import React, { MouseEvent, useContext, useEffect, useRef, useState } from 'react';
import { Menu } from './menu';
import styles from './router.module.css';
import { RoutesEnum } from './routes.enum';
import { SmsCodeForm } from '@components/sms-code-form';
import { TransferForm } from '@components/transfer-form';
import { TransferConfirmation } from '@components/transfer-confirmation';
import { Icon } from '@components/icon';
import { useCloseChatByHash } from '@hooks/use-close-chat-by-hash';
import { Toggle } from '@ast/magma/components/toggle';
import { useAppSelector } from '@store';
import { ThemeEnum } from '@slices/theme.slice';

type Props = {
  isBrowser: boolean;
  isAndroid: boolean;
  handleThemeChange: () => void;
};

export const Router: React.FunctionComponent<Props> = ({ isBrowser, isAndroid, handleThemeChange }) => {
  const { routerService, settingsService, statusService, twilioConversationsService } = useContext(ServiceContext);
  const { isMenuActive } = routerService;
  const route = useBehaviorSubject(routerService.route$);
  const isOnline = useBehaviorSubject(statusService.isAgentOnline$);
  const [isShowDropdown, setShowDropDown] = useState(false);
  const dropdownAfterToggleTimeoutId = useRef<NodeJS.Timeout | null>(null);
  const { isLiveChat, settings } = settingsService;
  const statusTitle = isOnline ? 'Online' : 'Offline';
  const isOnLiveChatRoute = route === RoutesEnum.Chat && isLiveChat;
  useCloseChatByHash();
  const theme = useAppSelector((state) => state.theme.currentTheme);

  const renderRoute = (): JSX.Element => {
    switch (route) {
      case RoutesEnum.TransferForm:
        return <TransferForm />;
      case RoutesEnum.TransferConfirmation:
        return <TransferConfirmation key="TransferConfirmation" />;
      case RoutesEnum.TransferSuccess:
        return <TransferConfirmation key="TransferConfirmation--success" success />;
      case RoutesEnum.Chat:
        return <Chat isBrowser={isBrowser} />;
      case RoutesEnum.Rating:
        return <Rating />;
      case RoutesEnum.Menu:
        return <Menu />;
      case RoutesEnum.CustomerIdentificationForm:
        return <CustomerIdentificationForm />;
      case RoutesEnum.ChoosePhone:
        return <ChoosePhone />;
      case RoutesEnum.SmsCodeForm:
        return <SmsCodeForm />;
    }
  };

  // Maybe better place to this is router service,
  // but it should be initialized after all
  // and content dependencies
  const handleClose = (): void => {
    switch (route) {
      case RoutesEnum.CustomerIdentificationForm:
      case RoutesEnum.ChoosePhone:
      case RoutesEnum.SmsCodeForm:
      case RoutesEnum.TransferForm:
      case RoutesEnum.TransferConfirmation:
      case RoutesEnum.TransferSuccess:
        routerService.closeApp();
        break;
      case RoutesEnum.Rating:
        twilioConversationsService.endRating();
        routerService.openChat();
        break;
      case RoutesEnum.Chat:
        if (isMenuActive) {
          routerService.openMenu();
        } else {
          routerService.closeApp();
        }
        break;
      case RoutesEnum.Menu:
        routerService.closeApp();
        break;
    }
  };

  const handleClickOnBackGround = (
    e: MouseEvent & { composedPath: () => HTMLElement[]; target: { id: string } },
  ): void => {
    const elements = e.composedPath();
    if (Array.isArray(elements) && elements[0]) {
      const isClickOnDropDown = elements[0].closest(`.${styles.dropDown}`);
      if (!isClickOnDropDown) {
        setShowDropDown(false);
      }
    }
  };
  useEffect(() => {
    const node = document.getElementById('live_chat_application_root');
    if (isShowDropdown) {
      node?.addEventListener('click', handleClickOnBackGround as () => void);
    } else {
      node?.removeEventListener('click', handleClickOnBackGround as () => void);
    }
    return (): void => {
      node?.removeEventListener('click', handleClickOnBackGround as () => void);
    };
  });

  const handleToggleTheme = (): void => {
    const closeDropdownAfterToggle = 800;
    handleThemeChange();
    if (dropdownAfterToggleTimeoutId.current) {
      clearTimeout(dropdownAfterToggleTimeoutId.current);
    }

    dropdownAfterToggleTimeoutId.current = setTimeout(() => {
      setShowDropDown(!isShowDropdown);
    }, closeDropdownAfterToggle);
  };

  return (
    <div className={styles.router}>
      {isBrowser && (
        <div className={styles.header}>
          <button
            onClick={handleClose}
            className={styles.button}
            type="button"
            data-qa="close_chat_window_button"
            aria-label="button, close live chat, press enter to close live chat"
            tabIndex={0}
          >
            <Icon name="close" sizes={[24]} className={styles.iconClose} />
          </button>
          <div className={styles.title} data-qa="router_title">
            <span
              aria-label={`panel, ${routerService.getTitle(route)}, use tab to navigate to the next element`}
              tabIndex={0}
            >
              {routerService.getTitle(route)}
            </span>
            {isOnLiveChatRoute && (
              <span tabIndex={0} aria-label={`status, ${statusTitle}, use tab to navigate to the next element`}>
                {statusTitle}
              </span>
            )}
          </div>
          <div className={styles.options}>
            {settings.BrandingSettings.DarkThemeIsEnabled && (
              <>
                <button className={styles.button} onClick={(): void => setShowDropDown(!isShowDropdown)}>
                  <Icon className={styles.iconOptions} name={'dots'} sizes={[24]} />
                </button>
                {isShowDropdown && (
                  <div className={styles.dropDown}>
                    <span className={styles.dropDownTitle}>Dark theme</span>
                    <Toggle checked={theme === ThemeEnum.DARK} onChange={handleToggleTheme} />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
      <div className={classnames(styles.content, { [styles.compact]: isBrowser, [styles.isAndroid]: isAndroid })}>
        {renderRoute()}
      </div>
    </div>
  );
};
