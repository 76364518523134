import { isUnknownObject } from '@guards/is-unknown-object.guard';
import { isIntent } from '@guards/intent/is-intent.guard';
import { IPartialIntentResponse } from '@models/intent/partial-intent-response.interface';

export function isPartialIntentResponse(object: unknown): object is IPartialIntentResponse {
  return (
    isUnknownObject(object) &&
    'QueryResult' in object &&
    isUnknownObject(object.QueryResult) &&
    'FulfillmentMessages' in object.QueryResult &&
    Array.isArray(object.QueryResult.FulfillmentMessages) &&
    object.QueryResult.FulfillmentMessages.some((entry) => isIntent(entry))
  );
}
