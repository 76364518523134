import { ISpecialErrorResponse } from '@api/specialError';
import { isUnknownObject } from '@guards/is-unknown-object.guard';

export function isSpecialErrorResponse(response: unknown): response is ISpecialErrorResponse {
  return (
    isUnknownObject(response) &&
    'Error' in response &&
    isUnknownObject(response.Error) &&
    'Code' in response.Error &&
    'Message' in response.Error &&
    typeof response.Error.Message === 'string' &&
    typeof response.Error.Code === 'number'
  );
}
