import { useContext } from 'react';
import { ServiceContext } from '@services/service.provider';
import { useBehaviorSubject } from '@hooks/use-behavior-subject';

interface IUseResolve {
  resolveChat(): unknown;
  canResolveChat: boolean;
}

export const useResolve = (): IUseResolve => {
  const { twilioConversationsService } = useContext(ServiceContext);
  const canResolveChat = useBehaviorSubject(twilioConversationsService.canResolve$);

  return {
    resolveChat: (): unknown => twilioConversationsService.resolveChatAndSetGracePeriod(),
    canResolveChat,
  };
};
