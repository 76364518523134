import { Message } from '@models/message.model';
import rootStyles from '@components/root/root.module.css';
import React, { FC } from 'react';

const AriaLastMessage: FC<{ list: Message[] }> = ({ list }) => {
  return (
    <div className={rootStyles['visually-hidden']}>
      <p>Last message: {list[list.length - 1]?.text}</p>
    </div>
  );
};

export { AriaLastMessage };
