import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import styles from './choose-phone.module.css';
import { Scroller } from '@components/scroller';
import { InfoMessage } from '@components/info-message';
import { AbstractStatusEnum } from '@models/abstract-status.enum';
import { Button } from '@components/button';
import { RadioButtonGroup } from '@components/radio-button-group';
import { useAppDispatch, useAppSelector } from '@store';
import { skipChoosePhone, submitChoosePhone } from '@actions/chat.actions';
import { ChatActions } from '@slices/chat.slice';
import { useChoosePhoneError } from './choose-phone.hooks';

const ServerIsntAnsweringMessage = `Server isn't answering`;

export const ChoosePhone: React.FunctionComponent = () => {
  const settings = useAppSelector((state) => state.settings.data?.AnonymCustomerChoosePhoneSettings);
  const isOtpRequested = useAppSelector((state) => state.chat.phonesToChoose.loaded);
  const phoneList = useAppSelector((state) => state.chat.phonesToChoose.data);
  const phones = useMemo(() => Object.keys(phoneList), [phoneList]);
  const [selectedValue, setSelectedValue] = useState('');
  const errorMessage = useChoosePhoneError();
  const dispatch = useAppDispatch();

  const isDisabled = isOtpRequested || !selectedValue;

  const skipHandler = (): void => {
    dispatch(skipChoosePhone());
  };

  const submitHandler = async (event: FormEvent): Promise<void> => {
    event.preventDefault();
    dispatch(submitChoosePhone(selectedValue));
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (isOtpRequested) {
      timer = setTimeout(() => {
        dispatch(ChatActions.setPhonesToChooseError(ServerIsntAnsweringMessage));
      }, 20000);
    }

    return (): void => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [dispatch, isOtpRequested]);

  useEffect(() => {
    return (): void => {
      dispatch(ChatActions.resetPhonesData());
    };
  }, [dispatch]);

  return (
    <Scroller>
      <form className={styles.form} onSubmit={submitHandler}>
        <div
          className={styles.title}
          data-qa="auth_info_title"
          aria-label={`heading, ${settings?.Title}, use tab to navigate to the next element`}
        >
          {settings?.Title}
        </div>
        <div
          className={styles.description}
          data-qa="contact_info_welcome_message"
          aria-label={`sub heading, ${settings?.WelcomeMessage}, use tab to navigate to the next element`}
        >
          {settings?.WelcomeMessage}
        </div>
        <div className={styles.fields} data-qa="select_phone_radio_btns">
          <RadioButtonGroup
            qaLocator="select_phone_radio_btn"
            onChange={setSelectedValue}
            selectedValue={selectedValue}
            name="choosePhoneGroup"
            list={phones}
          />
        </div>
        {errorMessage && <InfoMessage type={AbstractStatusEnum.negative} message={errorMessage} />}
        <div className={styles.buttons} data-qa="select_phone_btns">
          <Button onClick={skipHandler} qaLocator="select_phone_close_btn" ariaLabel={'Close select phone form'}>
            Close
          </Button>
          <Button
            type="submit"
            primary
            disabled={isDisabled}
            qaLocator="select_phone_submit_btn"
            ariaLabel={'Submit select phone form'}
          >
            {isOtpRequested ? 'Loading...' : 'Submit'}
          </Button>
        </div>
      </form>
    </Scroller>
  );
};
