import React from 'react';
import { Message as MessageModel } from '@models/message.model';
import { SystemMessageTypeEnum } from '@models/system-message-type.enum';
import { ContactInfo } from '@components/forms/contact-info';
import { ContactInfoSuccess } from '@components/forms/contact-info-success';
import { InView } from 'react-intersection-observer';

type Props = {
  message: MessageModel;
  isLast: boolean;
  onVisibilityChange(inView: boolean): void;
};

export const SpecialSystemMessage: React.FunctionComponent<Props> = ({ message, isLast, onVisibilityChange }) => {
  switch (message.attributes?.type) {
    case SystemMessageTypeEnum.RequestAnonymCustomerContactInfo:
      return (
        <InView as="div" onChange={onVisibilityChange}>
          <ContactInfo savedContactInfo={message.attributes.anonymCustomerContactInfo} isLast={isLast} />
        </InView>
      );
    case SystemMessageTypeEnum.ResponseAnonymCustomerContactInfo:
      return (
        <InView as="div" onChange={onVisibilityChange}>
          <ContactInfoSuccess attributes={message.attributes} />
        </InView>
      );
    default:
      return null;
  }
};
