import React, { useContext } from 'react';
import classnames from 'classnames';
import messageStyles from '@components/chat/message/message.module.css';
import { useBehaviorSubject } from '@hooks/use-behavior-subject';
import { ServiceContext } from '@services/service.provider';
import { useObservable } from '@hooks/use-observable';

export const LiveChatInitialMessage: React.FunctionComponent = () => {
  const { statusService, quickActionService } = useContext(ServiceContext);
  const initialMessage = useBehaviorSubject(quickActionService.initialMessage$);
  const isChatOnline = useObservable(statusService.isChatOnline$, false);
  const isVisible = isChatOnline && initialMessage;

  return isVisible ? (
    <div
      className={classnames(messageStyles.message, messageStyles.specialist)}
      tabIndex={-1}
      data-message={'message'}
      data-qa="initial_welcome_pseudo_message"
    >
      <div className={classnames(messageStyles.bubble, messageStyles.specialist)}>{initialMessage}</div>
    </div>
  ) : null;
};
