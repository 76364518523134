/**
 * Checks if it's today date
 * @param day - date of a day
 * @param month - month
 * @param year - year
 * @return boolean - if its today date
 * */
export const isToday = (day: number, month: number, year: number): boolean => {
  const now = new Date();
  const nowDate = now.getDate();
  const nowMonth = now.getMonth();
  const nowYear = now.getFullYear();

  return nowDate === day && nowMonth === month && nowYear === year;
};

/**
 * Compares dates and returns a date in string format:
 * "Today" if the next message date is today or date in format "April 19",
 * if the difference in dates a year, the return will be "April 19, 2023"
 * @param currentMessageDate - utc format date
 * @param nextMessageDate - utc format date
 * @param isFirstMessage - if it is the first message
 * @return string - "Today", "April 19" or "April 19, 2023"
 * */

export const compareWithNextMessageDate = (
  currentMessageDate: string,
  nextMessageDate: string,
  isFirstMessage: boolean,
): string => {
  let dateDivider = '';

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  if (isFirstMessage) {
    const currentMessage = new Date(currentMessageDate);
    const currentMessageDay = currentMessage.getDate();
    const currentMessageMonth = currentMessage.getMonth();
    const currentMessageYear = currentMessage.getFullYear();
    const isSameYear = currentMessageYear === new Date().getFullYear();
    const isTodayDate = isToday(currentMessageDay, currentMessageMonth, currentMessageYear);

    dateDivider = isTodayDate
      ? 'Today'
      : `${monthNames[currentMessageMonth]} ${currentMessageDay}${isSameYear ? '' : `, ${currentMessageYear}`}`;
  } else if (currentMessageDate && nextMessageDate) {
    const currentMessage = new Date(currentMessageDate);
    const currentMessageDay = currentMessage.getDate();
    const currentMessageMonth = currentMessage.getMonth();
    const currentMessageYear = currentMessage.getFullYear();

    const nextMessage = new Date(nextMessageDate);
    const nextMessageDay = nextMessage.getDate();
    const nextMessageMonth = nextMessage.getMonth();
    const nextMessageYear = nextMessage.getFullYear();

    const isSameDate =
      currentMessageDay === nextMessageDay &&
      currentMessageMonth === nextMessageMonth &&
      currentMessageYear === nextMessageYear;

    if (!isSameDate) {
      const isSameYear = currentMessageYear === nextMessageYear;
      if (isSameYear) {
        const isTodayDate = isToday(nextMessageDay, nextMessageMonth, nextMessageYear);
        dateDivider = isTodayDate ? 'Today' : `${monthNames[nextMessageMonth]} ${nextMessageDay}`;
      } else {
        dateDivider = `${monthNames[nextMessageMonth]} ${nextMessageDay}, ${nextMessageYear}`;
      }
    }
  }

  return dateDivider;
};
