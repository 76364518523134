import { IAbstractClass } from '@models/abstract-class.interface';
import { DebugStorage } from '@utils/debug/debug.factory';

export function storeInstance(instance: IAbstractClass, name: string, store: DebugStorage): void {
  if (!Object.prototype.hasOwnProperty.call(store, name)) {
    store[name] = instance;
  } else {
    const saved = store[name];
    if (Array.isArray(saved)) {
      saved.push(instance);
    } else {
      store[name] = [saved, instance];
    }
  }
}
