/* eslint-disable no-underscore-dangle */
import { isUnknownObject } from '@guards/is-unknown-object.guard';
import { ITextIntent } from '@models/intent/intents.interface';

export function isTextIntent(object: unknown): object is ITextIntent {
  return (
    isUnknownObject(object) &&
    'Text' in object &&
    isUnknownObject(object.Text) &&
    'Text_' in object.Text &&
    Array.isArray(object.Text.Text_) &&
    object.Text.Text_.some((entry) => typeof entry === 'string')
  );
}
