import { ISystemMessageRequest } from '@api/send-system-message';
import { ITwilioCredentials } from '@api/session';
import { Nullable } from '@models/nullable.type';
import { SystemMessageTypeEnum } from '@models/system-message-type.enum';

export function composeSystemMessageBody(
  MessageType: SystemMessageTypeEnum,
  credentials: Nullable<ITwilioCredentials>,
  attributes: Record<string, unknown> = {},
): ISystemMessageRequest {
  if (!credentials) {
    throw new ReferenceError('Failed to send system message, no Twilio credentials');
  }

  const { GlobalChannelId, TwilioChannelId } = credentials;
  return {
    MessageType,
    GlobalChannelId,
    TwilioChannelId,
    Attributes: JSON.stringify(attributes),
  };
}
