import React, { useCallback } from 'react';
import styles from './carousel.module.css';

type Props = {
  title: string;
  description: string;
  clickMessage: string;
  imageUri: string;
  clickCardHandler(message: string): void;
  slideIndex?: number;
};

export const ImageContent: React.FunctionComponent<Props> = ({
  clickMessage,
  title,
  description,
  clickCardHandler,
  imageUri,
  slideIndex,
}) => {
  const onClick = useCallback((): void => clickCardHandler(clickMessage), [clickCardHandler, clickMessage]);

  return (
    <div onClick={onClick} data-qa="carousel_onClick" data-index={slideIndex}>
      <img
        className={styles.image}
        src={imageUri}
        alt="carousel image"
        role="img"
        tabIndex={-1}
        data-tab-element={true}
      />
      <div className={styles.imageCardContent} data-qa="carousel_cardContent">
        <h6
          className={styles.imageCardTitle}
          tabIndex={-1}
          data-tab-element={true}
          aria-label={`carousel image title, ${title}, use tab to navigate to the next element`}
        >
          {title}
        </h6>
        <p
          className={styles.imageCardDescription}
          tabIndex={-1}
          data-tab-element={true}
          aria-label={`carousel image description, ${description}, use tab to navigate to the next element`}
        >
          {description}
        </p>
      </div>
    </div>
  );
};
