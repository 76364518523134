import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IPhonesToChoose } from '@models/message-attributes.interface';
import { submitChoosePhone } from '@actions/chat.actions';

interface IAppStoreChat {
  phonesToChoose: {
    data: IPhonesToChoose;
    error: string;
    loaded: boolean;
    loading: boolean;
  };
  previousMemberNumber: string;
}

const initialState: IAppStoreChat = {
  phonesToChoose: {
    data: {},
    error: '',
    loaded: false,
    loading: false,
  },
  previousMemberNumber: '',
};

const chatSlice = createSlice({
  name: 'Chat',
  initialState,
  reducers: {
    setNextMemberNumber: (state, { payload }: PayloadAction<string>) => {
      state.previousMemberNumber = payload;
    },
    setPhonesToChoose: (state, { payload }: PayloadAction<IPhonesToChoose>) => {
      state.phonesToChoose.data = payload;
    },
    setPhonesToChooseError: (state, { payload }: PayloadAction<string>) => {
      state.phonesToChoose.error = payload;
    },
    resetPhonesData: (state) => {
      state.phonesToChoose.loaded = false;
      state.phonesToChoose.loading = false;
      state.phonesToChoose.error = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitChoosePhone.pending, (state) => {
      state.phonesToChoose.loading = true;
      state.phonesToChoose.loaded = false;
      state.phonesToChoose.error = '';
    });
    builder.addCase(submitChoosePhone.fulfilled, (state) => {
      state.phonesToChoose.loading = false;
      state.phonesToChoose.loaded = true;
    });
    builder.addCase(submitChoosePhone.rejected, (state, { payload }) => {
      state.phonesToChoose.loading = true;
      state.phonesToChoose.loaded = false;
      state.phonesToChoose.error = payload?.Error?.Message || '';
    });
  },
});

export const ChatActions = chatSlice.actions;
export const chatReducer = chatSlice.reducer;

export type ChatActionsType = typeof ChatActions;
