import { useContext, useEffect, useRef, useState } from 'react';
import { useBehaviorSubject } from '@hooks/use-behavior-subject';
import { ServiceContext } from '@services/service.provider';
import { Message as MessageModel } from '@models/message.model';
import { sortByIndex } from '@utils/sort-by-index';
interface IScrollHandler {
  scrollRef: React.RefObject<HTMLDivElement>;
  handleScroll: () => void;
}

/**
 * We cannot have empty array of messages after initializing.
 * But it is because it's must be twlio servise works right in "Separate mode".
 * But not needed on render
 * https://ji.mfmnow.com/browse/CCVLC-4270
 */
const useFilterListByEmpty = (): MessageModel[] => {
  const { twilioConversationsService } = useContext(ServiceContext);
  const messages = useBehaviorSubject(twilioConversationsService.messages$);
  const oldMessages = useBehaviorSubject(twilioConversationsService.oldMessages$);
  const hiddenMessages = useBehaviorSubject(twilioConversationsService.hiddenMessages$);

  const [list, setList] = useState<MessageModel[]>([]);

  useEffect(() => {
    const visibleMessagesSortedByIndex = [...oldMessages, ...messages.sort(sortByIndex)].filter(
      (message) => !hiddenMessages.includes(message),
    );
    if (visibleMessagesSortedByIndex.length) {
      setList(visibleMessagesSortedByIndex);
    }
  }, [hiddenMessages, messages, oldMessages]);

  return list || [];
};

const FetchMessageHistoryThreshold = 300;

const useThrottledScrollHandler = (): IScrollHandler => {
  const { twilioConversationsService } = useContext(ServiceContext);
  const areMessagesFetching = useBehaviorSubject(twilioConversationsService.messagesFetching$);
  const prevScrollHeight = useRef(0);
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef?.current && prevScrollHeight.current && !areMessagesFetching) {
      scrollRef.current.scrollTop =
        scrollRef.current.scrollHeight - prevScrollHeight.current + FetchMessageHistoryThreshold;
    }
  }, [areMessagesFetching]);

  const handleScroll = (): void => {
    if (!scrollRef.current || twilioConversationsService.isLastPage()) {
      return;
    }

    if (areMessagesFetching && scrollRef.current.scrollTop <= FetchMessageHistoryThreshold) {
      // This 100 must be to avoid browser lag on scroll
      scrollRef.current.scrollTop = FetchMessageHistoryThreshold;
    }

    const scrollTop = scrollRef?.current?.scrollTop ?? 0;
    // on some devices scroll position can be below zero if turned on inert scrolling
    if (scrollTop <= FetchMessageHistoryThreshold) {
      prevScrollHeight.current = scrollRef?.current.scrollHeight;
      twilioConversationsService.fetchPreviousMessages();
    }
  };

  return { handleScroll, scrollRef };
};

export { useFilterListByEmpty, useThrottledScrollHandler };
