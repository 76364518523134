export const isWebView = (): boolean => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const isSafari = /safari/.test(userAgent);
  const isIos = /iphone|ipod|ipad/.test(userAgent);

  if (isIos) {
    return !isSafari;
  } else {
    return userAgent.includes('wv');
  }
};
