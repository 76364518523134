import { HTTPClient } from '@api/http-client';
import { ILivechatSettings } from '@api/settings';
import { ChatModeEnum, SeparatedChatModeEnum } from '@models/chat-mode.enum';
import { SessionStorageService } from '@services/storage/session-storage.service';
import { SettingsActionsType } from '@slices/settings.slice';
import { Store } from '@store';
import { Debug } from '@utils/debug/debug.decorator';
import { BehaviorSubject } from 'rxjs';

@Debug
export class SettingsService {
  public settings: ILivechatSettings;
  public separatedMode$ = new BehaviorSubject<SeparatedChatModeEnum | null>(null);

  constructor(
    private _httpClient: HTTPClient,
    private _sessionStorageService: SessionStorageService,
    private _store: Store,
    private _actions: SettingsActionsType,
  ) {
    const settings = this._sessionStorageService.get(SessionStorageService.Settings);
    this.settings = settings;
    // in store settings must be used settings in components only, but store all now
    this._store.dispatch(this._actions.setSettings(settings));
    const modeStorage = this._sessionStorageService.get<SeparatedChatModeEnum>(SessionStorageService.ChatMode);

    this.separatedMode$.next(modeStorage);
  }

  public async init(): Promise<void> {
    if (!this.settings) {
      const settings = await this._httpClient.settings();
      this.settings = this._sessionStorageService.save(SessionStorageService.Settings, settings);
      this._store.dispatch(this._actions.setSettings(settings));
    }
  }

  public setSeparatedChatMode(mode: SeparatedChatModeEnum): void {
    this.separatedMode$.next(mode);
    this._sessionStorageService.save<SeparatedChatModeEnum>(SessionStorageService.ChatMode, mode);
  }

  public get isLiveChat(): boolean {
    if (this.settings.Mode === ChatModeEnum.SeparatedLiveChatBot) {
      return this.separatedMode$.value === SeparatedChatModeEnum.LiveChat;
    } else {
      return this.settings.Mode === ChatModeEnum.LiveChat;
    }
  }
}
