import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './sms-code-form.module.css';
import { ServiceContext } from '@services/service.provider';
import classNames from 'classnames';
import { Scroller } from '@components/scroller';
import ReactCodeInput from 'react-verification-code-input';
import { Button } from '@components/button';
import { useBehaviorSubject } from '@hooks/use-behavior-subject';
import { useAppSelector } from '@store';
import { Icon } from '@components/icon';

const FieldsLength = 4;

export const SmsCodeForm: React.FunctionComponent = () => {
  const { routerService, liveChatService, twilioConversationsService } = useContext(ServiceContext);
  const settings = useAppSelector((state) => state.settings.data?.AnonymCustomerOtpSettings);
  const declinedInfo = useBehaviorSubject(twilioConversationsService.otpCodeIsDeclinedInfo$);
  const [isFormTouched, setIsFormTouched] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isFirstStep, setIsFirstStep] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const isError = (errorMessage || declinedInfo?.ErrorMessage) && isFormTouched;
  const inputRef = useRef<ReactCodeInput | null>(null);
  const codeRef = useRef<HTMLDivElement | null>(null);
  const [code, setCode] = useState('');

  const skipHandler = (): void => {
    liveChatService.skipSmsCode();
    routerService.openChat();
  };

  const submitHandler = async (lastCode: string): Promise<void> => {
    setIsLoading(true);
    setIsFirstStep(false);
    setErrorMessage('');

    const response = await liveChatService.submitSmsCode(lastCode);

    if (response.Error) {
      setErrorMessage(response.Error.Message);
      setIsLoading(false);
      routerService.openChat();
    }
  };

  const setCodeHandler = (currentCode: string): void => {
    setCode(currentCode);
    setIsFormTouched(true);

    if (currentCode.length === FieldsLength) {
      submitHandler(currentCode);
      setIsLoading(true);
      setIsFirstStep(false);
    }
  };

  const goBackHandler = (): void => {
    routerService.openChoosePhone();
  };

  const resendHandler = (): void => {
    setIsFirstStep(true);
    setCode('');
    setErrorMessage('');
    setIsFormTouched(false);

    if (inputRef.current) {
      // eslint-disable-next-line no-underscore-dangle
      inputRef.current.__clearvalues__();
    }
    if (codeRef.current) {
      codeRef.current.getElementsByTagName('input')[0].focus();
    }
    liveChatService.submitChoosePhone(liveChatService.choosenPhoneForSMS);
  };

  // inputs in ReactCodeInput can not be modified, that's why we add ariaLabel via effect
  useEffect(() => {
    const inputs = codeRef.current?.getElementsByTagName('input');
    if (inputs) {
      for (let i = 0; i < inputs.length; i++) {
        const input = inputs[i];
        input.ariaLabel = `Pin input field: ${i + 1}`;
      }
    }
  });

  useEffect(() => {
    if (!code) {
      setErrorMessage('This field is required');
    } else {
      setErrorMessage('');
    }
  }, [code, errorMessage]);

  useEffect(() => {
    if (declinedInfo?.ErrorMessage) {
      setIsLoading(false);
    }
  }, [declinedInfo?.ErrorMessage]);

  useEffect(() => {
    if (declinedInfo?.CanContinueResendOtp === false) {
      routerService.openChat();
    }
  }, [declinedInfo?.CanContinueResendOtp, routerService]);

  return (
    <Scroller>
      <div className={styles.wrapper}>
        <button onClick={skipHandler} className={styles.close} aria-label={'Close sms code form'}>
          <Icon name="close" sizes={[24]} qaLocator="sms_code_form_close_btn" />
        </button>
        <div className={styles.title} data-qa="sms_code_form_title">
          {settings?.Title}
        </div>
        <div className={styles.description} data-qa="sms_code_form_welcome_message">
          {settings?.Message}
        </div>
        <div ref={codeRef} className={styles.code} data-qa="sms_code_form_input">
          <ReactCodeInput
            ref={inputRef}
            className={classNames(styles.input, {
              [styles['input-error']]: isError,
              [styles['input-common']]: !isError,
            })}
            type="number"
            fields={FieldsLength}
            onChange={setCodeHandler}
            disabled={isLoading || !isFirstStep}
            autoFocus
          />
          {isError && <div className={styles.message}>{errorMessage}</div>}
          {declinedInfo?.ErrorMessage && (
            <div className={styles.message} data-qa="sms_code_form_error_message">
              {declinedInfo.ErrorMessage}{' '}
            </div>
          )}
        </div>
        <div className={styles.buttons} data-qa="sms_code_form_buttons">
          {isFirstStep ? (
            <button
              onClick={goBackHandler}
              className={styles.didntReceiveLink}
              aria-label={settings?.DidntReceiveCodeLabel}
            >
              {settings?.DidntReceiveCodeLabel}
            </button>
          ) : (
            <Button disabled={isLoading} onClick={resendHandler} ariaLabel={settings?.ResendCodeLabel}>
              {settings?.ResendCodeLabel}
            </Button>
          )}
        </div>
      </div>
    </Scroller>
  );
};
