import { SettingsService } from '@services/settings/settings.service';
import { LocalStorageService } from '@services/storage/local-storage.service';
import { Debug } from '@utils/debug/debug.decorator';
import { BehaviorSubject } from 'rxjs';

export enum PrivacyPolicyStateEnum {
  Initial,
  Accepted,
  Declined,
}

@Debug
export class PrivacyPolicyService {
  public privacyState$ = new BehaviorSubject<PrivacyPolicyStateEnum>(PrivacyPolicyStateEnum.Accepted);

  constructor(private _localStorageService: LocalStorageService, private _settingsService: SettingsService) {}

  public init(isAuth: boolean, isAuthPrivacyPolicyAccepted: boolean): void {
    const isEnabled = this._settingsService.settings.PrivacyPolicySettings.IsEnabled;

    if (!isEnabled) {
      return;
    }
    if (isAuth) {
      if (!isAuthPrivacyPolicyAccepted) {
        this.reset();
      }
    } else {
      const isAccepted = this._localStorageService.get<boolean>(LocalStorageService.PrivacyPolicyAccepted);
      const isRequired = isEnabled && !isAccepted;
      this.privacyState$.next(isRequired ? PrivacyPolicyStateEnum.Initial : PrivacyPolicyStateEnum.Accepted);
    }
  }

  public accept(): void {
    this._localStorageService.save(LocalStorageService.PrivacyPolicyAccepted, true);
    this.privacyState$.next(PrivacyPolicyStateEnum.Accepted);
  }

  public decline(): void {
    this.privacyState$.next(PrivacyPolicyStateEnum.Declined);
  }

  public reset(): void {
    this.privacyState$.next(PrivacyPolicyStateEnum.Initial);
  }
}
