import React from 'react';
import styles from './icon-underlay.module.css';
import classnames from 'classnames';
import { AbstractStatusEnum } from '@models/abstract-status.enum';
import { Icon } from '@components/icon';
import { IconSourceUrl } from '@components/icon-source-url';
import { Nullable } from '@models/nullable.type';

type Props = {
  icon: string;
  status: AbstractStatusEnum;
  qaLocator?: string;
  isCompact?: boolean;
  url?: Nullable<string>;
};

export const IconUnderlay: React.FunctionComponent<Props> = ({
  icon,
  status,
  qaLocator,
  isCompact = false,
  url = null,
}) => (
  <div className={classnames(styles.underlay, { [styles.compact]: isCompact }, styles[status])}>
    <IconSourceUrl
      url={url}
      className={styles.icon}
      qaLocator={qaLocator}
      fallbackIcon={<Icon name={icon} sizes={[72]} className={styles.icon} qaLocator={qaLocator} />}
      alt={'Status icon'}
    />
  </div>
);
