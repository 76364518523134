import { useBehaviorSubject } from '@hooks/use-behavior-subject';
import React, { useContext } from 'react';
import { ServiceContext } from '@services/service.provider';
import classnames from 'classnames';
import styles from '../root.module.css';

export const Counter: React.FunctionComponent = () => {
  const { twilioConversationsService } = useContext(ServiceContext);

  const unreadMessagesCount = useBehaviorSubject(twilioConversationsService.unreadMessagesCount$);
  return unreadMessagesCount ? (
    <span className={classnames(styles.counter)}>{unreadMessagesCount > 999 ? '1k+' : unreadMessagesCount}</span>
  ) : null;
};
