import { ThemeEnum, ThemeSliceActions } from '@slices/theme.slice';
import { store, useAppSelector } from '@store';
import { LocalStorageService } from '@services/storage/local-storage.service';
import { ServiceContext } from '@services/service.provider';
import { useContext, useEffect } from 'react';

export const useAppTheme = (): { handleThemeChange: () => void } => {
  const { localStorageService, settingsService } = useContext(ServiceContext);
  const theme = useAppSelector((state) => state.theme.currentTheme);
  const isThemeChangeAvailable = settingsService.settings.BrandingSettings.DarkThemeIsEnabled;

  const handleThemeChange = (): void => {
    const themeToSet = theme === ThemeEnum.LIGHT ? ThemeEnum.DARK : ThemeEnum.LIGHT;
    store.dispatch(ThemeSliceActions.setTheme(themeToSet));
    localStorageService.save(LocalStorageService.LiveChatTheme, themeToSet);
  };
  const handleThemeOnPageLoad = (): void => {
    const isUserHasDarkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const currentTheme = localStorageService.get(LocalStorageService.LiveChatTheme);
    if (currentTheme) {
      store.dispatch(ThemeSliceActions.setTheme(currentTheme));
    } else if (isUserHasDarkTheme) {
      store.dispatch(ThemeSliceActions.setTheme(ThemeEnum.DARK));
    }
  };
  const handleDataThemeChange = (theme: string | undefined | null): void => {
    const currentThemeChosenManually = localStorageService.get(LocalStorageService.LiveChatTheme);

    if (!theme || currentThemeChosenManually) {
      return;
    }
    const themeNameInSingleQuotes = theme.replaceAll(`"`, `'`);
    switch (themeNameInSingleQuotes) {
      case ThemeEnum.DARK:
        store.dispatch(ThemeSliceActions.setTheme(ThemeEnum.DARK));
        break;
      case ThemeEnum.LIGHT:
        store.dispatch(ThemeSliceActions.setTheme(ThemeEnum.LIGHT));
        break;
      default:
        console.error(`Unknown theme: ${themeNameInSingleQuotes}`);
    }
  };
  const handleOfmThemeChange = (): void => {
    const ofmDataThemeAttr = 'data-theme';
    const themeChosenManually = localStorageService.get(LocalStorageService.LiveChatTheme);

    if (themeChosenManually) {
      return;
    }

    const currentOfmTheme = document.body.getAttribute(ofmDataThemeAttr);
    handleDataThemeChange(currentOfmTheme);

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === ofmDataThemeAttr) {
          handleDataThemeChange((mutation?.target as HTMLBodyElement)?.dataset.theme);
        }
      });
    });
    observer.observe(document.body, { attributes: true });
  };

  useEffect(() => {
    if (!isThemeChangeAvailable) {
      return;
    }
    handleThemeOnPageLoad();
    handleOfmThemeChange();
  }, []);

  return {
    handleThemeChange,
  };
};
