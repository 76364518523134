import { useEffect } from 'react';

export const useSayPhrase = (phrase: string | string[], interrupt = false): void => {
  useEffect((): void => {
    if (!phrase) {
      return;
    }
    const node = document.getElementById('live_chat_application_root')?.shadowRoot?.getElementById('phrase-container');
    if (node) {
      if (typeof phrase === 'string') {
        node.textContent = phrase;
      }

      if (Array.isArray(phrase)) {
        node.textContent = phrase.join(',');
      }
      node.setAttribute('aria-live', interrupt ? 'assertive' : 'polite');
    }
  });
};
