import { SessionStorageService } from '@services/storage/session-storage.service';
import { Debug } from '@utils/debug/debug.decorator';

@Debug
export class PersistentTimerService {
  private _timerIdByName = new Map<string, number>();

  constructor(private _sessionStorageService: SessionStorageService, private _window: Window) {}

  public isTimerRunning(name: string): boolean {
    return Boolean(this._timerIdByName.get(name));
  }

  public setTimer(name: string, callback: TimerHandler, timeout: number): void {
    const timerId = this._window.setTimeout(this._createCallbackWithReset(name, callback), timeout);
    this._timerIdByName.set(name, timerId);
    this._sessionStorageService.save(name, Date.now() + timeout);
  }

  public restoreTimerIfPersist(name: string, callback: TimerHandler, timeout?: number): void {
    const expireTimeMs = timeout || this._sessionStorageService.get<number>(name);
    if (expireTimeMs) {
      const timeout = expireTimeMs - Date.now();
      this.setTimer(name, callback, timeout);
    }
  }

  public resetTimer(name: string): void {
    const timerId = this._timerIdByName.get(name);
    if (timerId) {
      this._window.clearTimeout(timerId);
      this._timerIdByName.delete(name);
      this._sessionStorageService.remove(name);
    }
  }

  private _createCallbackWithReset(name: string, callback: TimerHandler): TimerHandler {
    return (): void => {
      if (typeof callback === 'function') {
        callback();
      }
      this.resetTimer(name);
    };
  }
}
