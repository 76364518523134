export enum SystemMessageTypeEnum {
  ShowAccountsRequest = 'ShowAccountsRequest',
  SpecialistManualResolve = 'SpecialistManualResolve',
  MemberManualResolve = 'MemberManualResolve',
  MemberInactivityResolve = 'MemberInactivityResolve',
  RequestAnonymCustomerContactInfo = 'RequestAnonymCustomerContactInfo',
  ResponseAnonymCustomerContactInfo = 'ResponseAnonymCustomerContactInfo',
  DeclineAnonymCustomerContactInfo = 'DeclineAnonymCustomerContactInfo',
  WorkflowTimeout = 'WorkflowTimeout',
  SetAnonymMemberNumber = 'SetAnonymMemberNumber',
  AgentAcceptedChat = 'AgentAcceptedChat',
  SearchingAvailableAgent = 'SearchingAvailableAgent',
  AgentSetAnonymousContactInfo = 'AgentSetAnonymousContactInfo',
  CustomerIdentificationFormRequest = 'AuthInfoRequest',
  CustomerIdentificationFormResponse = 'AuthInfoResponse',
  AuthInfoDeclineResponse = 'AuthInfoDeclineResponse',
  ChooseOtpPhoneDeclineResponse = 'ChooseOtpPhoneDeclineResponse',
  ChooseOtpPhoneRequest = 'ChooseOtpPhoneRequest',
  ChooseOtpPhoneResponse = 'ChooseOtpPhoneResponse',
  ChooseOtpPhoneFailedRequest = 'ChooseOtpPhoneFailedRequest',
  BamOtpCodeSendFailedRequest = 'BamOtpCodeSendFailedRequest',
  BamOtpCodeVerifyFailedRequest = 'BamOtpCodeVerifyFailedRequest',
  AuthFailedRequest = 'AuthFailedRequest',
  OtpCodeRequest = 'OtpCodeRequest',
  OtpCodeResponse = 'OtpCodeResponse',
  OtpCodeDeclineResponse = 'OtpCodeDeclineResponse',
  OtpCodeIsDeclinedRequest = 'OtpCodeIsDeclinedRequest',
  Ping = 'Ping',
  AuthAcceptedRequest = 'AuthAcceptedRequest',
  ShowTransferRequest = 'ShowTransferRequest',
  TransferConfirmedResponse = 'TransferConfirmedResponse',
  TransferDeclinedResponse = 'TransferDeclinedResponse',
  TransferCompletedRequest = 'TransferCompletedRequest',
  TransferFailedRequest = 'TransferFailedRequest',
  GoToTrainingModeMessage = 'GoToTrainingModeMessage',
  LeaveTrainingModeMessage = 'LeaveTrainingModeMessage',
  ManagerAdviceMessage = 'ManagerAdviceMessage',
  AgentAdviceAnswerMessage = 'AgentAdviceAnswerMessage',
  ChatWasTakenOverByManager = 'ChatWasTakenOverByManager',
  ManagerJoinsToChat = 'ManagerJoinsToChat',
  OfmLoginNeeded = 'OfmLoginNeeded',
  AgentAdviserQuestionMessage = 'AgentAdviserQuestionMessage',
  AgentAdviserAnswerMessage = 'AgentAdviserAnswerMessage',
  SwitchToAuthSession = 'SwitchToAuthSession',
  UserLoggedInToOfm = 'UserLoggedInToOfm',
  UserLoggedOutFromOfm = 'UserLoggedOutFromOfm',
  ManagerJoinsToChatAsThirdParty = 'ManagerJoinsToChatAsThirdParty',
  ManagerLeavesChatAsThirdParty = 'ManagerLeavesChatAsThirdParty',
}

export const SpecialSystemMessageTypes = [
  SystemMessageTypeEnum.RequestAnonymCustomerContactInfo,
  SystemMessageTypeEnum.ResponseAnonymCustomerContactInfo,
];

export const PlacingToLoadingSystemMessageTypes = [
  SystemMessageTypeEnum.AgentAcceptedChat,
  SystemMessageTypeEnum.SearchingAvailableAgent,
];

// if hidden we neither fetch its body, nor placing to the chat roll
export const LiveChatHiddenSystemMessageTypes = [
  SystemMessageTypeEnum.MemberManualResolve,
  SystemMessageTypeEnum.SetAnonymMemberNumber,
  SystemMessageTypeEnum.AgentSetAnonymousContactInfo,
  SystemMessageTypeEnum.Ping,
  SystemMessageTypeEnum.CustomerIdentificationFormRequest,
  SystemMessageTypeEnum.CustomerIdentificationFormResponse,
  SystemMessageTypeEnum.AuthInfoDeclineResponse,
  SystemMessageTypeEnum.ChooseOtpPhoneDeclineResponse,
  SystemMessageTypeEnum.ChooseOtpPhoneRequest,
  SystemMessageTypeEnum.ChooseOtpPhoneResponse,
  SystemMessageTypeEnum.ChooseOtpPhoneFailedRequest,
  SystemMessageTypeEnum.AuthFailedRequest,
  SystemMessageTypeEnum.BamOtpCodeSendFailedRequest,
  SystemMessageTypeEnum.BamOtpCodeVerifyFailedRequest,
  SystemMessageTypeEnum.OtpCodeDeclineResponse,
  SystemMessageTypeEnum.OtpCodeIsDeclinedRequest,
  SystemMessageTypeEnum.OtpCodeResponse,
  SystemMessageTypeEnum.OtpCodeRequest,
  SystemMessageTypeEnum.AuthAcceptedRequest,
  SystemMessageTypeEnum.ShowTransferRequest,
  SystemMessageTypeEnum.TransferConfirmedResponse,
  SystemMessageTypeEnum.TransferDeclinedResponse,
  SystemMessageTypeEnum.TransferCompletedRequest,
  SystemMessageTypeEnum.TransferFailedRequest,
  SystemMessageTypeEnum.GoToTrainingModeMessage,
  SystemMessageTypeEnum.LeaveTrainingModeMessage,
  SystemMessageTypeEnum.ManagerAdviceMessage,
  SystemMessageTypeEnum.AgentAdviceAnswerMessage,
  SystemMessageTypeEnum.ChatWasTakenOverByManager,
  SystemMessageTypeEnum.OfmLoginNeeded,
  SystemMessageTypeEnum.AgentAdviserQuestionMessage,
  SystemMessageTypeEnum.AgentAdviserAnswerMessage,
  SystemMessageTypeEnum.SwitchToAuthSession,
  SystemMessageTypeEnum.UserLoggedInToOfm,
  SystemMessageTypeEnum.UserLoggedOutFromOfm,
];

export const ChatBotHiddenSystemMessageTypes = [
  ...LiveChatHiddenSystemMessageTypes,
  SystemMessageTypeEnum.SpecialistManualResolve,
  SystemMessageTypeEnum.MemberInactivityResolve,
  SystemMessageTypeEnum.WorkflowTimeout,
];

export const HideChipsButtonSystemMessageTypes = [
  SystemMessageTypeEnum.RequestAnonymCustomerContactInfo,
  SystemMessageTypeEnum.SpecialistManualResolve,
  SystemMessageTypeEnum.MemberInactivityResolve,
  SystemMessageTypeEnum.WorkflowTimeout,
];
