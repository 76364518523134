import { RefObject, useEffect, useRef } from 'react';

export const useVoiceForLabels = (isSelect = false): RefObject<HTMLFormElement> => {
  const formRef = useRef<HTMLFormElement | null>(null);

  useEffect((): void => {
    if (formRef) {
      const labels = formRef.current?.querySelectorAll('label') as NodeListOf<HTMLLabelElement>;
      if (labels) {
        [...labels].forEach((label, index) => {
          let voice = `form input ${index + 1} of ${labels?.length}, ${
            label.innerText
          }, enter information or use tab to navigate to the next element.`;

          if (isSelect) {
            voice = `form input ${index + 1} of ${labels?.length}, ${label.innerText}, ${
              label.innerText.toLowerCase() === 'amount' ? 'enter amount' : 'open dropdown list by using alt + enter'
            } or use tab to navigate to the next element.`;
          }

          label.ariaLabel = voice;
        });
      }
    }
  });

  return formRef;
};
