import { byteToBase64 } from './byte-to-base64';
import { base64Encode } from '@utils/base64-encode';
import { SessionStorageService } from '@services/storage/session-storage.service';
import { storageServicePrefix } from '@services/storage/storage.service';

export const getIdForClientDeviceIdHeader = () => {
  let id = sessionStorage[storageServicePrefix + SessionStorageService.DeviceId];
  if (!id) {
    id = createId();
    sessionStorage[storageServicePrefix + SessionStorageService.DeviceId] = id;
  }

  return id;
};

const createId = () => {
  const array = new Uint8Array(128);
  const str = crypto.getRandomValues(array);
  const hash = byteToBase64(str);
  return 'v5_' + base64Encode(hash)?.slice(0, 24);
};
