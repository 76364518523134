import React, { useCallback } from 'react';
import styles from './transfer-form.module.css';
import NumberFormat from 'react-number-format';
import { Button } from '@components/button';
import CircleArrowRight from '@ast/magma/components/icon/icons/CircleArrowRight';
import { Input } from '@ast/magma/components/input';

import {
  useFormTitle,
  useIsTransferDisabled,
  useSkipTransferHandler,
  useSubmitTransfer,
  useTransferFields,
} from './transfer-form.hooks';
import { Select } from '@components/select';
import { ITransferAccount, ITransferAction } from '@services/livechat/livechat.models';
import { ITransferAccountExt } from './transfer-form.interface';
import classNames from 'classnames';
import { formatStringAmountToFloat, formatTransferToOption } from './transfer-form.utils';
import { useVoiceForLabels } from '@hooks/use-voice-for-labels';
import { useFocusOnRef } from '@hooks/use-focus-on-ref';
import { useSayPhrase } from '@hooks/use-say-phrase';

export const TransferForm: React.FunctionComponent = () => {
  const title = useFormTitle();
  const fields = useTransferFields();
  const { from, to, amount, loan } = fields;
  const skipHandler = useSkipTransferHandler();
  const isDisabled = useIsTransferDisabled(fields);
  const { submitHandler, isTouched } = useSubmitTransfer(fields, isDisabled);

  const CustomInput = useCallback(
    (props: { value: string }) => (
      <Input
        {...props}
        aria-invalid={isTouched && !formatStringAmountToFloat(props.value)}
        label="Amount"
        aria-label={'Amount input'}
      />
    ),
    [isTouched],
  );
  useSayPhrase(
    isTouched && isDisabled
      ? 'something went wrong, please go through the inputs again and choose correct information, use tab to navigate'
      : '',
  );

  const renderOption = useCallback((item: ITransferAccountExt, index: number, total: number): JSX.Element => {
    return (
      <button
        role="presentation"
        data-qa={item?.Disabled ? 'transfer_form_option_disabled' : 'transfer_form_option_enabled'}
        className={classNames(styles.option, { [styles.disabledOption]: item?.Disabled })}
        aria-label={`option ${index + 1} of ${total}. Account name: ${item?.Name}, account number: ${
          item?.Number
        }, account available balance: ${item?.AvailableBalance}, ${
          item?.Disabled ? 'you can not choose this account,' : 'use enter to choose this account or'
        } use tab to navigate to the next element.`}
        tabIndex={0}
        data-option={item?.Disabled ? '' : 'enabled'}
        aria-disabled={item?.Disabled}
      >
        <div>{item?.Name} </div>
        <div>{item?.Number} </div>
        <div>{item?.AvailableBalance}</div>
      </button>
    );
  }, []);

  const renderLoanOption = useCallback(
    (item?: ITransferAction): JSX.Element => <div className={styles.option}>{formatTransferToOption(item)}</div>,
    [],
  );

  const renderLoanSelectedOption = useCallback((item?: ITransferAction): string => formatTransferToOption(item), []);

  const renderSelectedOption = (item?: ITransferAccount): string => {
    const { Name = '', Number = '' } = item as ITransferAccount;
    return Name && Number ? `${Name} ${Number}` : '';
  };

  const optionDisabledHandler = (item?: ITransferAccountExt): boolean => !!item?.Disabled;
  const formRef = useVoiceForLabels(true);
  const titleRef = useFocusOnRef(formRef);

  return (
    <form className={styles.form} onSubmit={submitHandler} ref={formRef}>
      <div className={styles.title} data-qa="transfer_form_title">
        <div className={styles.titleShadow} />
        <CircleArrowRight className={styles.titleSvg} />
        <span
          className={styles.titleText}
          tabIndex={0}
          aria-label={`heading, ${title}, use tab to navigate to the next element`}
          ref={titleRef}
        >
          {title}
        </span>
      </div>
      <div className={styles.fields} data-qa="transfer_form_fields">
        <Select
          label="Transfer from"
          option={from.state}
          onSelect={from.onChange}
          options={from.list}
          renderOption={renderOption}
          renderSelectedOption={renderSelectedOption}
          isInvalid={isTouched && !from.state?.Id}
          qaLocator="transfer_form_transfer_from"
        />
        <Select
          label="Transfer to"
          option={to.state}
          optionDisabled={optionDisabledHandler}
          onSelect={to.onChange}
          options={to.list}
          renderOption={renderOption}
          renderSelectedOption={renderSelectedOption}
          isInvalid={isTouched && !to.state?.Id}
          qaLocator="transfer_form_transfer_to"
        />
        {loan.list.length > 0 && (
          <Select
            label="Payment options"
            option={loan.value}
            onSelect={loan.onChange}
            options={loan.list}
            renderOption={renderLoanOption}
            renderSelectedOption={renderLoanSelectedOption}
            isInvalid={isTouched && !loan.value}
            qaLocator="transfer_form_payment_options"
          />
        )}
        {!amount.isHidden && (
          <div className={styles.amount} data-qa="transfer_form_amount">
            <NumberFormat
              value={amount.value}
              onChange={amount.onChange}
              thousandSeparator=","
              decimalScale={2}
              decimalSeparator="."
              prefix="$"
              maxLength={15}
              customInput={CustomInput}
              allowNegative={false}
            />
          </div>
        )}
      </div>
      <div className={styles.buttons} data-qa="transfer_form_buttons">
        <Button
          qaLocator="transfer_confirm_button"
          type="submit"
          primary
          ariaLabel="button one of two, continue, press enter to continue transfer"
        >
          Continue
        </Button>
        <Button
          qaLocator="transfer_cancel_button"
          onClick={skipHandler}
          ariaLabel="button two of two, cancel, press enter to cancel transfer"
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};
