import React, { useCallback } from 'react';
import styles from './radio-button-group.module.css';

type Props = {
  onChange(val: string): void;
  selectedValue: string;
  name: string;
  list: string[];
  qaLocator?: string;
};

export const RadioButtonGroup: React.FunctionComponent<Props> = ({ list, name, onChange, selectedValue, qaLocator }) => {
  const onChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <div className={styles.wrapper}>
      {list.map((item) => (
        <label key={item} className={styles.container}>
          {item}
          <input value={item} onChange={onChangeHandler} checked={item === selectedValue} type="radio" name={name} />
          <span className={styles.checkmark} />
        </label>
      ))}
    </div>
  );
};
