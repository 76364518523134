import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Portal } from '@components/portal';
import Reaptcha from 'reaptcha';
import { ServiceContext } from '@services/service.provider';
import styles from '@components/root/root.module.css';

type Recaptchav2ContainerProps = {
  recaptchaV2Ref: React.RefObject<Reaptcha>;
  setIsShowRecaptchaV2: React.Dispatch<React.SetStateAction<boolean>>;
  setRecaptchaV2Token: React.Dispatch<React.SetStateAction<string>>;
};

export const Recaptchav2Container: React.FunctionComponent<Recaptchav2ContainerProps> = ({
  recaptchaV2Ref,
  setRecaptchaV2Token,
}) => {
  const { settingsService } = useContext(ServiceContext);
  const [recaptchaContainer, setRecaptchaContainer] = useState<HTMLDivElement | null>(null);

  const createRecaptchaContainer = useCallback(async () => {
    const recaptchaContainerDiv = document.createElement('div');
    recaptchaContainerDiv.id = 'recaptcha-container';
    document.body.appendChild(recaptchaContainerDiv);
    await setRecaptchaContainer(recaptchaContainerDiv);
  }, []);

  const removeRecaptchaContainer = useCallback(async () => {
    if (recaptchaContainer) {
      document.body.removeChild(recaptchaContainer);
      await setRecaptchaContainer(null);
    }
  }, [recaptchaContainer]);

  useEffect(() => {
    const updateRecaptchaContainerStyle = (): void => {
      if (recaptchaContainer) {
        const shadowDomApplicationDiv = document
          .querySelector('[data-qa="application_root"]')
          ?.shadowRoot?.querySelector(`.${styles.root}`);
        if (!shadowDomApplicationDiv) {
          return;
        }

        const shadowDomApplicationDivStyles = window.getComputedStyle(shadowDomApplicationDiv as Element);
        const shadowDomApplicationDivRect = shadowDomApplicationDiv.getBoundingClientRect();
        const recaptchaContainerStyles: React.CSSProperties = {};
        const isMobile = window.matchMedia('(max-width: 980px)').matches;

        if (!isMobile) {
          recaptchaContainerStyles.display = 'flex';
          recaptchaContainerStyles.position = 'fixed';
          recaptchaContainerStyles.top = `${shadowDomApplicationDivRect.top}px`;
          recaptchaContainerStyles.left = `${shadowDomApplicationDivRect.left}px`;
          recaptchaContainerStyles.right = `${shadowDomApplicationDivRect.right}px`;
          recaptchaContainerStyles.bottom = `${shadowDomApplicationDivRect.bottom}px`;
          recaptchaContainerStyles.width = shadowDomApplicationDivStyles.getPropertyValue('width');
          recaptchaContainerStyles.height = shadowDomApplicationDivStyles.getPropertyValue('height');
          recaptchaContainerStyles.background = 'rgba(255, 255, 255, 0.9)';
          recaptchaContainerStyles.alignItems = 'center';
          recaptchaContainerStyles.justifyContent = 'center';
          recaptchaContainerStyles.overflow = 'hidden';
          recaptchaContainerStyles.zIndex = 99999999;
        } else {
          recaptchaContainerStyles.position = 'fixed';
          recaptchaContainerStyles.display = 'flex';
          recaptchaContainerStyles.background = 'rgba(255, 255, 255, 0.9)';
          recaptchaContainerStyles.alignItems = 'center';
          recaptchaContainerStyles.justifyContent = 'center';
          recaptchaContainerStyles.overflow = 'hidden';
          recaptchaContainerStyles.zIndex = 99999999;
          recaptchaContainerStyles.right = 'auto';
          recaptchaContainerStyles.left = '0';
          recaptchaContainerStyles.bottom = 'auto';
          recaptchaContainerStyles.top = '0';
          recaptchaContainerStyles.width = '100%';
          recaptchaContainerStyles.height = '100%';
        }

        Object.assign(recaptchaContainer.style, recaptchaContainerStyles);
      }
    };
    window.addEventListener('resize', updateRecaptchaContainerStyle);
    if (!recaptchaContainer) {
      createRecaptchaContainer();
    } else {
      updateRecaptchaContainerStyle();
    }

    return (): void => {
      if (recaptchaContainer) {
        window.removeEventListener('resize', updateRecaptchaContainerStyle);
        removeRecaptchaContainer();
      }
    };
  }, [createRecaptchaContainer, removeRecaptchaContainer, recaptchaContainer]);

  const onVerify = useCallback(
    (token: string): void => {
      setRecaptchaV2Token(token);
    },
    [setRecaptchaV2Token],
  );

  const onExpire = (): void => {
    setRecaptchaV2Token('');
  };

  return (
    <Portal node={recaptchaContainer}>
      <Reaptcha
        ref={recaptchaV2Ref}
        sitekey={settingsService.settings.RecaptchaSettings.RecaptchaV2SiteKey}
        onVerify={onVerify}
        onExpire={onExpire}
      />
    </Portal>
  );
};
