import { ChangeEvent, FormEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ServiceContext } from '@services/service.provider';
import { ITransferAccount, ITransferAction } from '@services/livechat/livechat.models';

import {
  AccountsFields,
  IAccountsState,
  ISubmitTransfer,
  ITransferAccountExt,
  ITransferAmountForm,
  ITransferFields,
  ITransferLoanField,
} from './transfer-form.interface';
import { useBehaviorSubject } from '@hooks/use-behavior-subject';
import {
  formatAmountByZero,
  formatStringAmountToFloat,
  getAmountHiddenByLoans,
  getDestinationBySource,
  isDisabledTransferForm,
} from './transfer-form.utils';
import { Nullable } from '@models/nullable.type';

export const useIsTransferDisabled = (fields: ITransferFields): boolean => {
  return isDisabledTransferForm(fields, fields.loan.list.length > 0);
};

export const useSkipTransferHandler = (): VoidFunction => {
  const { routerService, liveChatService } = useContext(ServiceContext);

  return (): void => {
    routerService.openChat();
    liveChatService.resetTransferInfo();
    liveChatService.skipTransfer();
  };
};

export const useSubmitTransfer = (fields: ITransferFields, isDisabled: boolean): ISubmitTransfer => {
  const { routerService, liveChatService } = useContext(ServiceContext);
  const [isTouched, setisTouched] = useState(false);
  const transferFrom = fields.from.state;
  const transferTo = fields.to.state;
  const loan = fields.loan.value;

  const submitHandler = async (event: FormEvent): Promise<void> => {
    event.preventDefault();
    const amount = formatStringAmountToFloat(fields.amount.value);
    setisTouched(true);
    if (!isDisabled) {
      liveChatService.setTransferInfo(transferFrom, transferTo, amount, loan);
      routerService.openTransferConfirmation();
    }
  };

  return { submitHandler, isTouched };
};

export const useTransferFields = (): ITransferFields => {
  const accountsState = useTransferAccounts();
  const loan = useTransferLoan(accountsState);
  const amount = useTransferAmount(loan);

  return { ...accountsState, amount, loan };
};

export const useFormTitle = (): string => {
  const { twilioConversationsService } = useContext(ServiceContext);
  const transferReponseData = useBehaviorSubject(twilioConversationsService.transferResponseData$);

  return transferReponseData?.MakeTransferTitle || '';
};

const useAmountDataByLoans = (loan?: ITransferLoanField): { initAmount: string; isHidden: boolean } => {
  const { liveChatService, twilioConversationsService } = useContext(ServiceContext);
  const transferReponseData = useBehaviorSubject(twilioConversationsService.transferResponseData$);
  const isHidden = getAmountHiddenByLoans(loan);
  const initAmount = isHidden ? '' : String(liveChatService.transferInfo?.amount || transferReponseData?.Amount || '');

  return {
    initAmount,
    isHidden,
  };
};

const useTransferAmount = (loan: ITransferLoanField): ITransferAmountForm => {
  const { initAmount, isHidden } = useAmountDataByLoans(loan);
  const [value, setValue] = useState(initAmount);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement & HTMLTextAreaElement>): void => {
      setValue(formatAmountByZero(value, event.target.value));
    },
    [value],
  );

  // if loan choosen we use amount from that,
  // but if custom amount choosen, we have to return the prevent typing amount
  return {
    value,
    onChange,
    isHidden,
  };
};

const useTransferLoan = (accounts: AccountsFields): ITransferLoanField => {
  const { liveChatService } = useContext(ServiceContext);
  const [value, setValue] = useState<Nullable<ITransferAction>>(liveChatService.transferInfo?.loan || null);
  const list = useMemo(() => accounts.to.state?.TransferActions || [], [accounts.to.state?.TransferActions]);
  const isInit = useRef(true);

  useEffect(() => {
    if (isInit.current) {
      isInit.current = false;
    } else {
      setValue(null);
    }
  }, [accounts.to.state?.Id]);

  return { value, onChange: setValue, list };
};

const useAccountsState = (): IAccountsState => {
  const { twilioConversationsService, liveChatService } = useContext(ServiceContext);
  const accounts = useBehaviorSubject(twilioConversationsService.transferResponseData$);

  const fromInitValue = liveChatService.transferInfo.from;
  const toInitValue = liveChatService.transferInfo.to;

  const [from, setFrom] = useState<ITransferAccountExt | null>(fromInitValue);
  const [to, setTo] = useState<ITransferAccountExt | null>(toInitValue);

  const onChangeTo = useCallback((item: ITransferAccountExt): void => {
    if (!item.disabled) {
      setTo(item);
    }
  }, []);

  const destinationAccounts = useMemo(() => getDestinationBySource(accounts, from?.Id), [accounts, from?.Id]);
  const sourceAccounts = useMemo(() => accounts?.SourceAccounts || [], [accounts?.SourceAccounts]);

  useEffect(() => {
    if (from?.Id === to?.Id) {
      setTo(null);
    }
  }, [from, to]);

  return { from: [from, setFrom, sourceAccounts], to: [to, onChangeTo, destinationAccounts] };
};

const useTransferAccounts = (): AccountsFields => {
  const { from, to } = useAccountsState();
  const [transferFrom, setTransferFrom, allSourceAccounts] = from;
  const [transferTo, setTransferTo, allDestinationAccounts] = to;

  const handleTransferFromChange = (value: ITransferAccount): void => {
    setTransferFrom(value);
  };

  const handleTransferToChange = (value: ITransferAccount): void => {
    setTransferTo(value);
  };

  return {
    from: {
      onChange: handleTransferFromChange,
      state: transferFrom,
      list: allSourceAccounts,
    },
    to: {
      onChange: handleTransferToChange,
      state: transferTo,
      list: allDestinationAccounts,
    },
  };
};
