import { ISendSystemMessageResponse } from '@api/send-system-message';
import { createAsyncThunk } from '@reduxjs/toolkit';
import services from '@services/service.provider';

export const submitChoosePhone = createAsyncThunk<
  ISendSystemMessageResponse,
  string,
  { rejectValue: ISendSystemMessageResponse }
>('Chat:submitChoosePhone', async (phoneLabel, { rejectWithValue }) => {
  // In perfect world all logic of the method submitChoosePhone should be here
  const response = await services.liveChatService.submitChoosePhone(phoneLabel);

  if (response?.Error) {
    return rejectWithValue(response);
  } else {
    return response;
  }
});

export const skipChoosePhone = createAsyncThunk('Chat:skipChoosePhone', async () => {
  const response = await services.liveChatService.skipChoosePhone();

  return response;
});
