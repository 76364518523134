import { ServiceContext } from '@services/service.provider';
import { useCallback, useContext, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

const useCloseChatByHash = (): void => {
  const { routerService, botcopyService } = useContext(ServiceContext);
  const closeAppCallback = useCallback(() => {
    routerService.closeApp();
    botcopyService.closeWindow();
  }, [routerService, botcopyService]);
  useEffect(() => {
    if (isMobile) {
      const hashChangeHandler = (): void => {
        closeAppCallback();
      };
      window.addEventListener('hashchange', hashChangeHandler);
      return (): void => {
        window.removeEventListener('hashchange', hashChangeHandler);
      };
    }
  }, [closeAppCallback]);
};

export { useCloseChatByHash };
