import styles from '@components/root/root.module.css';
import services from '@services/service.provider';
import { LocalStorageService } from '@services/storage/local-storage.service';
import { isMobile } from 'react-device-detect';
import { RefObject } from 'react';
import { sendGAEvent } from '@utils/GA';

const buttonPosition = '16px';
const resetPosition = (draggableElement: HTMLElement): void => {
  draggableElement.style.top = 'unset';
  draggableElement.style.right = 'unset';
  draggableElement.style.bottom = 'unset';
  draggableElement.style.left = 'unset';
};
const getCoordinates = (draggableElement: HTMLElement): DOMRect => draggableElement.getBoundingClientRect();

const locatePosition = (draggableElement: HTMLElement): void => {
  resetPosition(draggableElement);
  const savedPosition = services.localStorageService.get(LocalStorageService.ButtonLiveChatPosition);
  if (Array.isArray(savedPosition) && !services.routerService.isAppOpen$.value) {
    savedPosition.forEach(
      (position: 'top' | 'left' | 'bottom' | 'right') => (draggableElement.style[position] = buttonPosition),
    );
  } else {
    draggableElement.style.bottom = buttonPosition;
    draggableElement.style.right = buttonPosition;
  }
};

/** handleDragAndDropButton - for mobile devices is possible to move the livechat button in any corner and save it in LS.
 * */
export const handleDragAndDropButton = (btnRef: RefObject<HTMLButtonElement>): void => {
  const draggableElement = document.getElementById('live_chat_application_root');

  if (!draggableElement || !isMobile) {
    return;
  }
  const buttonPosition = '16px';
  locatePosition(draggableElement);
  draggableElement.addEventListener('touchstart', (event) => {
    const buttonClass = `.${styles?.button?.split(' ')[0]}`;
    const hasButton = draggableElement?.shadowRoot?.querySelector(buttonClass);

    if (services.routerService.isAppOpen$.value || !hasButton) {
      return true;
    } else {
      event.preventDefault();
      const { left, top } = getCoordinates(draggableElement);
      const offsetX = event.touches[0].clientX - left;
      const offsetY = event.touches[0].clientY - top;

      const elementWidth = draggableElement.offsetWidth;
      const elementHeight = draggableElement.offsetHeight;

      const startX = event.touches[0].clientX;
      const startY = event.touches[0].clientY;
      let hasDragged = false;
      const onTouchmove = (event: TouchEvent): void => {
        const [touch] = event.touches;
        const deltaX = touch.clientX - startX;
        const deltaY = touch.clientY - startY;
        const shift = 10;
        const isButtonMovedByAnyDirection = Math.abs(deltaX) > shift || Math.abs(deltaY) > shift;
        if (isButtonMovedByAnyDirection) {
          resetPosition(draggableElement);
          const newLeft = event.touches[0].clientX - offsetX;
          const newTop = event.touches[0].clientY - offsetY;
          const hasVerticalScroll = innerWidth - document.documentElement.clientWidth;
          const hasHorizontalScroll = innerHeight - document.documentElement.clientHeight;
          const maxX = innerWidth - elementWidth - +hasVerticalScroll;
          const maxY = innerHeight - elementHeight - +hasHorizontalScroll;
          const clampedLeft = Math.min(maxX, Math.max(0, newLeft));
          const clampedTop = Math.min(maxY, Math.max(0, newTop));
          draggableElement.style.left = `${clampedLeft}px`;
          draggableElement.style.top = `${clampedTop}px`;
          hasDragged = true;
        } else {
          hasDragged = false;
        }
      };

      const onTouchend = (): void => {
        const { left, top, width, height } = getCoordinates(draggableElement);
        const centerX = left + width / 2;
        const centerY = top + height / 2;
        const { clientWidth, clientHeight } = document.documentElement;
        if (hasDragged) {
          resetPosition(draggableElement);
          if (centerX < clientWidth / 2 && centerY < clientHeight / 2) {
            services.localStorageService.save(LocalStorageService.ButtonLiveChatPosition, ['top', 'left']);
            draggableElement.style.top = buttonPosition;
            draggableElement.style.left = buttonPosition;
          } else if (centerX > clientWidth / 2 && centerY < clientHeight / 2) {
            services.localStorageService.save(LocalStorageService.ButtonLiveChatPosition, ['top', 'right']);
            draggableElement.style.top = buttonPosition;
            draggableElement.style.right = buttonPosition;
          } else if (centerX < clientWidth / 2 && centerY > clientHeight / 2) {
            services.localStorageService.save(LocalStorageService.ButtonLiveChatPosition, ['bottom', 'left']);
            draggableElement.style.bottom = buttonPosition;
            draggableElement.style.left = buttonPosition;
          } else if (centerX > clientWidth / 2 && centerY > clientHeight / 2) {
            services.localStorageService.save(LocalStorageService.ButtonLiveChatPosition, ['bottom', 'right']);
            draggableElement.style.bottom = buttonPosition;
            draggableElement.style.right = buttonPosition;
          }
        } else {
          if (btnRef.current) {
            resetPosition(draggableElement);
            draggableElement.style.bottom = buttonPosition;
            draggableElement.style.right = buttonPosition;
            services.routerService.openApp();
            sendGAEvent('User', 'App opened');
          }
        }
        document.removeEventListener('touchmove', onTouchmove);
        document.removeEventListener('touchend', onTouchend);
      };

      document.addEventListener('touchmove', onTouchmove);
      document.addEventListener('touchend', onTouchend);
    }
  });
};
