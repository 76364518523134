import React, { Fragment, useCallback, useContext, useMemo } from 'react';
import { Message as MessageModel } from '@models/message.model';
import { Message } from '@components/chat/message';
import { ServiceContext } from '@services/service.provider';
import { debounce } from 'throttle-debounce';
import styles from './history.module.css';
import { compareWithNextMessageDate } from '@utils/compareWithNextMessageDate';
import { isAllMessagesFromToday } from '@components/chat/utils/is-messages-from-today';

interface IHistoryProps {
  list: MessageModel[];
}

const MaxFiringScrollEventInterval = 100;

const History: React.FunctionComponent<IHistoryProps> = ({ list }) => {
  const { twilioConversationsService } = useContext(ServiceContext);
  const isAllMessagesTodayDate = useMemo(() => isAllMessagesFromToday(list), [list]);
  const isLastPage = twilioConversationsService.isLastPage();
  const handleResend = useCallback(
    (message: MessageModel): void => {
      twilioConversationsService.resentMessage(message);
    },
    [twilioConversationsService],
  );

  const handleMessageVisible = useMemo(
    () =>
      debounce(MaxFiringScrollEventInterval, (index: number): void => {
        const lastReadMessageIndex = twilioConversationsService.getLastReadMessageIndex();
        if (!lastReadMessageIndex || index > lastReadMessageIndex) {
          twilioConversationsService.updateLastReadMessageIndex(index);
        }
      }),
    [twilioConversationsService],
  );

  return (
    <>
      {list.map((message, idx, array) => {
        let dateDivider = null;
        const isFirstMessage = idx === 0;
        let isSecondMessageFromSpecialist = false;
        if (!isAllMessagesTodayDate) {
          dateDivider = compareWithNextMessageDate(message.timestamp, array[idx + 1]?.timestamp, isFirstMessage);
        }
        if (idx > 0) {
          const prevMessage = array[idx - 1];
          const hasSameFromName = prevMessage?.attributes?.fromName === message?.attributes?.fromName;
          const hasSameAuthor = prevMessage?.author === message?.author;
          isSecondMessageFromSpecialist = hasSameFromName && hasSameAuthor;
        }
        return (
          <Fragment key={message.clientId}>
            {isFirstMessage && dateDivider && isLastPage && (
              <div className={styles.divider__wrapper} data-message="message">
                <span className={styles.divider}>{dateDivider}</span>
              </div>
            )}
            <Message
              onVisible={handleMessageVisible}
              message={message}
              onResend={handleResend}
              isLast={idx === array.length - 1}
              index={message.index}
              isSecondMessageFromSpecialist={isSecondMessageFromSpecialist}
            />
            {!isFirstMessage && dateDivider && (
              <div className={styles.divider__wrapper} data-message="message">
                <span className={styles.divider}>{dateDivider}</span>
              </div>
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export { History };
