import React, { useContext } from 'react';
import { Status } from '@components/chat/status';
import { ServiceContext } from '@services/service.provider';
import { useIsChatOffline } from '../hooks/use-is-chat-offline';

export const WelcomePanel: React.FunctionComponent = () => {
  const { settingsService } = useContext(ServiceContext);
  const isChatOffline = useIsChatOffline();
  const { AnonymChatOnlineWelcomeMessage, AnonymChatOfflineWelcomeMessage, AssetsSettings } = settingsService.settings;
  const { isLiveChat } = settingsService;
  const onlineUrl = isLiveChat ? AssetsSettings.LiveChatImageUrl : AssetsSettings.ChatBotImageUrl;
  const iconUrl = isChatOffline ? AssetsSettings.OfflineImageUrl : onlineUrl;

  // we're showing offline only for livechat
  const { Title, Text } = !isChatOffline ? AnonymChatOnlineWelcomeMessage : AnonymChatOfflineWelcomeMessage;

  return <Status isOnline={!isChatOffline} title={Title} text={Text} url={iconUrl} />;
};
