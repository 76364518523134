export enum ChatModeEnum {
  LiveChat,
  ChatBot,
  LiveChatBot,
  SeparatedLiveChatBot,
}

export enum SeparatedChatModeEnum {
  LiveChat = 'LiveChat',
  LiveChatBot = 'LiveChatBot',
}
