// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".stepper__stepper--2zp6m {\n  display: flex;\n  justify-content: center;\n}\n\n.stepper__stepper--2zp6m > div {\n  width: 8px;\n  height: 8px;\n  flex-shrink: 0;\n  border-radius: 50%;\n  transition: background-color 0.6s;\n}\n\n.stepper__stepper--2zp6m > div + div {\n  margin-left: 8px;\n}\n\n.stepper__active-step--rwWA6 {\n  opacity: 1;\n  background-color: var(--color-primary);\n}\n\n.stepper__gray-step--2XQTD {\n  background-color: var(--color-grey-500);\n}\n\n.stepper__light-gray-step--1rH3Y {\n  background-color: var(--color-grey-400);\n}\n\n.stepper__lightest-gray-step--_vibK {\n  background-color: var(--color-grey-200);\n}\n", "",{"version":3,"sources":["webpack://./src/components/stepper/stepper.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,cAAc;EACd,kBAAkB;EAClB,iCAAiC;AACnC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,sCAAsC;AACxC;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,uCAAuC;AACzC","sourcesContent":[".stepper {\n  display: flex;\n  justify-content: center;\n}\n\n.stepper > div {\n  width: 8px;\n  height: 8px;\n  flex-shrink: 0;\n  border-radius: 50%;\n  transition: background-color 0.6s;\n}\n\n.stepper > div + div {\n  margin-left: 8px;\n}\n\n.active-step {\n  opacity: 1;\n  background-color: var(--color-primary);\n}\n\n.gray-step {\n  background-color: var(--color-grey-500);\n}\n\n.light-gray-step {\n  background-color: var(--color-grey-400);\n}\n\n.lightest-gray-step {\n  background-color: var(--color-grey-200);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepper": "stepper__stepper--2zp6m",
	"active-step": "stepper__active-step--rwWA6",
	"gray-step": "stepper__gray-step--2XQTD",
	"light-gray-step": "stepper__light-gray-step--1rH3Y",
	"lightest-gray-step": "stepper__lightest-gray-step--_vibK"
};
export default ___CSS_LOADER_EXPORT___;
