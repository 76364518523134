import { AuthorEnum } from '@models/author.enum';
import { Intent } from '@models/intent/intent.model';
import { v4 as uuidv4 } from 'uuid';
import { MessageStatusEnum } from '@models/message-status.enum';
import { Nullable } from '@models/nullable.type';
import { IMessageAttributes } from './message-attributes.interface';

export class Message {
  public readonly timestamp: string;
  public readonly clientId: string;
  public intent: Intent;
  public isRejected = false;
  constructor(
    text: string,
    public readonly author: AuthorEnum,
    public index: number,
    timestamp?: string,
    clientId?: string,
    public status: Nullable<MessageStatusEnum> = null,
    public readonly attributes: Nullable<IMessageAttributes> = null,
  ) {
    this.timestamp = timestamp ?? new Date().toISOString();
    this.clientId = clientId ?? uuidv4();
    this.intent = new Intent(text);
  }

  public get text(): string {
    return this.intent.texts[0];
  }
}
