import { StorageService } from '@services/storage/storage.service';
import { Debug } from '@utils/debug/debug.decorator';

@Debug
export class SessionStorageService extends StorageService {
  public static readonly AccessToken = 'AccessToken';
  public static readonly RefreshToken = 'RefreshToken';
  public static readonly TwilioCredentials = 'TwilioCredentials';
  public static readonly TokenExpireTimeMs = 'TokenExpireTimeMs';
  public static readonly AvailabilityStatus = 'AvailabilityStatus';
  public static readonly ChatMode = 'ChatMode';
  public static readonly Route = 'Route';
  public static readonly IsChatOpen = 'IsChatOpen';
  public static readonly Settings = 'Settings';
  public static readonly Status = 'Status';
  public static readonly LogToConsole = 'LogToConsole';
  public static readonly LiveChatSkills = 'LiveChatSkills';
  public static readonly UserSentFirstMessageToChatType = 'UserSentFirstMessageToChatType';
  public static readonly AuthInOfm = 'AuthInOfm';
  public static readonly OfmAuthorizedData = 'OfmAuthorizedData';
  public static readonly GracePeriod = 'GracePeriod';
  public static readonly LogOutCreds = 'LogOutCreds';
  public static readonly LogOutTime = 'LogOutTime';
  public static readonly FocusedMessageIndex = 'focusedMessageIndex';
  public static readonly DeviceId = 'DeviceId';
}
