import { Nullable } from '@models/nullable.type';
import { Debug } from '@utils/debug/debug.decorator';
import { debounce } from 'throttle-debounce';

@Debug
export class FullHeightService {
  private _resizeHandler = debounce(100, this._setFullHeight.bind(this));
  private _cssVariablesRoot: Nullable<HTMLElement> = null;

  constructor(private _window: Window) {}

  public init(rootNode: HTMLElement): void {
    this._cssVariablesRoot = rootNode;
    this._setFullHeight();
    this._window.addEventListener('resize', this._resizeHandler);
    this._bindCSSVariables(rootNode);
  }

  private _bindCSSVariables(rootNode: HTMLElement): void {
    const style = this._window.getComputedStyle(rootNode);
    const { body } = this._window.document;
    body.style.setProperty('--livechat-app-desktop-width', style.getPropertyValue('--desktop-width'));
    body.style.setProperty('--livechat-app-desktop-height', style.getPropertyValue('--desktop-height'));
    body.style.setProperty('--livechat-app-border-radius', style.getPropertyValue('--border-radius'));
  }

  public dispose(): void {
    this._window.removeEventListener('resize', this._resizeHandler);
  }

  private _setFullHeight(): void {
    const fullHeight = `${this._window.innerHeight}px`;
    this._cssVariablesRoot?.style.setProperty('--full-height', fullHeight);
    const { body } = this._window.document;
    body.style.setProperty('--livechat-app-full-height', fullHeight);
  }
}
