import { FC, ReactNode } from 'react';
import ReactDOM from 'react-dom';

type PortalProps = {
  node?: HTMLElement | null;
  children: ReactNode;
};

export const Portal: FC<PortalProps> = ({ node, children }) => {
  const targetNode = node || document.body;
  return ReactDOM.createPortal(children, targetNode);
};
