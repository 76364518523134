import { useBehaviorSubject } from '@hooks/use-behavior-subject';
import { ServiceContext } from '@services/service.provider';
import { useContext } from 'react';

export const useFormTitle = (isSuccess?: boolean): string => {
  const { twilioConversationsService } = useContext(ServiceContext);
  const transferReponseData = useBehaviorSubject(twilioConversationsService.transferResponseData$);
  const result = isSuccess ? transferReponseData?.SuccessfulTransferTitle : transferReponseData?.ConfirmTransferTitle;

  return result || '';
};
