import { useCallback, useEffect, useRef } from 'react';

/**
 * Hook helps to get prev value by current value
 * @param value - observable variable
 * @description each render, except first render, we have the value of
 * prev render by assignment ref in useEffect or manually by function
 */
export function usePrevState<T>(value: T, preventUpdate = false, condition = true): [T | undefined, () => void] {
  const ref = useRef<T>();
  const setPrevValue = useCallback(() => {
    ref.current = value;
  }, [value]);

  useEffect(() => {
    !preventUpdate && condition && setPrevValue();
  }, [preventUpdate, setPrevValue, condition]);

  return [ref.current, setPrevValue];
}
