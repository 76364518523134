import { useContext, useEffect, useState } from 'react';
import { ServiceContext } from '@services/service.provider';

/**
 * Hook returns variable for initiation live chat button alert animation
 * @param isButtonRendered {boolean} is live chat button rendered
 * @return isShiverAnimation {Boolean} - is animation playing
 */
export function useAlertButtonAnimation(isButtonRendered: boolean): boolean {
  const { settingsService } = useContext(ServiceContext);
  const [isShiverAnimation, setShiverAnimation] = useState(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);
  const defaultAnimationTimeout = 5;
  const milisecsInSecond = 1000;
  const secondsInMin = 60;
  const { LiveChatButtonAlertAnimationTimeout } = settingsService.settings?.LiveChatButtonSettings;
  const animationTimeOut =
    secondsInMin * milisecsInSecond * (LiveChatButtonAlertAnimationTimeout ?? defaultAnimationTimeout);
  const animationLength = 3000;
  const initAnimation = (): void => {
    const setAnimation = (): void => {
      setShiverAnimation(true);
      const timeoutId = setTimeout(() => {
        clearTimeout(timeoutId);
        setShiverAnimation(false);
      }, animationLength);
    };
    const id = setInterval((): void => {
      setAnimation();
    }, animationTimeOut + animationLength);
    setIntervalId(id);
  };
  const stopAnimation = (): void => {
    if (intervalId) {
      clearInterval(intervalId);
    }
  };
  useEffect(() => {
    if (LiveChatButtonAlertAnimationTimeout === 0) {
      return;
    }
    isButtonRendered ? initAnimation() : stopAnimation();

    return stopAnimation;
  }, [isButtonRendered]);

  return isShiverAnimation;
}
