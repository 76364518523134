/**
 * Hook returns function that checks if the html element in the viewport
 * @param el {HTMLElement | null} HTML element
 * @param extraSpace {number} some additional space, so the block will be out of viewport + this space vertically
 * @return isInViewPort {Boolean} - is the element in the range of the viewport
 */
export const useElementInViewport = (el: HTMLDivElement | null, extraSpace = 0): (() => boolean) => {
  if (!el) {
    return (): boolean => true;
  }

  return (): boolean => {
    const rect = el?.getBoundingClientRect();

    if (!rect) {
      return true;
    }

    const isInViewPort =
      rect.bottom > 0 &&
      rect.right > 0 &&
      rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
      rect.top < (window.innerHeight || document.documentElement.clientHeight) + extraSpace;

    return isInViewPort;
  };
};
