import React, { useEffect, useRef } from 'react';
import styles from './status.module.css';
import classnames from 'classnames';
import { IconUnderlay } from '@components/icon-underlay';
import { AbstractStatusEnum } from '@models/abstract-status.enum';
import { Nullable } from '@models/nullable.type';

type Props = {
  isOnline: boolean;
  title: string;
  text?: string;
  url?: Nullable<string>;
};

export const Status: React.FunctionComponent<Props> = ({ title, text, isOnline, url = null }) => {
  const icon = isOnline ? 'online-circle' : 'offline-circle';
  const status = isOnline ? AbstractStatusEnum.info : AbstractStatusEnum.neutral;
  const headingRef = useRef<HTMLHeadingElement | null>(null);

  useEffect(() => {
    if (!isOnline) {
      const timer = setTimeout(() => {
        headingRef.current?.focus();
        clearTimeout(timer);
      }, 0);
    }
  });

  return (
    <div className={styles.status}>
      <IconUnderlay icon={icon} status={status} qaLocator="status_block_icon" url={url} />
      <div
        ref={headingRef}
        className={styles.title}
        data-qa="status_block_title"
        tabIndex={0}
        aria-label={`heading, ${title}, use tab to navigate to the next element`}
      >
        {title}
      </div>
      {text && (
        <div
          className={classnames(styles.text, { [styles.centered]: !isOnline })}
          dangerouslySetInnerHTML={{ __html: text }}
          data-qa="status_block_text"
          tabIndex={0}
          aria-label={`sub heading, ${text}, use tab to navigate to the next element`}
        />
      )}
    </div>
  );
};
