/* eslint-disable no-underscore-dangle */
import { isCarouselIntent } from '@guards/intent/is-carousel-intent.guard';
import { isPartialIntentResponse } from '@guards/intent/is-partial-intent-response.guard';
import { isSuggestionIntent } from '@guards/intent/is-suggestion-intent.guard';
import { isTextIntent } from '@guards/intent/is-text-intent.guard';
import { CarouselEnum, ICarousel } from '@models/intent/intents.interface';
import { IPartialIntentResponse } from '@models/intent/partial-intent-response.interface';
import { IQuickReply } from '@models/quick-reply.interface';

export class Intent {
  public texts: string[] = [];
  public quickReplies: IQuickReply[] = [];
  public carousel?: ICarousel;

  public constructor(jsonBody: string) {
    try {
      const partialIntentResponse = JSON.parse(jsonBody);

      if (isPartialIntentResponse(partialIntentResponse)) {
        this.texts = Intent._getTexts(partialIntentResponse);
        this.quickReplies = Intent._getQuickReplies(partialIntentResponse);
        this.carousel = Intent._getCarousel(partialIntentResponse);
      } else {
        this.texts = [jsonBody];
      }
    } catch {
      this.texts = [jsonBody];
      // an agent can use bot suggestions and can write just a message
    }
  }

  private static _getTexts(intentResponse: IPartialIntentResponse): string[] {
    return intentResponse.QueryResult.FulfillmentMessages.filter(isTextIntent)
      .map((entry) => entry.Text.Text_)
      .reduce((acc, val) => acc.concat(val), []);
  }

  private static _getQuickReplies(intentResponse: IPartialIntentResponse): IQuickReply[] {
    return intentResponse.QueryResult.FulfillmentMessages.filter(isSuggestionIntent)
      .map((entry) =>
        entry.Suggestions.Suggestions_.map((reply) => ({
          text: reply.Title,
          isDisabled: false,
        })),
      )
      .reduce((acc, val) => acc.concat(val), []);
  }

  private static _getCarousel(intentResponse: IPartialIntentResponse): ICarousel | undefined {
    const items = intentResponse.QueryResult.FulfillmentMessages.filter(isCarouselIntent)
      .map((entry) =>
        entry.CarouselSelect.Items.map((reply) => ({
          title: reply.Title,
          imageUri: reply.Image.ImageUri,
          description: reply.Description,
          clickMessage: reply.Info.Key,
        })),
      )
      .reduce((acc, val) => acc.concat(val), []);

    if (items.length > 0) {
      return {
        type: items[0]?.imageUri ? CarouselEnum.Image : CarouselEnum.Text,
        items,
      };
    }
  }
}
