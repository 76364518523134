import { getOFMAuthToken } from '@utils/handle-ofm-token-update';
import { useEffect, useState } from 'react';
import { EventsEnum } from '../eventType.enum';

/**
 * Hook returns status if the client logs in OFM
 * @return isLoggedIn - is client logged in OFM
 */
const useLoggedInOfm = (): boolean[] => {
  const hasToken = getOFMAuthToken();
  const [isLoggedIn, setIsLoggedIn] = useState(!!hasToken);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toggleIsLogin = (event: any): void => {
    const haveToken = event?.detail?.token;
    setIsLoggedIn(!!haveToken);
  };

  useEffect(() => {
    window.addEventListener(EventsEnum.TokenUpdate, toggleIsLogin);

    return (): void => {
      window.removeEventListener(EventsEnum.TokenUpdate, toggleIsLogin);
    };
  }, []);

  return [isLoggedIn];
};

export { useLoggedInOfm };
