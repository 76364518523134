// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".icon__icon--2UpUd {\n  display: block;\n  fill: currentColor;\n  box-sizing: border-box;\n}\n", "",{"version":3,"sources":["webpack://./src/components/icon/icon.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,sBAAsB;AACxB","sourcesContent":[".icon {\n  display: block;\n  fill: currentColor;\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "icon__icon--2UpUd"
};
export default ___CSS_LOADER_EXPORT___;
