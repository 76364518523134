import { configureStore } from '@reduxjs/toolkit';
import { chatReducer } from '@slices/chat.slice';
import { ofmReducer } from '@slices/ofm.slice';
import { settingsReducer } from '@slices/settings.slice';
import { themeReducer } from '@slices/theme.slice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

export const store = configureStore({
  reducer: { chat: chatReducer, settings: settingsReducer, ofm: ofmReducer, theme: themeReducer },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type Store = typeof store;
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
