export interface ISpecialErrorResponse {
  Error: IApiError;
}

export interface IApiError {
  Code: ApiErrorCodeEnum;
  Message: string;
}

export enum ApiErrorCodeEnum {
  NoInternet = -1,
  UnknownError = 0,
  SmsCallSessionIsNotStarted = 1,
  SmsCallSessionIsExpired = 2,
  MessageTooLong = 3,
  MessageContainsUnallowedUrl = 4,
  SendMessageLimitExceeded = 5,
  SessionCallWasAlreadyFinished = 6,
  InvalidSpecialist = 7,
  SmsCallSessionIsInvalid = 8,
  LiveChatAnonymousSessionWasClosed = 9,
  SmsCallInvalidPhoneNumber = 10,
  TheRequestedResourceConversationsParticipantsWasNotFound = 11,
  NoRatings = 12,
  EmptyRating = 13,
  CommentTooLong = 14,
  NoChannelPermission = 15,
  MessageWithXss = 16,
  TooLargeDataInContactInfoParameters = 17,
  RecaptchaScoreWasLowerThenMinimal = 21,
}
