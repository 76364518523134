import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { Router } from '@components/router';
import styles from './root.module.css';
import './themes.module.css';
import classnames from 'classnames';
import { ServiceContext } from '@services/service.provider';
import { useBehaviorSubject } from '@hooks/use-behavior-subject';
import { usePrevState } from '@hooks/use-prev-value';
import 'normalize.css';
import '@ast/magma/shared/var.css';
import '@ast/magma/shared/index.css';
import { sendGAEvent } from '@utils/GA';
import { isWebView } from '@utils/is-webview';
import { Icon } from '@components/icon';
import { IconSourceUrl } from '@components/icon-source-url';
import { useLoggedInOfm } from '@hooks/use-logged-in-ofm';
import { useHideChatBySpecificUrl } from '@hooks/use-hide-chat-by-specific-url';
import rootStyles from '@components/root/root.module.css';
import { useAlertButtonAnimation } from '@hooks/use-alert-button-animation';
import { isMobile } from 'react-device-detect';
import { handleDragAndDropButton } from '@utils/handle-drag-and-drop-button';
import { Counter } from '@components/root/counter';
import { useAppSelector } from '@store';
import { useAppTheme } from '@hooks/use-app-theme';

type Props = {
  isBrowser: boolean;
  hasHiddenAttribute: boolean;
};

export const Root: React.FunctionComponent<Props> = ({ isBrowser: isBrowserAttribute, hasHiddenAttribute }) => {
  const { routerService, settingsService } = useContext(ServiceContext);
  const isOpen = useBehaviorSubject(routerService.isAppOpen$);
  const [isPrevOpen] = usePrevState(isOpen);
  const [isLoggedIn] = useLoggedInOfm();
  const isHideLiveChat = useHideChatBySpecificUrl(settingsService.settings?.PagesWithoutLiveChat);
  const isAndroid = useMemo(() => navigator.userAgent.toLowerCase().indexOf('android') > -1, []);
  const isBrowser = isBrowserAttribute;
  const mainIcon = settingsService.settings.AssetsSettings.OpenChatImageUrl;
  const btnRef = useRef<HTMLButtonElement | null>(null);
  const theme = useAppSelector((state) => state.theme.currentTheme);
  const { handleThemeChange } = useAppTheme();
  // screen reader has to know that we close chat. By this point we focus on init state - chat button
  useEffect(() => {
    const callback = (e: KeyboardEvent): void => {
      if (e.key === 'Escape' && isOpen) {
        routerService.closeApp();
        btnRef.current?.focus();
      }
    };
    document.addEventListener('keydown', callback);

    return (): void => {
      document.removeEventListener('keydown', callback);
    };
  }, [isOpen, routerService]);

  const isLiveChatButtonDraggableForMobile =
    settingsService.settings?.LiveChatButtonSettings?.IsLiveChatButtonDraggableForMobile;
  useEffect(() => {
    if (isLiveChatButtonDraggableForMobile) {
      handleDragAndDropButton(btnRef);
    }
  }, [isLiveChatButtonDraggableForMobile]);

  const renderRouter = (): JSX.Element => (
    <div
      data-theme={theme}
      className={classnames(styles.root, {
        [styles.rootAndroid]: isAndroid,
        [styles.rootIos]: !isAndroid,
        [styles.compact]: isBrowser,
      })}
    >
      <Router isBrowser={isBrowser} isAndroid={isAndroid} handleThemeChange={handleThemeChange} />
      <div className={rootStyles['visually-hidden']} id={'phrase-container'} role="status" aria-live="assertive"></div>
    </div>
  );

  const isShiverAnimation = useAlertButtonAnimation(!isPrevOpen);
  const handleOpen = (): void => {
    routerService.openApp();
    sendGAEvent('User', 'App opened');
  };

  const renderButton = (): JSX.Element | null => {
    if (!isPrevOpen) {
      sendGAEvent('User', 'Icon appeared');
    }
    const text = settingsService.settings?.LiveChatButtonSettings?.LiveChatButtonText;
    return (
      <div data-theme={theme} className={classnames({ [styles.animationShiver]: isShiverAnimation })}>
        <button
          ref={btnRef}
          className={classnames(styles.button, {
            [styles.animation]: !isPrevOpen,
            [styles.isHidden]: hasHiddenAttribute && isLoggedIn,
            [styles['button--withText']]: Boolean(text),
          })}
          onClick={handleOpen}
          data-qa="open_chat_window_button"
          aria-label="button, live chat, press enter to start live chat"
        >
          {Boolean(text) && (
            <span className={classnames(styles.label, { [styles['label--mobile']]: isMobile })}>{text}</span>
          )}
          <Counter />
          <IconSourceUrl
            url={mainIcon}
            fallbackIcon={<Icon name="main-button" sizes={[38]} className={styles.icon} qaLocator="main-button" />}
            alt="Open live chat icon"
          />
        </button>
      </div>
    );
  };

  if (isHideLiveChat || (isBrowser && isWebView())) {
    return null;
  }

  if (isBrowser) {
    return isOpen ? renderRouter() : renderButton();
  }

  return renderRouter();
};
