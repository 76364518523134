import React, { useState } from 'react';
import { Props as IconProps } from '@components/icon';
import { Nullable } from '@models/nullable.type';

type Props = {
  url: Nullable<string>;
  fallbackIcon: React.ReactElement<IconProps>;
  className?: string;
  qaLocator?: string;
  alt?: string;
};

export const IconSourceUrl: React.FunctionComponent<Props> = ({ url, fallbackIcon, className, qaLocator, alt }) => {
  const [isLoadFailed, setIsLoadFailed] = useState(false);
  const handleLoadError = (): void => {
    setIsLoadFailed(true);
  };

  if (isLoadFailed || !url) {
    return fallbackIcon;
  }

  return <img src={url} className={className} data-qa={qaLocator} onError={handleLoadError} alt={alt} role="img" />;
};
