export enum RoutesEnum {
  Menu = 'Menu',
  Chat = 'Chat',
  Rating = 'Rating',
  CustomerIdentificationForm = 'CustomerIdentificationForm',
  ChoosePhone = 'ChoosePhone',
  SmsCodeForm = 'SmsCodeForm',
  TransferConfirmation = 'TransferConfirmation',
  TransferForm = 'TransferForm',
  TransferSuccess = 'TransferSuccess',
}
