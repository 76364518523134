// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../styles/title.module.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../styles/button.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menu__menu--lplQu {\n  padding: 16px;\n}\n\n.menu__title--2PBwV {\n  margin: 8px 0 24px;\n}\n\n.menu__button--2cgzW {\n  color: var(--color-primary);\n  background-color: var(--color-primary-contrast);\n  font-size: 16px;\n  width: 100%;\n  text-decoration: none;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 16px;\n  border-radius: var(--border-radius);\n}\n\n.menu__button--2cgzW:disabled {\n  color: var(--color-disabled);\n  cursor: default;\n}\n\n.menu__button--2cgzW + .menu__button--2cgzW {\n  margin-top: 8px;\n}\n\n.menu__icon--27yDh {\n  margin-right: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/router/menu/menu.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EAEE,kBAAkB;AACpB;;AAEA;EAEE,2BAA2B;EAC3B,+CAA+C;EAC/C,eAAe;EACf,WAAW;EACX,qBAAqB;EACrB,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,aAAa;EACb,mCAAmC;AACrC;;AAEA;EACE,4BAA4B;EAC5B,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".menu {\n  padding: 16px;\n}\n\n.title {\n  composes: title from '~@styles/title.module.css';\n  margin: 8px 0 24px;\n}\n\n.button {\n  composes: button from '~@styles/button.module.css';\n  color: var(--color-primary);\n  background-color: var(--color-primary-contrast);\n  font-size: 16px;\n  width: 100%;\n  text-decoration: none;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 16px;\n  border-radius: var(--border-radius);\n}\n\n.button:disabled {\n  color: var(--color-disabled);\n  cursor: default;\n}\n\n.button + .button {\n  margin-top: 8px;\n}\n\n.icon {\n  margin-right: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "menu__menu--lplQu",
	"title": "menu__title--2PBwV " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["title"] + "",
	"button": "menu__button--2cgzW " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["button"] + "",
	"icon": "menu__icon--27yDh"
};
export default ___CSS_LOADER_EXPORT___;
