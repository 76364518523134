import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Nullable } from '@models/nullable.type';
import { ILivechatSettings } from '@api/settings';

interface IAppStoreSettings {
  data: Nullable<ILivechatSettings>;
}

const initialState: IAppStoreSettings = {
  data: null,
};

const settingsSlice = createSlice({
  name: 'Settings',
  initialState,
  reducers: {
    setSettings: (state, { payload }: PayloadAction<ILivechatSettings>) => {
      state.data = payload;
    },
  },
});

export const SettingsActions = settingsSlice.actions;
export const settingsReducer = settingsSlice.reducer;
export type SettingsActionsType = typeof SettingsActions;
