import { useAppSelector } from '@store';
import { useEffect, useRef, useState } from 'react';

const LiveOfFormTimeout = 20 * 1000;

export const useChoosePhoneError = (): string => {
  const error = useAppSelector((state) => state.chat.phonesToChoose.error);
  const settingError = useAppSelector((state) => state.settings.data?.AnonymCustomerChoosePhoneSettings?.RejectMessage);
  const isLoaded = useAppSelector((state) => state.chat.phonesToChoose.loaded);
  const [errorMessage, setErrorMessage] = useState('');
  const timerId = useRef(0);

  useEffect(() => {
    if (isLoaded) {
      timerId.current = window.setTimeout(() => {
        setErrorMessage(settingError || '');
      }, LiveOfFormTimeout);
    } else {
      setErrorMessage('');
    }

    return (): void => {
      if (timerId.current) {
        window.clearTimeout(timerId.current);
      }
    };
  }, [isLoaded, settingError]);

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    }
  }, [error]);

  return errorMessage;
};
