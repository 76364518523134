export const storageServicePrefix = 'as.lc.';
export class StorageService {
  private readonly _prefix: string;

  constructor(private _storage: Storage) {
    this._prefix = storageServicePrefix;
  }

  public save<T>(key: string, value: T): T {
    this._storage.setItem(`${this._prefix}${key}`, JSON.stringify(value));
    return value;
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  public get<T = any>(key: string): T {
    const result = this._storage.getItem(`${this._prefix}${key}`);
    return result ? JSON.parse(result) : result;
  }

  public remove(key: string): void {
    this._storage.removeItem(`${this._prefix}${key}`);
  }
}
