import React from 'react';
import styles from './icon.module.css';
import classnames from 'classnames';

export type Props = {
  name: string;
  sizes: [number, number?];
  className?: string;
  qaLocator?: string;
};

/**
 * Pass only width sizes number for square icon
 */
export const Icon: React.FunctionComponent<Props> = ({ name, sizes, className, qaLocator }) => {
  const [width, height] = sizes;
  const viewBox = `0 0 ${width} ${height ?? width}`;
  return (
    <svg
      viewBox={viewBox}
      width={width}
      height={height ?? width}
      className={classnames(styles.icon, className)}
      data-qa={qaLocator}
    >
      <use href={`#${name}`} xlinkHref={`#${name}`} />
    </svg>
  );
};
