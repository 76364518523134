import { Loader } from '@components/loader';
import React, { FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { timeOutResponseInSeconds } from '../../consts';
import styles from './transfer-confirmation.module.css';
import { ServiceContext } from '@services/service.provider';
import { Scroller } from '@components/scroller';
import classnames from 'classnames';
import CircleArrowRight from '@ast/magma/components/icon/icons/CircleArrowRight';
import CheckCircle from '@ast/magma/components/icon/icons/CheckCircle';
import { Button } from '@components/button';
import { toLocaleDecimalString } from '@utils/helpers';
import { useFormTitle } from './transfer-confirmation';
import { useFocusOnRef } from '@hooks/use-focus-on-ref';

interface IProps {
  success?: boolean;
}

export const TransferConfirmation: React.FunctionComponent<IProps> = ({ success }) => {
  const { routerService, liveChatService } = useContext(ServiceContext);
  const { from, to, amount, loan } = liveChatService.transferInfo;
  const [isLoading, setIsLoading] = useState(false);
  const fromString = `${from.Name} ${from.Type} ${from.Number}`;
  const toString = `${to.Name} ${to.Type} ${to.Number}`;
  const title = useFormTitle(success);
  const timerId = useRef(0);

  const getTransferConfirmAmount = (): string => {
    return loan?.Value
      ? `${loan.Label} $${toLocaleDecimalString(loan.Value, 2)}`
      : `$${toLocaleDecimalString(amount, 2)}`;
  };

  const skipHandler = (): void => {
    routerService.openChat();
    if (!success) {
      liveChatService.skipTransfer();
    }
    liveChatService.resetTransferInfo();
  };

  const editHandler = (): void => {
    routerService.openTransferForm();
  };

  const submitHandler = async (event: FormEvent): Promise<void> => {
    event.preventDefault();
    setIsLoading(true);

    const response = await liveChatService.submitTrasfer();

    if (response.Error) {
      setIsLoading(false);
    } else {
      timerId.current = window.setTimeout(() => {
        setIsLoading(false);
      }, timeOutResponseInSeconds);
    }
  };

  useEffect(
    () => (): void => {
      if (timerId.current) {
        window.clearTimeout(timerId.current);
      }
    },
    [],
  );
  const formRef = useRef<HTMLFormElement | null>(null);
  const titleRef = useFocusOnRef(formRef);
  return (
    <Scroller>
      <form
        ref={formRef}
        className={classnames(styles.form, { [styles.formAnimation]: success, [styles.formDisabled]: isLoading })}
        onSubmit={submitHandler}
      >
        <Loader isLoading={isLoading} />
        <div data-qa={success ? 'transfer_success_header' : 'transfer_confirmation_header'} className={styles.title}>
          <div className={classnames(styles.titleShadow, { [styles.successShadow]: success })} />
          {success ? (
            <CheckCircle className={classnames(styles.titleSvg, styles.successSvg)} />
          ) : (
            <CircleArrowRight className={styles.titleSvg} />
          )}
        </div>
        <div
          ref={titleRef}
          tabIndex={0}
          data-qa={success ? 'transfer_success_title' : 'transfer_confirmation_title'}
          className={styles.titleText}
          aria-label={`heading, ${title}, use tab to navigate to the next element`}
        >
          {title}
        </div>
        <ul className={styles.list}>
          <li data-qa={success ? 'transfer_success_from' : 'transfer_confirmation_from'}>
            <span>From</span> - {fromString}
          </li>
          <li data-qa={success ? 'transfer_success_to' : 'transfer_confirmation_to'}>
            <span>To</span> - {toString}
          </li>
          <li data-qa={success ? 'transfer_success_amount' : 'transfer_confirmation_amount'}>
            <span>Amount</span> - {getTransferConfirmAmount()}
          </li>
        </ul>
        <div className={classnames(styles.buttons, { [styles.successButtons]: success })}>
          {!success && (
            <Button
              qaLocator="transfer_confirmation_submit_button"
              type="submit"
              primary
              disabled={isLoading}
              ariaLabel="button one of three, confirm, press enter to confirm the transfer"
            >
              Confirm
            </Button>
          )}
          {!success && (
            <Button
              qaLocator="transfer_confirmation_edit_button"
              onClick={editHandler}
              ariaLabel="button two of three, edit, press enter to edit the transfer"
            >
              Edit
            </Button>
          )}
          <Button
            qaLocator={success ? 'transfer_success_cancel_button' : 'transfer_confirmation_cancel_button'}
            onClick={skipHandler}
            ariaLabel={`button ${success ? '' : 'three of three'}, ${success ? 'close' : 'cancel'}, press enter to ${
              success ? 'close success window' : 'cancel the transfer'
            }`}
          >
            {success ? 'Close' : 'Cancel'}
          </Button>
        </div>
      </form>
    </Scroller>
  );
};
