import React, { useContext, useEffect, useRef } from 'react';
import classNames from 'classnames';
import styles from './privacy.module.css';
import { ServiceContext } from '@services/service.provider';
import { useBehaviorSubject } from '@hooks/use-behavior-subject';
import { PrivacyPolicyStateEnum } from '@services/privacy-policy/privacy-policy.service';

export const Privacy: React.FunctionComponent = () => {
  const { settingsService, privacyPolicyService } = useContext(ServiceContext);
  const privacyPolicyState = useBehaviorSubject(privacyPolicyService.privacyState$);
  const { settings } = settingsService;
  const {
    Title,
    DeclineButtonLabel,
    DeclineTitle,
    AcceptButtonLabel,
    PrivacyPolicyDescription,
    BackToChatButtonLabel,
  } = settings.PrivacyPolicySettings;
  const isDeclined = privacyPolicyState === PrivacyPolicyStateEnum.Declined;
  const isAccepted = privacyPolicyState === PrivacyPolicyStateEnum.Accepted;

  const handleAccept = (): void => {
    privacyPolicyService.accept();
  };

  const handleDecline = (): void => {
    privacyPolicyService.decline();
  };

  const handleBack = (): void => {
    privacyPolicyService.reset();
  };

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const title = containerRef.current?.querySelector('h1');
    if (title) {
      title.focus();
    }
  });

  if (isAccepted) {
    return null;
  }

  return (
    <div className={styles.overlay}>
      <div className={styles.privacy} ref={containerRef}>
        {isDeclined ? (
          <>
            <h1
              className={styles.title}
              data-qa="privacy_title"
              tabIndex={0}
              aria-label={`heading, ${DeclineTitle}, press tab to navigate to the next element`}
            >
              {DeclineTitle}
            </h1>

            <div className={styles.controls}>
              <button
                className={classNames(styles.button, styles.reset)}
                onClick={handleBack}
                data-qa="privacy_back_button"
                aria-label="button, back to chat, press enter to open"
              >
                {BackToChatButtonLabel}
              </button>
            </div>
          </>
        ) : (
          <>
            <h1
              className={styles.title}
              aria-label={`heading, ${DeclineTitle}, press tab to navigate to the next element`}
              data-qa="privacy_title"
              tabIndex={0}
            >
              {Title}
            </h1>
            <p
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: PrivacyPolicyDescription }}
              data-qa="privacy_description"
              aria-label={`sub-heading, ${PrivacyPolicyDescription}, press tab to navigate to the next element`}
              tabIndex={0}
            />
            <div className={styles.controls}>
              <button
                className={classNames(styles.button, styles.primary, styles.accept)}
                onClick={handleAccept}
                data-qa="privacy_accept_button"
                aria-label="button one of two, accept privacy, press enter to open"
              >
                {AcceptButtonLabel}
              </button>
              <button
                onClick={handleDecline}
                className={classNames(styles.button, styles.decline)}
                type="button"
                data-qa="privacy_decline_button"
                aria-label="button two of two, decline privacy, press enter to open"
              >
                {DeclineButtonLabel}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
