import { Message } from '@models/message.model';
import { isToday } from '@utils/compareWithNextMessageDate';

/**
 * Checks if all messages from today
 * @return  boolean - if all messages from today
 * @param messages
 */

export const isAllMessagesFromToday = (messages: Message[]): boolean =>
  messages.every((message) => {
    const messageDate = new Date(message.timestamp);
    const day = messageDate.getDate();
    const month = messageDate.getMonth();
    const year = messageDate.getFullYear();

    return isToday(day, month, year);
  });
