import React, { useContext, useEffect, useState } from 'react';
import { Privacy } from '@components/privacy';
import { Actions } from '@components/chat/actions';
import { Form } from '@components/chat/form';
import { Resolve } from '@components/chat/resolve';
import { WelcomePanel } from '@components/chat/welcome-panel';
import { LiveChatInitialMessage } from '@components/chat/live-chat-initial-message';
import { useBehaviorSubject } from '@hooks/use-behavior-subject';
import { IQuickReply } from '@models/quick-reply.interface';
import { ServiceContext } from '@services/service.provider';
import styles from './chat.module.css';
import useScrollToBottomChat from './hooks/use-scroll-to-bottom';
import { useResolve } from './hooks/use-resolve';
import { PrivacyPolicyStateEnum } from '@services/privacy-policy/privacy-policy.service';
import { useFilterListByEmpty, useThrottledScrollHandler } from './chat.hooks';
import { History } from './history';
import { AriaLastMessage } from './aria-last-message';
import { useArrowsToNavigate } from '@components/chat/hooks/use-arrows-to-navigate';

const Chat: React.FunctionComponent<{ isBrowser: boolean }> = ({ isBrowser }) => {
  const { twilioConversationsService, quickActionService, privacyPolicyService } = useContext(ServiceContext);
  const areMessagesFetching = useBehaviorSubject(twilioConversationsService.messagesFetching$);
  const privacyState = useBehaviorSubject(privacyPolicyService.privacyState$);
  const actions = useBehaviorSubject(quickActionService.actions$);
  const visibleMessagesSortedByIndex = useFilterListByEmpty();
  const { canResolveChat, resolveChat } = useResolve();
  const [isDragging, setIsDragging] = useState(false);

  /**
   * Auto scroll and fetching by scrolling logic
   */
  const scrollMethods = useScrollToBottomChat(visibleMessagesSortedByIndex.length);
  const { handleScroll, scrollRef } = useThrottledScrollHandler();
  const resetCurrentFocussedMessageIndex = useArrowsToNavigate(scrollRef);

  useEffect(() => {
    twilioConversationsService.onComponentRendered();
  }, [twilioConversationsService]);

  const handleActionClick = ({ text, skill, buttonId }: IQuickReply): void => {
    resetCurrentFocussedMessageIndex();
    twilioConversationsService.submitUserMessage({ text, skill, buttonId });
  };

  const userScrollHandler = (): void => {
    scrollMethods.handleScrollForDummy();
    handleScroll();
  };

  const onWheel = (): void => {
    userScrollHandler();
  };

  const onTouchMove = (): void => {
    userScrollHandler();
  };

  const onKeyDown = (e: KeyboardEvent): void => {
    switch (e.key) {
      case 'ArrowUp':
        setIsDragging(true);
        break;
    }
  };

  const onKeyUp = (e: KeyboardEvent): void => {
    switch (e.key) {
      case 'ArrowUp':
        setIsDragging(false);
        break;
    }
  };

  // To cover only the case with scrollbar thumb
  const onScroll = (): void => {
    if (isDragging) {
      userScrollHandler();
    }
  };

  const onMouseDown = (): void => {
    setIsDragging(true);
  };

  const onMouseUp = (): void => {
    setIsDragging(false);
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);

    return (): void => {
      document.removeEventListener('keyup', onKeyUp);
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  return (
    <div className={styles.chat}>
      <Resolve canResolveChat={canResolveChat} resolveChat={resolveChat} />
      {areMessagesFetching && visibleMessagesSortedByIndex.length > 0 && <div className={styles.bar} />}
      <div className={styles.roll}>
        <div
          ref={scrollRef}
          onScroll={onScroll}
          className={styles.messages}
          onWheel={onWheel}
          onTouchMove={onTouchMove}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          aria-live="polite"
          aria-relevant="text"
        >
          <WelcomePanel />
          <LiveChatInitialMessage />
          <History list={visibleMessagesSortedByIndex} />
          <Actions actions={actions} data-qa="prompt_btn" onClick={handleActionClick} />
          <div ref={scrollMethods.lastChildDummyRef} className={styles.lastChildDummy}></div>
          <AriaLastMessage list={visibleMessagesSortedByIndex} />
        </div>
      </div>
      <Form
        turnViewingHistoryOff={scrollMethods.turnViewingHistoryOff}
        areMessagesFetching={areMessagesFetching}
        areNoMessages={!visibleMessagesSortedByIndex.length}
        isBrowser={isBrowser}
        isOnPrivacyModal={privacyState === PrivacyPolicyStateEnum.Initial}
        resetCurrentFocussedMessageIndex={resetCurrentFocussedMessageIndex}
      />
      <Privacy />
    </div>
  );
};

export { Chat };
