export interface ILogRequest {
  Level: LogLevelEnum;
  Message: string;
}

export enum LogLevelEnum {
  Trace = 0,
  Debug = 1,
  Info = 2,
  Warning = 3,
  Error = 4,
  Critical = 5,
  None = 6,
}
