import React from 'react';
import styles from './carousel.module.css';

type Props = {
  title: string;
  description: string;
  slideIndex?: number;
};

export const TextContent: React.FunctionComponent<Props> = ({ title, description, slideIndex }) => (
  <div className={styles.card} data-qa="carousel_cards" data-index={slideIndex}>
    <h6
      className={styles.title}
      data-tab-element={true}
      tabIndex={-1}
      aria-label={`carousel title, ${title}, use tab to navigate to the next element`}
    >
      {title}
    </h6>
    <p
      className={styles.description}
      data-tab-element={true}
      tabIndex={-1}
      aria-label={`carousel description, ${description}, use tab to navigate to the next element`}
    >
      {description}
    </p>
  </div>
);
