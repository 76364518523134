import { BehaviorSubject, Observable, filter, map, withLatestFrom } from 'rxjs';
import { HTTPClient } from '@api/http-client';
import { LoggingService } from '@services/logging/logging.service';
import { SessionService } from '@services/session/session.service';
import { Debug } from '@utils/debug/debug.decorator';
import { SessionStorageService } from '@services/storage/session-storage.service';
import { SettingsService } from '@services/settings/settings.service';
import { ChatModeEnum, SeparatedChatModeEnum } from '@models/chat-mode.enum';

@Debug
export class StatusService {
  private _pollingTimerId = 0;
  private readonly _startSessionHandler = this._cancelPolling.bind(this);
  public static SuccessPollingInterval = 5 * 60 * 1000; // 5 minutes
  public static FailurePollingInterval = 30 * 1000; // 30 seconds
  public readonly requestStatusHandler = this.requestStatus.bind(this);
  public isAgentOnline$ = new BehaviorSubject(false);
  public isOnlineLoading$ = new BehaviorSubject(false);

  constructor(
    private _httpClient: HTTPClient,
    private _loggingService: LoggingService,
    private _sessionService: SessionService,
    private _sessionStorageService: SessionStorageService,
    private _window: Window,
    private _settingsService: SettingsService,
  ) {}

  public async init(): Promise<void> {
    this._sessionService.onStartSession$.subscribe(this._startSessionHandler);
    this._sessionService.onEndSession$.subscribe(() => this.isAgentOnline$.next(false));
    this.isAgentOnline$.next(Boolean(this._sessionStorageService.get(SessionStorageService.Status)));
    await this.requestStatus();
  }

  public async requestStatus(): Promise<boolean> {
    if (this._sessionService.isActive && this.isOnline) {
      return true;
    }
    return this.tryRequestStatus();
  }

  public async tryRequestStatus(): Promise<boolean> {
    try {
      this.isOnlineLoading$.next(true);
      const isOnline = await this._httpClient.status();
      this._sessionStorageService.save(SessionStorageService.Status, isOnline);
      this.isAgentOnline$.next(isOnline);
      this._scheduleRequestStatusIn();
      return isOnline;
    } catch (error) {
      this._loggingService.error(error, 'Failed to fetch status');
      this._scheduleRequestStatusIn(StatusService.FailurePollingInterval);
      return false;
    } finally {
      this.isOnlineLoading$.next(false);
    }
  }

  public get isOnline(): boolean {
    return this.isAgentOnline$.value;
  }

  public get isChatOnline$(): Observable<boolean> {
    return this.isAgentOnline$.pipe(
      withLatestFrom(this._settingsService.separatedMode$, this.isOnlineLoading$),
      map(([isOnline, separatedMode, isOnlineLoading]) => {
        switch (this._settingsService.settings?.Mode) {
          case ChatModeEnum.SeparatedLiveChatBot:
            return isOnline || isOnlineLoading || separatedMode !== SeparatedChatModeEnum.LiveChat;
          case ChatModeEnum.LiveChat:
            return isOnline;
          case ChatModeEnum.ChatBot:
          case ChatModeEnum.LiveChatBot:
            return true;
          default:
            return false;
        }
      }),
    );
  }

  public get onGoingOnline$(): Observable<true> {
    return this.isChatOnline$.pipe(filter(Boolean));
  }

  private _cancelPolling(): void {
    this._window.clearTimeout(this._pollingTimerId);
  }

  private _scheduleRequestStatusIn(timeout = StatusService.SuccessPollingInterval): void {
    if (!this._settingsService.settings || this._settingsService.isLiveChat) {
      this._cancelPolling();
      this._pollingTimerId = this._window.setTimeout(this.requestStatusHandler, timeout);
    }
  }
}
