import { BehaviorSubject, Observable, map, withLatestFrom } from 'rxjs';
import { HTTPClient } from '@api/http-client';
import { ISendSystemMessageResponse } from '@api/send-system-message';
import { SystemMessageTypeEnum } from '@models/system-message-type.enum';
import { IContactInfo } from '@services/livechat/contact-info';
import { LoggingService } from '@services/logging/logging.service';
import { TwilioConversationsService } from '@services/twilio-conversations/twilio-conversations.service';
import { Debug } from '@utils/debug/debug.decorator';
import { CustomerIdentification, ITransferAccount, ITransferAction, ITransferRequest } from './livechat.models';
import { IErrorLike } from '@models/error-like.interface';
import { Nullable } from '@models/nullable.type';
import { ITwilioCredentials } from '@api/session';
import { SettingsService } from '@services/settings/settings.service';
import { ApiErrorCodeEnum } from '@api/specialError';
import { RouterService } from '@services/router/router.service';
import { getDefautTransferInfo } from '@utils/helpers';
import { StatusService } from '@services/status/status.service';
import { Store } from '@store';

@Debug
export class LiveChatService {
  public typedMessage$ = new BehaviorSubject('');
  public transferInfo: ITransferRequest = getDefautTransferInfo();
  public savedContactInfoForm: Nullable<IContactInfo> = null;
  public choosenPhoneForSMS = '';

  public readonly setTypedMessageHandler = this._setTypedMessage.bind(this);

  constructor(
    private readonly _httpClient: HTTPClient,
    private readonly _twilioConversationsService: TwilioConversationsService,
    private readonly _loggingService: LoggingService,
    private readonly _settingsService: SettingsService,
    private readonly _routerService: RouterService,
    private readonly _statusService: StatusService,
    private readonly _store: Store,
  ) {
    this._twilioConversationsService.isTransferTransactionFailed$.subscribe(() => this.resetTransferInfo());
  }

  public get isSubmitDisabled$(): Observable<boolean> {
    return this.typedMessage$.pipe(
      withLatestFrom(
        this._statusService.isChatOnline$,
        this._twilioConversationsService.isInitialized$,
        this._twilioConversationsService.isChatResolving$,
      ),
      map(([typedMessage, isChatOnline, isInitialized, isChatResolving]) => {
        const maxMessageLength = this._settingsService.settings.MaxMessageLength;
        const trimmedMessage = typedMessage.trim();
        const trimmedMessageLength = trimmedMessage.length;
        const isMessageEmpty = trimmedMessageLength === 0;
        const isLengthExceeded = maxMessageLength !== 0 && trimmedMessageLength > maxMessageLength;

        return isMessageEmpty || isLengthExceeded || !isChatOnline || !isInitialized || isChatResolving;
      }),
    );
  }

  private async _sendSystemMessage(
    type: SystemMessageTypeEnum,
    credentials: Nullable<ITwilioCredentials>,
    attributes: Record<string, unknown> = {},
    headers: Record<string, string> = {},
    logError: (error: unknown) => void,
  ): Promise<ISendSystemMessageResponse> {
    try {
      return await this._httpClient.sendSystemMessage(type, credentials, attributes, headers);
    } catch (error) {
      const isNoInternet = (error as IErrorLike).message === 'Network Error';

      if (!isNoInternet) {
        logError(error);
      }

      return {
        Error: {
          Code: isNoInternet ? ApiErrorCodeEnum.NoInternet : ApiErrorCodeEnum.UnknownError,
          Message: isNoInternet
            ? this._settingsService.settings.InternetConnectionErrorMessage
            : this._settingsService.settings.BackendErrorMessage,
        },
      };
    }
  }

  private _setTypedMessage(message: string): void {
    this.typedMessage$.next(message);
  }

  private _transferErrorHandler(error: unknown): void {
    this._loggingService.error(error, 'Failed to submit transfer');
  }

  public resetTransferInfo(): void {
    this.transferInfo = getDefautTransferInfo();
  }

  public setTransferInfo(
    from?: Nullable<ITransferAccount>,
    to?: Nullable<ITransferAccount>,
    amount = 0,
    loan?: Nullable<ITransferAction>,
  ): void {
    if (from && to && (amount || loan?.Value)) {
      this.transferInfo = { from, to, amount: amount, loan };
    }
  }

  public async submitContactInfo(contactInfo: IContactInfo): Promise<ISendSystemMessageResponse> {
    return this._sendSystemMessage(
      SystemMessageTypeEnum.ResponseAnonymCustomerContactInfo,
      this._twilioConversationsService.twilioCredentials,
      { AnonymCustomerContactInfo: contactInfo },
      {},
      (error) => this._loggingService.error(error, 'Failed to submit contact info'),
    );
  }

  public async submitCustomerIdentificationForm(
    customerIdentification: CustomerIdentification,
    RecaptchaIsEnabled: boolean,
    isRecaptchaV2: boolean,
    recaptchaToken: string | null,
  ): Promise<ISendSystemMessageResponse> {
    if (RecaptchaIsEnabled && recaptchaToken !== null) {
      return this._sendSystemMessage(
        SystemMessageTypeEnum.CustomerIdentificationFormResponse,
        this._twilioConversationsService.twilioCredentials,
        { AnonymAuthInfo: customerIdentification },
        {
          'X-Recaptcha-Token': recaptchaToken,
          'X-Is-Recaptcha-V2': isRecaptchaV2.toString(),
        },
        (error) => this._loggingService.error(error, 'Failed to submit customer identification form'),
      );
    }
    return this._sendSystemMessage(
      SystemMessageTypeEnum.CustomerIdentificationFormResponse,
      this._twilioConversationsService.twilioCredentials,
      { AnonymAuthInfo: customerIdentification },
      {},
      (error) => this._loggingService.error(error, 'Failed to submit customer identification form'),
    );
  }

  public async submitChoosePhone(phoneLabel: string): Promise<ISendSystemMessageResponse> {
    const encryptedOtpPhone = this._store.getState().chat.phonesToChoose.data[phoneLabel];
    this._twilioConversationsService.otpCodeIsDeclinedInfo$.next(null);
    this.choosenPhoneForSMS = phoneLabel;

    const response = await this._sendSystemMessage(
      SystemMessageTypeEnum.ChooseOtpPhoneResponse,
      this._twilioConversationsService.twilioCredentials,
      { EncryptedOtpPhone: encryptedOtpPhone, DisplayedOtpPhone: phoneLabel },
      {},
      (error) => this._loggingService.error(error, 'Failed to submit chosen phone'),
    );

    // None of those option doesn't trigger backend, just opens chat
    if (encryptedOtpPhone === null) {
      this._routerService.openChat();
    }

    return response;
  }

  public async submitTrasfer(): Promise<ISendSystemMessageResponse> {
    const loan = this.transferInfo?.loan;
    const amount = loan && loan.Value ? loan.Value : this.transferInfo?.amount;
    return this._sendSystemMessage(
      SystemMessageTypeEnum.TransferConfirmedResponse,
      this._twilioConversationsService.twilioCredentials,
      {
        ConfirmedTransferInfo: {
          SourceId: this.transferInfo?.from.Id,
          DestinationId: this.transferInfo?.to.Id,
          Amount: amount,
        },
      },
      {},
      this._transferErrorHandler,
    );
  }

  public async submitSmsCode(smsCode: string): Promise<ISendSystemMessageResponse> {
    return this._sendSystemMessage(
      SystemMessageTypeEnum.OtpCodeResponse,
      this._twilioConversationsService.twilioCredentials,
      { OtpCode: smsCode },
      {},
      (error) => this._loggingService.error(error, 'Failed to submit sms code'),
    );
  }

  public async skipCustomerIdentificationForm(): Promise<void> {
    try {
      await this._httpClient.sendSystemMessage(
        SystemMessageTypeEnum.AuthInfoDeclineResponse,
        this._twilioConversationsService.twilioCredentials,
      );
    } catch (error) {
      this._loggingService.error(error, 'Failed to skip customer identification form');
    }
  }

  public async skipChoosePhone(): Promise<void> {
    this._routerService.openChat();

    try {
      await this._httpClient.sendSystemMessage(
        SystemMessageTypeEnum.ChooseOtpPhoneDeclineResponse,
        this._twilioConversationsService.twilioCredentials,
      );
    } catch (error) {
      this._loggingService.error(error, 'Failed to skip choose phone');
    }
  }

  public async skipSmsCode(): Promise<void> {
    try {
      await this._httpClient.sendSystemMessage(
        SystemMessageTypeEnum.OtpCodeDeclineResponse,
        this._twilioConversationsService.twilioCredentials,
      );
    } catch (error) {
      this._loggingService.error(error, 'Failed to skip sms code');
    }
  }

  public async skipTransfer(): Promise<void> {
    try {
      await this._httpClient.sendSystemMessage(
        SystemMessageTypeEnum.TransferDeclinedResponse,
        this._twilioConversationsService.twilioCredentials,
      );
    } catch (error) {
      this._loggingService.error(error, 'Failed to skip transfer');
    }

    this._twilioConversationsService.transferResponseData$.next(null);
  }
}
