export function formatAmericanPhoneNumber(phoneNumber: string): string {
  const threeThreeFourDigitsRegexp = /(\d{0,3})(\d{0,3})(\d{0,4})/;
  const matchArray = phoneNumber.match(threeThreeFourDigitsRegexp);
  if (!matchArray) {
    return '';
  }

  const [whole, first, second, third] = matchArray;
  let formattedNumber = '';
  if (whole.length >= 1) {
    formattedNumber += `(${first}`;
  }
  if (whole.length >= 3) {
    formattedNumber += `) ${second}`;
  }
  if (whole.length >= 6) {
    formattedNumber += `-${third}`;
  }
  return formattedNumber;
}
