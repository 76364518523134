import React from 'react';
import styles from './stepper.module.css';
import classnames from 'classnames';

type Props = {
  currentIndex: number;
  itemsLength: number;
};

const isActiveStep = (index: number, currentIndex: number): boolean => index === currentIndex;
const isGrayStep = (index: number, currentIndex: number): boolean =>
  index === currentIndex + 1 || index === currentIndex + 2 || index === currentIndex - 1 || index === currentIndex - 2;
const isLightStep = (index: number, currentIndex: number): boolean =>
  index === currentIndex + 3 || index === currentIndex - 3;
const isLightestStep = (index: number, currentIndex: number): boolean =>
  !isActiveStep(index, currentIndex) && !isGrayStep(index, currentIndex) && !isLightStep(index, currentIndex);

export const Stepper: React.FunctionComponent<Props> = ({ currentIndex, itemsLength }) => (
  <div className={styles.stepper}>
    {[...new Array(itemsLength)].map((i, index) => (
      <div
        key={index}
        className={classnames({
          [styles['active-step']]: isActiveStep(index, currentIndex),
          [styles['gray-step']]: isGrayStep(index, currentIndex),
          [styles['light-gray-step']]: isLightStep(index, currentIndex),
          [styles['lightest-gray-step']]: isLightestStep(index, currentIndex),
        })}
      />
    ))}
  </div>
);
