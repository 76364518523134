// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loading-message__root--feziV {\n  position: relative;\n  padding-bottom: 4px;\n  padding-top: 4px;\n  padding-left: 16px;\n  color: var(--color-text-secondary);\n  font-size: 14px;\n  line-height: 24px;\n  min-height: 24px;\n}\n\n.loading-message__root--feziV:after {\n  position: absolute;\n  content: '';\n  width: 100%;\n  height: 1px;\n  top: 0;\n  left: 0;\n  background-color: var(--color-chat-divider);\n}\n\n.loading-message__error--11Azg {\n  color: var(--color-warning);\n}\n", "",{"version":3,"sources":["webpack://./src/components/chat/loading-message/loading-message.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,kCAAkC;EAClC,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,WAAW;EACX,MAAM;EACN,OAAO;EACP,2CAA2C;AAC7C;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".root {\n  position: relative;\n  padding-bottom: 4px;\n  padding-top: 4px;\n  padding-left: 16px;\n  color: var(--color-text-secondary);\n  font-size: 14px;\n  line-height: 24px;\n  min-height: 24px;\n}\n\n.root:after {\n  position: absolute;\n  content: '';\n  width: 100%;\n  height: 1px;\n  top: 0;\n  left: 0;\n  background-color: var(--color-chat-divider);\n}\n\n.error {\n  color: var(--color-warning);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "loading-message__root--feziV",
	"error": "loading-message__error--11Azg"
};
export default ___CSS_LOADER_EXPORT___;
