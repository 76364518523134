/**
 * Adds custom "locationchange" event, whenever url is changing,
 * for "pushState", "hashchange", "popstate", "replacestate" events
 * */

export enum CustomEventEnum {
  Locationchange = 'locationchange',
}

const oldPushState = history.pushState;
const oldReplaceState = history.replaceState;

const emitLocationChangeEvent = (): void => {
  window.dispatchEvent(new Event(CustomEventEnum.Locationchange));
};
const handleHashChangeEvent = (): void => {
  emitLocationChangeEvent();
};
const handlePopStateEvent = (): void => {
  emitLocationChangeEvent();
};

export const addLocationChangeEvent = (): void => {
  history.pushState = function pushState(...args): void {
    const modifiedPushState = oldPushState.apply(this, args);
    window.dispatchEvent(new Event('pushstate'));
    window.dispatchEvent(new Event(CustomEventEnum.Locationchange));
    return modifiedPushState;
  };
  history.replaceState = function replaceState(...args): void {
    const modifiedReplaceState = oldReplaceState.apply(this, args);
    window.dispatchEvent(new Event('replacestate'));
    window.dispatchEvent(new Event(CustomEventEnum.Locationchange));
    return modifiedReplaceState;
  };

  window.addEventListener('popstate', handlePopStateEvent);
  window.addEventListener('hashchange', handleHashChangeEvent);
};

export const removeLocationChangeEvent = (): void => {
  history.pushState = oldPushState;
  history.replaceState = oldReplaceState;
  window.removeEventListener('popstate', handlePopStateEvent);
  window.removeEventListener('hashchange', handleHashChangeEvent);
};
