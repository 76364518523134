import { Nullable } from '@models/nullable.type';
import { ITransferAccount, ITransferAction, ITransferResponse } from '@services/livechat/livechat.models';
import { toLocaleDecimalString } from '@utils/helpers';
import { ITransferFields, ITransferLoanField } from './transfer-form.interface';

export const isDisabledTransferForm = (fields: ITransferFields, loanIsRequired: boolean): boolean => {
  const transferFrom = fields.from?.state?.Id;
  const transferTo = fields.to?.state?.Id;
  const amount = formatStringAmountToFloat(fields.amount.value);
  const isDisabledByLoan = loanIsRequired ? !fields.loan.value?.Value && !amount : !amount;

  return !transferTo || !transferFrom || isDisabledByLoan;
};

export const getAmountHiddenByLoans = (loan?: ITransferLoanField): boolean => {
  const loanAmount = String(loan?.value?.Value || '');
  const isHidden = !!(loan?.list.length && (!loan?.value || loanAmount));

  return isHidden;
};

export const getDestinationBySource = (
  accounts?: Nullable<ITransferResponse>,
  fromId?: Nullable<string>,
): ITransferAccount[] => {
  return (accounts?.DestinationAccounts || []).map((account) => {
    return { ...account, Disabled: account.Id === fromId };
  });
};

export const formatTransferToOption = (item?: ITransferAction): string => {
  if (!item?.Value) {
    return item?.Label || '';
  }

  return `${item.Label || ''} $${toLocaleDecimalString(item.Value, 2)}`;
};

export const formatAmountByZero = (prevValue: string, nextValue: string): string => {
  let result = nextValue;

  if (nextValue === '$.') {
    result = '$0.';
  } else if (nextValue === '$0' && prevValue === '$0.') {
    result = '';
  }

  return result;
};

export const formatStringAmountToFloat = (value: string): number =>
  parseFloat(value.replace('$', '').replaceAll(',', '').replaceAll('-', ''));
