import { createContext } from 'react';
import axios from 'axios';
import { HTTPClient } from '@api/http-client';
import { BotcopyService } from '@services/botcopy/botcopy.service';
import { BrandingService } from '@services/branding/branding.service';
import { FullHeightService } from '@services/full-height/full-height.service';
import { LiveChatService } from '@services/livechat/live-chat.service';
import { LoggingService } from '@services/logging/logging.service';
import { RouterService } from '@services/router/router.service';
import { SessionService } from '@services/session/session.service';
import { SettingsService } from '@services/settings/settings.service';
import { QuickActionService } from '@services/quick-action/quick-action.service';
import { StatusService } from '@services/status/status.service';
import { SessionStorageService } from '@services/storage/session-storage.service';
import { LocalStorageService } from '@services/storage/local-storage.service';
import { TwilioConversationsService } from '@services/twilio-conversations/twilio-conversations.service';
import { AsyncTaskQueue } from '@utils/async-task-queue';
import { TwilioClientBuilder } from '@utils/twilio-client-builder';
import { PersistentTimerService } from './persistent-timer/persistent-timer.service';
import { PrivacyPolicyService } from './privacy-policy/privacy-policy.service';
import { store } from '@store';
import { ChatActions } from '@slices/chat.slice';
import { SettingsActions } from '@slices/settings.slice';
import { isMobile } from 'react-device-detect';

const axiosInstance = axios.create();
const asyncTaskQueue = new AsyncTaskQueue();
const fullHeightService = new FullHeightService(window);
const botcopyService = new BotcopyService(window, document, isMobile);
const sessionStorageService = new SessionStorageService(window.sessionStorage);
const httpClient = new HTTPClient(axiosInstance, window, sessionStorageService);
const localStorageService = new LocalStorageService(window.localStorage);
const settingsService = new SettingsService(httpClient, sessionStorageService, store, SettingsActions);
const privacyPolicyService = new PrivacyPolicyService(localStorageService, settingsService);
const loggingService = new LoggingService(httpClient, settingsService, sessionStorageService);
loggingService.init();
const sessionService = new SessionService(
  sessionStorageService,
  loggingService,
  httpClient,
  store,
  privacyPolicyService,
);
const statusService = new StatusService(
  httpClient,
  loggingService,
  sessionService,
  sessionStorageService,
  window,
  settingsService,
);
const routerService = new RouterService(sessionStorageService, settingsService, botcopyService, statusService);
const brandingService = new BrandingService(settingsService);
const quickActionService = new QuickActionService(loggingService, sessionStorageService, settingsService);
const persistentTimerService = new PersistentTimerService(sessionStorageService, window);
const twilioConversationsService = new TwilioConversationsService(
  loggingService,
  sessionService,
  sessionStorageService,
  asyncTaskQueue,
  httpClient,
  new TwilioClientBuilder(),
  settingsService,
  quickActionService,
  routerService,
  statusService,
  persistentTimerService,
  window,
  store,
  ChatActions,
  privacyPolicyService,
);
const liveChatService = new LiveChatService(
  httpClient,
  twilioConversationsService,
  loggingService,
  settingsService,
  routerService,
  statusService,
  store,
);

const services = {
  quickActionService,
  botcopyService,
  brandingService,
  fullHeightService,
  liveChatService,
  loggingService,
  privacyPolicyService,
  routerService,
  sessionService,
  settingsService,
  statusService,
  twilioConversationsService,
  localStorageService,
};

export const ServiceContext = createContext(services);
export const ServiceProvider = ServiceContext.Provider;
export default services;
