/* eslint-disable
  @typescript-eslint/ban-types,
  @typescript-eslint/no-explicit-any,
  no-underscore-dangle,
  space-before-function-paren,
*/
import { IAbstractClass } from '@models/abstract-class.interface';
import { logMethods } from './log-methods';

export type DebugStorage = Record<string, unknown>;

declare global {
  interface Window {
    __debug__: DebugStorage;
    __enableDebug: () => void;
    __disableDebug: () => void;
  }
}

type DecoratorHandler = (instance: IAbstractClass, name: string, debugStorage: DebugStorage) => void;

export const IsDebugEnabledKey = 'ac.ls.DebugDecoratorIsDebugEnabled';

export function DebugDecoratorFactory(handler: DecoratorHandler, _window: Window, storage?: DebugStorage): Function {
  _window.__debug__ = {};
  _window.__enableDebug = (): void => {
    _window.localStorage.setItem(IsDebugEnabledKey, 'true');
  };
  _window.__disableDebug = (): void => {
    _window.localStorage.setItem(IsDebugEnabledKey, 'false');
  };
  return function _Debug<T extends { new (...args: any[]): IAbstractClass }>(constructor: T): IAbstractClass & T {
    const debugStorage = storage ?? _window.__debug__;

    const savedValue = _window.localStorage.getItem(IsDebugEnabledKey);
    const isEnabled = savedValue === null ? process.env.NODE_ENV === 'development' : JSON.parse(savedValue);
    return class extends constructor {
      constructor(...args: any[]) {
        super(...args);
        if (isEnabled) {
          handler(this, constructor.name, debugStorage);
          logMethods(this, constructor);
        }
      }
    };
  };
}
