/* eslint-disable
  @typescript-eslint/ban-types,
  @typescript-eslint/no-explicit-any,
  no-console
*/
import { isPrimitive } from '@utils/is-primitive';

export function logMethods(context: any, constructor: any): void {
  const propertyNames = Object.getOwnPropertyNames(constructor.prototype);
  const methodNames = propertyNames.filter((name) => name !== 'constructor');
  methodNames.forEach((methodName) => {
    const descriptor = Object.getOwnPropertyDescriptor(constructor.prototype, methodName);
    if (descriptor) {
      const { get, set } = descriptor;
      if (get === undefined && set === undefined) {
        const originalMethod = constructor.prototype[methodName];
        constructor.prototype[methodName] = (...args: any[]): any => {
          if (args.length === 0) {
            console.log(`${constructor.name}.${methodName}()`);
          } else if (args.every(isPrimitive)) {
            console.log(`${constructor.name}.${methodName}(${args})`);
          } else {
            console.groupCollapsed(`${constructor.name}.${methodName}`);
            args.forEach((arg) => console.log(arg));
            console.groupEnd();
          }
          return originalMethod.apply(context, args);
        };
      }
    }
  });
}
