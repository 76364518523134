import { useContext, useMemo } from 'react';
import { ServiceContext } from '@services/service.provider';
import { useObservable } from '@hooks/use-observable';

const useIsChatOffline = (): boolean => {
  const { statusService } = useContext(ServiceContext);
  const isChatOnline = useObservable(statusService.isChatOnline$, true);
  return useMemo((): boolean => !isChatOnline, [isChatOnline]);
};

export { useIsChatOffline };
