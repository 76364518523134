// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".scroller__scroller--2aeo7 {\n  overflow-y: scroll;\n  max-height: 100%;\n}\n\n@media only screen and (min-width: 480px) {\n  @-moz-document url-prefix() {\n    .scroller__scroller--2aeo7 {\n      margin-right: -18px;\n    }\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/scroller/scroller.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE;IACE;MACE,mBAAmB;IACrB;EACF;AACF","sourcesContent":[".scroller {\n  overflow-y: scroll;\n  max-height: 100%;\n}\n\n@media only screen and (min-width: 480px) {\n  @-moz-document url-prefix() {\n    .scroller {\n      margin-right: -18px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scroller": "scroller__scroller--2aeo7"
};
export default ___CSS_LOADER_EXPORT___;
