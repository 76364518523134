/* eslint-disable no-underscore-dangle */
import { ISuggestion, ISuggestionIntent } from '@models/intent/intents.interface';
import { isUnknownObject } from '@guards/is-unknown-object.guard';

export function isSuggestionIntent(object: unknown): object is ISuggestionIntent {
  return (
    isUnknownObject(object) &&
    'Suggestions' in object &&
    isUnknownObject(object.Suggestions) &&
    'Suggestions_' in object.Suggestions &&
    Array.isArray(object.Suggestions.Suggestions_) &&
    object.Suggestions.Suggestions_.some((entry) => isSuggestion(entry))
  );
}

export function isSuggestion(object: unknown): object is ISuggestion {
  return isUnknownObject(object) && 'Title' in object && typeof object.Title === 'string';
}
