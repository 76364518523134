import { ISkillButton, ISkillResponse } from '@api/skills';
import { IQuickReply } from '@models/quick-reply.interface';
import { LoggingService } from '@services/logging/logging.service';
import { SessionStorageService } from '@services/storage/session-storage.service';
import { BehaviorSubject } from 'rxjs';
import { Debug } from '@utils/debug/debug.decorator';
import { SkillEnum } from '@models/skill.enum';
import { IRestMessageAttributes } from '@models/message-attributes.interface';
import { Nullable } from '@models/nullable.type';
import { SettingsService } from '@services/settings/settings.service';

@Debug
export class QuickActionService {
  public initialMessage$ = new BehaviorSubject('');
  public actions$ = new BehaviorSubject<IQuickReply[]>([]);

  constructor(
    private _loggingService: LoggingService,
    private _sessionStorageService: SessionStorageService,
    private _settingsService: SettingsService,
  ) {}

  public addSkillActions({ InitialMessage, Buttons }: ISkillResponse): void {
    this.initialMessage$.next(InitialMessage);
    if (this._settingsService.settings.IsSkillsEnabled) {
      const quickReplies = Buttons.map(this._skillToQuickReply);
      this._setActions(quickReplies);
    }
  }

  public get isDialogFlowActions(): boolean {
    return this.actions$.value.length > 0 && this.actions$.value.every((action) => action.skill);
  }

  private _skillToQuickReply(skill: ISkillButton): IQuickReply {
    return {
      text: skill.Text,
      skill: skill.Skill,
      isDisabled: !skill.IsOnline,
    };
  }

  public addBotIntentActions(quickReplies: IQuickReply[], messageAttributes: Nullable<IRestMessageAttributes>): void {
    this._setActions(quickReplies.map((reply) => this._parseBackendQuickReply(reply, messageAttributes)));
  }

  private _setActions(actions: IQuickReply[]): void {
    this.actions$.next(actions);
  }

  private _parseBackendQuickReply(
    ccQuickReply: IQuickReply,
    messageAttributes: Nullable<IRestMessageAttributes>,
  ): IQuickReply {
    const dialogFlowSkillRegexpDollar = /^\$(\d+)\s(.*)$/;
    const dialogFlowSkillRegexpPercent = /^%(\d+)\s(.*)$/;
    const dialogFlowSkillMatchArrayDollar = ccQuickReply.text.match(dialogFlowSkillRegexpDollar);
    const dialogFlowSkillMatchArrayPercent = ccQuickReply.text.match(dialogFlowSkillRegexpPercent);
    const skillStatus = messageAttributes?.skillsStatus;
    // string format: ${SkillId} {Button display name}
    if (dialogFlowSkillMatchArrayDollar) {
      const [, dialogFlowSkill, text] = dialogFlowSkillMatchArrayDollar;
      const skill = this._dialogFlowSkillToSkillEnum(dialogFlowSkill);
      return { text, skill, isDisabled: !(skillStatus?.find((s) => s.Skill == skill)?.Online ?? false) };
    } else if (dialogFlowSkillMatchArrayPercent) {
      const [, buttonId, text] = dialogFlowSkillMatchArrayPercent;
      return { text, buttonId: `%${buttonId}`, isDisabled: false };
    } else {
      return ccQuickReply;
    }
  }

  private _dialogFlowSkillToSkillEnum(skill: string): SkillEnum {
    switch (skill.toLowerCase()) {
      case '1': {
        return SkillEnum.Agent;
      }
      case '2': {
        return SkillEnum.ExternalAgent;
      }
      case '3': {
        return SkillEnum.AnyAgent;
      }
      default: {
        this._loggingService.error(`Unsupported skill from dialogflow ${skill}`);
        return SkillEnum.AnyAgent;
      }
    }
  }

  public clearInitialMessage(): void {
    this.initialMessage$.next('');
  }

  public clearActions(): void {
    if (this.actions$.value.length) {
      this.actions$.next([]);
      this._sessionStorageService.remove(SessionStorageService.LiveChatSkills);
    }
  }
}
