import { Debug } from '@utils/debug/debug.decorator';

type Task = () => Promise<unknown>;

@Debug
export class AsyncTaskQueue {
  private _tasks: Task[] = [];
  private _isBusy = false;

  public add(task: Task): void {
    this._tasks.push(task);
    this._next();
  }

  private async _next(): Promise<void> {
    if (!this._isBusy) {
      const task = this._tasks.shift();
      if (task) {
        this._isBusy = true;
        await task();
        this._isBusy = false;
        this._next();
      }
    }
  }
}
