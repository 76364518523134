// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../styles/block.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".contact-info-success__success--3UIGL {\n  background-color: var(--color-primary-contrast);\n  margin-top: 16px;\n}\n\n.contact-info-success__list--2giZ- {\n  margin: 16px 0 0;\n}\n\n.contact-info-success__entry--38wMB {\n  display: flex;\n}\n\n.contact-info-success__entry--38wMB + .contact-info-success__entry--38wMB {\n  margin-top: 4px;\n}\n\n.contact-info-success__label--1CWpJ {\n  color: var(--color-text--lighten);\n  margin-right: 0.33em;\n}\n\n.contact-info-success__value--2NKbg {\n  margin: 0;\n  color: var(--color-text);\n}\n\n.contact-info-success__value--2NKbg:before {\n  content: '– ';\n}\n", "",{"version":3,"sources":["webpack://./src/components/forms/contact-info-success/contact-info-success.module.css"],"names":[],"mappings":"AAAA;EAEE,+CAA+C;EAC/C,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iCAAiC;EACjC,oBAAoB;AACtB;;AAEA;EACE,SAAS;EACT,wBAAwB;AAC1B;;AAEA;EACE,aAAa;AACf","sourcesContent":[".success {\n  composes: block from '~@styles/block.module.css';\n  background-color: var(--color-primary-contrast);\n  margin-top: 16px;\n}\n\n.list {\n  margin: 16px 0 0;\n}\n\n.entry {\n  display: flex;\n}\n\n.entry + .entry {\n  margin-top: 4px;\n}\n\n.label {\n  color: var(--color-text--lighten);\n  margin-right: 0.33em;\n}\n\n.value {\n  margin: 0;\n  color: var(--color-text);\n}\n\n.value:before {\n  content: '– ';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"success": "contact-info-success__success--3UIGL " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["block"] + "",
	"list": "contact-info-success__list--2giZ-",
	"entry": "contact-info-success__entry--38wMB",
	"label": "contact-info-success__label--1CWpJ",
	"value": "contact-info-success__value--2NKbg"
};
export default ___CSS_LOADER_EXPORT___;
