import { AxiosRequestConfig } from 'axios';
import { Subject } from 'rxjs';
import { HTTPClient } from '@api/http-client';
import { Nullable } from '@models/nullable.type';
import { LoggingService } from '@services/logging/logging.service';
import { SessionStorageService } from '@services/storage/session-storage.service';
import { Debug } from '@utils/debug/debug.decorator';
import { ITwilioCredentials } from '@api/session';
import { SeparatedChatModeEnum } from '@models/chat-mode.enum';
import { OfmSliceActions } from '@slices/ofm.slice';
import { IAuthorizationData } from '@utils/handle-ofm-token-update';
import { Store } from '@reduxjs/toolkit';
import { PrivacyPolicyService } from '@services/privacy-policy/privacy-policy.service';
import { isInIframe } from '@utils/is-in-iframe';

@Debug
export class SessionService {
  private readonly _authHeaderInterceptor = this._addAuthHeader.bind(this);
  private _tokenExpireTimeMs = 0;
  private _accessToken: Nullable<string>;
  private _refreshToken: Nullable<string>;
  private _isActive = false;
  public readonly requestSessionHandler = this._onRequestSession.bind(this);
  public readonly onTokenRefreshed$ = new Subject<void>();
  public readonly onStartSession$ = new Subject<void>();
  public readonly onEndSession$ = new Subject<void>();

  constructor(
    private _sessionStorageService: SessionStorageService,
    private _loggingService: LoggingService,
    private _httpClient: HTTPClient,
    private _store: Store,
    private _privacyPolicyService: PrivacyPolicyService,
  ) {
    this._accessToken = null;
    this._refreshToken = null;
    this._tokenExpireTimeMs = 0;
    this._httpClient.addRequestInterceptor(this._authHeaderInterceptor);

    if (!isInIframe()) {
      this.init();
    }
  }

  private _init(): void {
    if (this._refreshToken && this._accessToken && this.isTokenValid()) {
      this._startSessionUntil(this._accessToken, this._refreshToken, this._tokenExpireTimeMs);
    }
  }

  public init(): void {
    this._accessToken = this._sessionStorageService.get(SessionStorageService.AccessToken);
    this._refreshToken = this._sessionStorageService.get(SessionStorageService.RefreshToken);
    this._tokenExpireTimeMs = this._sessionStorageService.get(SessionStorageService.TokenExpireTimeMs) ?? 0;
    this._init();
  }

  public get isActive(): boolean {
    return this._isActive;
  }

  public set isActive(value: boolean) {
    if (value === this._isActive) {
      return;
    }
    this._isActive = value;
    if (value) {
      this.onStartSession$.next();
    } else {
      this.onEndSession$.next();
    }
  }

  private async _onRequestSession(mode?: SeparatedChatModeEnum): Promise<Nullable<ITwilioCredentials>> {
    const { ofm } = this._store.getState();
    try {
      const {
        Token,
        RefreshToken,
        TokenExpirationTimeoutInSeconds,
        IsPrivacyPolicyAccepted: isPrivacyPolicyAccepted,
        ...credentials
      } = ofm.isAuth
        ? await this._httpClient.authSession(ofm.authorizationData, mode)
        : await this._httpClient.session(mode);

      this._privacyPolicyService.init(ofm.isAuth, isPrivacyPolicyAccepted);
      this._startSessionUntil(Token, RefreshToken, Date.now() + TokenExpirationTimeoutInSeconds * 1000);

      if ((ofm.authorizationData as IAuthorizationData)?.anonymousToken) {
        this._removeOfmAnonymousToken();
      }
      return credentials;
    } catch (error) {
      this._loggingService.error(error, 'Failed to create token');
      this.onStartSession$.error(error);
    }

    return null;
  }

  public closeSession(): void {
    this._accessToken = null;

    this._httpClient.removeSessionIdHeader();

    this._sessionStorageService.remove(SessionStorageService.AccessToken);
    this._sessionStorageService.remove(SessionStorageService.RefreshToken);
    this._sessionStorageService.remove(SessionStorageService.TokenExpireTimeMs);

    this._tokenExpireTimeMs = 0;
    this.isActive = false;
  }

  public isTokenValid(): boolean {
    return this._tokenExpireTimeMs - Date.now() > 5000; // 5s reserve
  }

  private _startSessionUntil(accessToken: string, refreshToken: string, accessTokenExpireTimeMs: number): void {
    this._refreshToken = this._sessionStorageService.save(SessionStorageService.RefreshToken, refreshToken);
    this._setToken(accessToken, accessTokenExpireTimeMs);
    this.isActive = true;
  }

  private _setToken(accessToken: string, accessTokenExpireTimeMs: number): void {
    this._accessToken = this._sessionStorageService.save(SessionStorageService.AccessToken, accessToken);
    this._tokenExpireTimeMs = this._sessionStorageService.save(
      SessionStorageService.TokenExpireTimeMs,
      accessTokenExpireTimeMs,
    );
  }

  private async _addAuthHeader(request: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    if (HTTPClient.isAuthorizedRequest(request)) {
      const isTokenValid = this.isTokenValid();
      if (!isTokenValid && this._refreshToken) {
        const { Token, TokenExpirationTimeoutInSeconds } = await this._httpClient.refreshToken(this._refreshToken);
        if (Token && TokenExpirationTimeoutInSeconds) {
          this._setToken(Token, Date.now() + TokenExpirationTimeoutInSeconds * 1000);
          this.onTokenRefreshed$.next();
        } else {
          // it will fire onEndSession$ triggers endChat in TwilioConversationsService
          this.isActive = false;
          this.onTokenRefreshed$.error(new Error('Failed to update token, restarting session'));
          throw new Error('Failed to update token, restarting session');
        }
      }
      // Update current request token, because it still have the old token
      request.headers.common[HTTPClient.AuthHeaderKey] = this._accessToken;
    } else {
      request.headers.common[HTTPClient.TenantIdHeaderKey] = this._httpClient.tenantId;
    }
    return request;
  }

  private _removeOfmAnonymousToken(): void {
    try {
      const authData = this._sessionStorageService.get(SessionStorageService.OfmAuthorizedData) as IAuthorizationData;
      authData.anonymousToken = undefined;

      this._sessionStorageService.save(SessionStorageService.OfmAuthorizedData, authData);
      this._store.dispatch(OfmSliceActions.setOfmAuthorizationData({ authorizationData: authData }));
    } catch (error) {
      console.error('Cannot remove ofm anonymous token');
    }
  }
}
