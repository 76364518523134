import { BehaviorSubject } from 'rxjs';
import { RoutesEnum } from '@components/router/routes.enum';
import { BotcopyService } from '@services/botcopy/botcopy.service';
import { SettingsService } from '@services/settings/settings.service';
import { SessionStorageService } from '@services/storage/session-storage.service';
import { Nullable } from '@models/nullable.type';
import { Debug } from '@utils/debug/debug.decorator';
import { StatusService } from '@services/status/status.service';
import { ChatModeEnum, SeparatedChatModeEnum } from '@models/chat-mode.enum';

@Debug
export class RouterService {
  private _isWebView = true;
  public isAppOpen$ = new BehaviorSubject(false);
  public route$ = new BehaviorSubject(RoutesEnum.Chat);

  constructor(
    private _sessionStorageService: SessionStorageService,
    private _settingsService: SettingsService,
    private _botcopyService: BotcopyService,
    private _statusService: StatusService,
  ) {}

  public init(isWebView: boolean): void {
    this._isWebView = isWebView;
    if (!this.isMenuActive && !isWebView) {
      this.isAppOpen$.next(true);
    }
    this._setRoute(this._initialRoute);
  }

  public get isMenuActive(): boolean {
    const isSeparatedModeIsActive =
      this._settingsService.settings.Mode === ChatModeEnum.SeparatedLiveChatBot &&
      this._settingsService.settings.LiveChatMenuButton.IsVisible &&
      this._settingsService.settings.LiveChatBotMenuButton.IsVisible;

    return (
      (this._botcopyService.isEnabled ||
        this._settingsService.settings.ContactUsMenuButton.IsVisible ||
        this._settingsService.settings.PhoneUsMenuButton.IsVisible ||
        isSeparatedModeIsActive) &&
      this._isWebView
    );
  }

  private get _initialRoute(): RoutesEnum {
    const savedRoute = this._sessionStorageService.get<Nullable<RoutesEnum>>(SessionStorageService.Route);
    const isRestoringRoute = savedRoute !== null && this._statusService.isOnline;

    return isRestoringRoute ? savedRoute : this.firstInitialRoute;
  }

  public setFirstInitialRoute(): void {
    this._setRoute(this.firstInitialRoute);
  }

  public get firstInitialRoute(): RoutesEnum {
    return this.isMenuActive ? RoutesEnum.Menu : RoutesEnum.Chat;
  }

  public get isChatOpen(): boolean {
    return this.isAppOpen$.value && this.route$.value === RoutesEnum.Chat;
  }

  public openTransferForm(): void {
    this._setRoute(RoutesEnum.TransferForm);
  }

  public openTransferConfirmation(): void {
    this._setRoute(RoutesEnum.TransferConfirmation);
  }

  public openTransferSuccess(): void {
    this._setRoute(RoutesEnum.TransferSuccess);
  }

  public openApp(): void {
    this.isAppOpen$.next(true);
  }

  public closeApp(): void {
    this.isAppOpen$.next(false);
  }

  public openMenu(): void {
    this._setRoute(RoutesEnum.Menu);
  }

  public openChat(separatedMode?: SeparatedChatModeEnum): void {
    if (separatedMode) {
      this._settingsService.setSeparatedChatMode(separatedMode);
    }
    this._setRoute(RoutesEnum.Chat);
  }

  public openRating(): void {
    this._setRoute(RoutesEnum.Rating);
  }

  public openCustomerIdentificationForm(): void {
    this._setRoute(RoutesEnum.CustomerIdentificationForm);
  }

  public openChoosePhone(): void {
    this._setRoute(RoutesEnum.ChoosePhone);
  }

  public openSmsCodeForm(): void {
    this._setRoute(RoutesEnum.SmsCodeForm);
  }

  private _setRoute(route: RoutesEnum): void {
    switch (route) {
      case RoutesEnum.TransferForm:
      case RoutesEnum.TransferConfirmation:
      case RoutesEnum.TransferSuccess:
      case RoutesEnum.ChoosePhone:
      case RoutesEnum.SmsCodeForm:
        this._sessionStorageService.save(SessionStorageService.Route, RoutesEnum.Chat);
        break;
      default:
        this._sessionStorageService.save(SessionStorageService.Route, route);
        break;
    }
    this.route$.next(route);
  }

  public getTitle(route: RoutesEnum): string {
    const { Header, LiveChatHeader } = this._settingsService.settings;
    switch (route) {
      case RoutesEnum.Menu:
        return Header;
      default:
        return LiveChatHeader;
    }
  }
}
