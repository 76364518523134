import { ICarouselIntent } from '@models/intent/intents.interface';
import { isUnknownObject } from '@guards/is-unknown-object.guard';

export function isCarouselIntent(object: unknown): object is ICarouselIntent {
  return (
    isUnknownObject(object) &&
    'CarouselSelect' in object &&
    isUnknownObject(object.CarouselSelect) &&
    'Items' in object.CarouselSelect &&
    Array.isArray(object.CarouselSelect.Items)
  );
}
