import { ONCE_PER_LIFECYCLE } from '@utils/once-per-lifecycle';
import React, { useLayoutEffect, useRef } from 'react';
import styles from './shadowdom.module.css';

type Props = {
  innerHTML: string;
  qaLocator?: string;
};

export const ShadowDOM: React.FunctionComponent<Props> = ({ innerHTML, qaLocator }) => {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      const root = ref.current.attachShadow({ mode: 'open' });
      const node = document.createElement('div');
      node.innerHTML = innerHTML;
      root.appendChild(node);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, ONCE_PER_LIFECYCLE);

  return <div className={styles.text} ref={ref} data-qa={qaLocator ?? null}></div>;
};
