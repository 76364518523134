import { useCallback, useEffect, useState } from 'react';
import { CustomEventEnum } from '@utils/add-location-change-event';
import trim from 'lodash.trim';

/**
 * Hook returns if LiveChat should be hidden on certain URL
 * @param urlsList {Array} Array of urls where the LiveChat icon should be hidden
 * @return isHidden {Boolean} - is LiveChat icon should be hidden
 */

const useHideChatBySpecificUrl = (urlsList: string[] = []): boolean => {
  const [isHidden, setIsHidden] = useState(false);

  const getCurrentPageName = (): string => {
    const startToHashAndQuestionMarkToEndRegexp = /(^(.*)#|\/?\?(.*)$)/gm;
    let lowerCaseUrl = window.location.href.toLowerCase();
    // in OFM ver 4 url build with #, for ex: http://example/#/page
    const hasHash = lowerCaseUrl.includes('#');
    if (hasHash) {
      lowerCaseUrl = lowerCaseUrl.replace(startToHashAndQuestionMarkToEndRegexp, '');
    } else {
      lowerCaseUrl = lowerCaseUrl.replace(window.location.origin, '');
      if (lowerCaseUrl.includes('?')) {
        lowerCaseUrl = lowerCaseUrl.substr(0, lowerCaseUrl.indexOf('?'));
      }
    }
    return trim(lowerCaseUrl, '/');
  };

  const handleLocationChangeCallback = useCallback(() => {
    const hasUrlToHideIcon = urlsList.some((pageUrl) => pageUrl.toLowerCase() === getCurrentPageName());
    setIsHidden(hasUrlToHideIcon);
  }, [urlsList]);

  useEffect(() => {
    const handleLocationChange = (): void => {
      handleLocationChangeCallback();
    };
    handleLocationChange();
    window.addEventListener(CustomEventEnum.Locationchange, handleLocationChange);

    return (): void => {
      window.removeEventListener(CustomEventEnum.Locationchange, handleLocationChange);
    };
  }, [handleLocationChangeCallback]);

  return isHidden;
};

export { useHideChatBySpecificUrl };
