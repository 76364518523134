import { createSlice } from '@reduxjs/toolkit';

export enum ThemeEnum {
  LIGHT = 'light',
  DARK = 'dark',
}

const themeSlice = createSlice({
  name: 'Theme',
  initialState: { currentTheme: ThemeEnum.LIGHT },
  reducers: {
    setTheme: (state, action: { payload: ThemeEnum }) => {
      state.currentTheme = action.payload;
    },
  },
});

export const ThemeSliceActions = themeSlice.actions;
export const themeReducer = themeSlice.reducer;
