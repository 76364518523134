import { Nullable } from '@models/nullable.type';
import { ISkillResponse } from '@api/skills';
import { SeparatedChatModeEnum } from '@models/chat-mode.enum';

export enum SessionStateEnum {
  Bot,
  Agent,
  SearchAgent,
  Closed,
}

export interface ISessionRequest {
  BrowserInfo?: string;
  Origin?: string;
  LiveChatMode?: SeparatedChatModeEnum;
}

export interface IAuthSessionRequest {
  FirstName: string;
  LastName: string;
  UserId: string;
  OfmSessionId: string;
  OfmUserToken: string;
  ClientDeviceId: string;
  ApiKey: string;
  LiveChatMode: string;
}

export interface ITwilioCredentials {
  TwilioAccessToken: string;
  TwilioChannelId: string;
  GlobalChannelId: string;
}

export interface ISessionResponse extends ITwilioCredentials {
  Token: string;
  TokenExpirationTimeoutInSeconds: number;
  RefreshToken: string;
  IsPrivacyPolicyAccepted: boolean;
}

// actually interface here should be either Bot Intent or Live Chat Skills
export interface IJoinSessionResponse {
  InitialIntent: Nullable<IInitialIntentResponse>;
  LiveChatSkills: Nullable<ISkillResponse>;
  IsChatWithAgent: boolean;
}

export interface IStateSessionResponse {
  SessionState: SessionStateEnum;
}

export interface IInitialIntentResponse {
  Body: string;
  Attributes: string;
}
