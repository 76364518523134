import React, { useContext } from 'react';
import classnames from 'classnames';
import styles from './loading-message.module.css';
import { useBehaviorSubject } from '@hooks/use-behavior-subject';
import { useObservable } from '@hooks/use-observable';
import { ServiceContext } from '@services/service.provider';

type Props = {
  isLengthExceeded: boolean;
  isInitMessagesFetching: boolean;
};

export const LoadingMessage: React.FunctionComponent<Props> = ({ isLengthExceeded, isInitMessagesFetching }) => {
  const { twilioConversationsService, settingsService, statusService } = useContext(ServiceContext);
  const isAgentTyping = useBehaviorSubject(twilioConversationsService.isAgentTyping$);
  const loadingMessage = useBehaviorSubject(twilioConversationsService.loadingMessage$);
  const isInitialized = useBehaviorSubject(twilioConversationsService.isInitialized$);
  const isChatOnline = useObservable(statusService.isChatOnline$, false);
  const { settings } = settingsService;

  const getMessage = (): string => {
    // Ordered for priority
    if (isLengthExceeded) {
      return `The message is too long. Limit ${settings.MaxMessageLength} symbols.`;
    }

    if (loadingMessage) {
      return loadingMessage;
    }

    if (isAgentTyping) {
      return 'Typing...';
    }

    if ((!isInitialized && isChatOnline) || isInitMessagesFetching) {
      return settings.AnonymousInitializingMessage;
    }

    return '';
  };

  return getMessage() ? (
    <div
      className={classnames(styles.root, { [styles.error]: isLengthExceeded })}
      data-qa="status_message"
      role={'status'}
      aria-live="assertive"
    >
      {getMessage()}
    </div>
  ) : null;
};
