// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button__button--3wRPI {\n  padding: 0;\n  margin: 0;\n  border: 0;\n  background: none;\n  cursor: pointer;\n  box-sizing: border-box;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/button.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,SAAS;EACT,SAAS;EACT,gBAAgB;EAChB,eAAe;EACf,sBAAsB;AACxB","sourcesContent":[".button {\n  padding: 0;\n  margin: 0;\n  border: 0;\n  background: none;\n  cursor: pointer;\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "button__button--3wRPI"
};
export default ___CSS_LOADER_EXPORT___;
