import { StringByFormatEnum } from '@models/helpers';
import { Message } from '@models/message.model';
import { SystemMessageTypeEnum } from '@models/system-message-type.enum';
import { ITransferRequest } from '@services/livechat/livechat.models';

export const validateEmail = (email: string): boolean => {
  const regexp = /\S+@\S+\.\S+/;

  return regexp.test(email);
};

export const stripStringByFormat = (string: string, format?: StringByFormatEnum): string => {
  switch (format) {
    case StringByFormatEnum.Name:
      return string.replace(/[^a-zA-Z-' ]/g, '');
    case StringByFormatEnum.Digits:
      return string.replace(/\D/g, '');
    default:
      return string;
  }
};

export const toLocaleDecimalString = (number?: number, decimalRange?: number): string => {
  if (!number) {
    return '0';
  }

  const integer = Math.floor(number);
  const decimal = number - integer;

  return integer.toLocaleString('en-US') + decimal.toFixed(decimalRange).substring(1);
};

export const getDefautTransferInfo = (): ITransferRequest => ({
  from: {
    Id: null,
    Name: null,
    Type: null,
    Number: null,
    Balance: null,
    AvailableBalance: null,
    TransferActions: null,
  },
  to: {
    Id: null,
    Name: null,
    Type: null,
    Number: null,
    Balance: null,
    AvailableBalance: null,
    TransferActions: null,
  },
  amount: 0,
});

export const getIsShowStatusByTakeOver = (messages: Message[] = []): boolean => {
  const lastMessage = messages.slice(-1)?.[0];
  return lastMessage && lastMessage?.attributes?.type === SystemMessageTypeEnum.ManagerJoinsToChat;
};

export const capitalize = (str: string): string => {
  if (typeof str !== 'string') {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const hexToRgb = (hexColor: string): string => {
  const hex = hexColor.replace('#', '');
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `${r},${g},${b}`;
};
