import React, { FormEvent, useContext, useRef, useState } from 'react';
import { ServiceContext } from '@services/service.provider';
import styles from './rating.module.css';
import TextareaAutosize from 'react-textarea-autosize';
import classNames from 'classnames';
import { Scroller } from '@components/scroller';
import { Icon } from '@components/icon';
import { useFocusOnRef } from '@hooks/use-focus-on-ref';

export const Rating: React.FunctionComponent = () => {
  const { settingsService, twilioConversationsService } = useContext(ServiceContext);
  const [chosenIndex, setChosenIndex] = useState<number>();
  const [note, setNote] = useState<string>('');

  const ratingSettings = settingsService.settings.RatingSettings;
  const [question] = ratingSettings.Questions;
  const ratings = question.Rates;
  const isRatingSelected = chosenIndex !== undefined;
  const questionTitle = ratings[chosenIndex ?? ratings.length - 1].Question;

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    if (!isRatingSelected && !note) {
      return;
    }
    const rate = isRatingSelected ? ratings[chosenIndex].Rate : 0;
    twilioConversationsService.submitRating(rate, note, questionTitle);
  };

  const handleSkip = (): void => {
    twilioConversationsService.endRating();
  };

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const titleRef = useFocusOnRef(wrapperRef);

  const textForRatingButtons: { [key: number]: string } = {
    0: 'Rate chat as very unhappy. Image: Very unhappy face',
    1: 'Rate chat as unhappy. Image: Unhappy face',
    2: 'Rate chat as neutral. Image: Neutral face',
    3: 'Rate chat as satisfied. Image: Satisfied face',
    4: 'Rate chat as very satisfied. Image: Very satisfied face',
  };

  return (
    <Scroller>
      <div className={styles.wrapper} ref={wrapperRef}>
        <form className={styles.rating} onSubmit={handleSubmit}>
          <div className={styles.title} data-qa="rating_title" tabIndex={0} ref={titleRef}>
            {question.Question}
          </div>
          <div className={styles.options}>
            {ratings.map((rating, i) => (
              <button
                onClick={(): void => setChosenIndex(i)}
                className={classNames(styles.rate, { [styles.active]: i === chosenIndex })}
                key={rating.Rate}
                type="button"
                data-qa="rating_rate_button"
                aria-label={`button ${i + 1} of ${ratings.length} ${i === chosenIndex ? 'is selected' : ''}, ${
                  textForRatingButtons[i]
                }, press enter to rate or tab to navigate to the next element`}
              >
                <span className={styles.underlay} data-qa="rating_option_underlay" />
                <Icon
                  name={`rating-${rating.Rate}`}
                  sizes={[48]}
                  className={styles.icon}
                  qaLocator="rating_option_icon"
                />
              </button>
            ))}
          </div>
          <p className={styles.question} data-qa="rating_option_question">
            {questionTitle}
          </p>
          <TextareaAutosize
            placeholder="Add a comment"
            className={styles.textarea}
            onChange={(e): void => setNote(e.target.value)}
            maxLength={1024}
            maxRows={3}
            data-qa="rating_comment_textarea"
            aria-label={'text input, enter comment, use tab to navigate to the next element'}
          />
          <div className={styles.optional}>Optional</div>
          <button
            className={classNames(styles.button, styles.primary)}
            type="submit"
            aria-disabled={!isRatingSelected && !note}
            data-qa="rating_submit_button"
            aria-label={`button, submit rating, ${
              !isRatingSelected && !note
                ? 'button is disabled, please go back to the rating buttons and rate the conversation, use shit + tab to navigate'
                : 'press enter to submit'
            }`}
          >
            Rate
          </button>
          <button
            onClick={handleSkip}
            className={styles.button}
            type="button"
            data-qa="rating_skip_button"
            aria-label={'button, skip rating, press enter to skip'}
          >
            Skip
          </button>
        </form>
      </div>
    </Scroller>
  );
};
