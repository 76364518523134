import React, { useContext } from 'react';
import styles from './actions.module.css';
import { Button } from '@components/button';
import { IQuickReply } from '@models/quick-reply.interface';
import { useBehaviorSubject } from '@hooks/use-behavior-subject';
import { ServiceContext } from '@services/service.provider';

type Props = {
  actions: IQuickReply[];
  onClick: (action: IQuickReply) => void;
};

export const Actions: React.FunctionComponent<Props> = ({ actions, onClick }) => {
  const { twilioConversationsService } = useContext(ServiceContext);
  const isInitialized = useBehaviorSubject(twilioConversationsService.isInitialized$);
  return actions.length ? (
    <div className={styles.actions} data-qa="chat_quick_actions">
      {actions.map((action, index) => (
        <Button
          isAriaDisable={action.isDisabled || !isInitialized}
          onClick={(): void => {
            if (action.isDisabled || !isInitialized) {
              return;
            }
            onClick(action);
          }}
          key={index.toString()}
          type="button"
          compact
          narrow
          qaLocator={`quick_action_button_${index}`}
          ariaLabel={`action button ${index + 1} of ${actions.length}, ${action.text}, ${
            action.isDisabled || !isInitialized
              ? 'this action is disabled, use alt + arrows to navigate to the next element'
              : 'press enter to send as a message'
          }`}
          tabIndex={-1}
          dataType="message"
        >
          {action.text}
        </Button>
      ))}
    </div>
  ) : null;
};
