export const sendGAEvent = (category: string, action: string, label?: string, value?: number): void => {
  if (window.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  }
};

export const initGA = async (settingId?: string): Promise<void> => {
  const measurementId = window.document.currentScript?.dataset.measurementId || settingId;
  if (measurementId) {
    const scriptGA = document.createElement('script');
    scriptGA.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;

    const scriptGAInit = document.createElement('script');
    scriptGAInit.text = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${measurementId}')`;

    document.head.appendChild(scriptGA);
    document.body.appendChild(scriptGAInit);
  }
};
