/* eslint-disable no-console */
import { SessionStorageService } from '@services/storage/session-storage.service';
import { storageServicePrefix } from '@services/storage/storage.service';
import { isInIframe } from './is-in-iframe';

interface ICustomEvent {
  data: {
    eventName: string;
    value: unknown;
  };
}

const iframeGracePeriodStart = 'as.cc.iframeGracePeriodStart';
const iframeGracePeriodEnd = 'as.cc.iframeGracePeriodEnd';
const gracePeriodKey = `${storageServicePrefix}${SessionStorageService.GracePeriod}`;

const restoreIframeGracePeriod = (callback: (timeout: number) => void): void => {
  if (isInIframe()) {
    subscribeEnd(callback);

    window.parent.postMessage({ eventName: iframeGracePeriodStart }, '*');
  } else {
    subscribeStart();
  }
};

const subscribeStart = (): void => {
  window.addEventListener('message', (event: ICustomEvent): void => {
    if (event.data?.eventName === iframeGracePeriodStart) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const frames = window.frames as any;
      const gracePeriodMsStr = sessionStorage.getItem(gracePeriodKey);
      const gracePeriodMs = Number(gracePeriodMsStr);
      frames.livechatiframe.postMessage({ eventName: iframeGracePeriodEnd, value: gracePeriodMs }, '*');
    }
  });
};

const subscribeEnd = (callback: (timeout: number) => void): void => {
  window.addEventListener('message', (event: ICustomEvent): void => {
    if (event.data?.eventName === iframeGracePeriodEnd && event.data.value) {
      callback(event.data.value as number);
    }
  });
};

export { restoreIframeGracePeriod };
