import React, { KeyboardEvent, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import styles from './select.module.css';
import { FormField } from '@ast/magma/components/formfield';
import { Input } from '@ast/magma/components/input';
import AngleDown from '@ast/magma/components/icon/icons/AngleDown';
import AngleUp from '@ast/magma/components/icon/icons/AngleUp';
import classNames from 'classnames';
import { Nullable } from '@models/nullable.type';

interface ISelect<T> {
  options: T[];
  option: Nullable<T>;
  label: string;
  onSelect(value: T): void;
  renderOption?: (value: T, index: number, total: number) => ReactNode;
  renderSelectedOption?: (value: T) => ReactNode;
  optionDisabled?: (value: T) => boolean;
  isInvalid?: boolean;
  qaLocator?: string;
}

export function Select<T>({
  option,
  options,
  onSelect,
  renderSelectedOption,
  renderOption,
  optionDisabled,
  label,
  isInvalid,
  qaLocator,
}: ISelect<T>): JSX.Element {
  const inputRef: React.Ref<HTMLInputElement & HTMLTextAreaElement> = useRef(null);
  const dropdownRef: React.Ref<HTMLDivElement> = useRef(null);
  const [isOpened, setIsOpened] = useState(false);
  const [isInArea, setIsInArea] = useState(false);
  const isBoundClickCloseAllowed = useRef<boolean>(true);
  const boundClickCloseTimeout = useRef<NodeJS.Timeout | null>(null);

  const value = useMemo(() => {
    if (renderSelectedOption && option) {
      return renderSelectedOption(option);
    } else {
      return option;
    }
  }, [renderSelectedOption, option]) as unknown as string;

  const clickHandler = (): void => {
    if (isOpened) {
      inputRef?.current?.blur();
    } else {
      setIsOpened(true);

      if (boundClickCloseTimeout.current) {
        clearTimeout(boundClickCloseTimeout.current);
      }

      isBoundClickCloseAllowed.current = false;
      boundClickCloseTimeout.current = setTimeout(() => {
        isBoundClickCloseAllowed.current = true;
      }, 100);
    }
  };

  const renderOptionHandler = (value: T, index: number, total: number): T | ReactNode => {
    if (renderOption) {
      return renderOption(value, index, total);
    } else {
      return <div className={styles.option}>{value}</div>;
    }
  };

  const onEnterArea = (): void => {
    setIsInArea(true);
  };

  const onLeaveArea = (): void => {
    setIsInArea(false);
  };

  const arrowClickHandler = (): void => {
    if (!isOpened) {
      inputRef.current?.focus();
    }
    inputRef.current?.click();
  };

  useEffect(() => {
    const reset = (): void => {
      if (isBoundClickCloseAllowed.current && isOpened) {
        setIsOpened(false);
      }
    };
    document.addEventListener('click', reset);
    document.addEventListener('keydown', onKeyDown);

    if (isOpened) {
      (dropdownRef.current?.querySelector('[data-option=enabled]') as HTMLDivElement)?.focus();
    }

    return (): void => {
      document.removeEventListener('click', reset);
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [isOpened]);

  const onKeyDown = (e: { key: string; code: string }): void => {
    if ((e?.key === 'Enter' || e?.code === 'Enter') && inputRef?.current?.matches(':focus-within')) {
      arrowClickHandler();
    }
  };
  return (
    <div data-qa={qaLocator} className={styles.templatesField}>
      <FormField className={styles.field} reserveErrorHeight={false}>
        <Input
          aria-invalid={isInvalid}
          value={value || ''}
          ref={inputRef}
          onClick={clickHandler}
          readOnly
          className={styles.input}
          maxLength={200}
          label={label}
          aria-label={label}
          trailingChild={<div onClick={arrowClickHandler}>{isOpened ? <AngleUp /> : <AngleDown />}</div>}
        />
      </FormField>
      {isOpened && (
        <div
          ref={dropdownRef}
          className={classNames(styles.dropdown, { [styles.isOpened]: isOpened })}
          onPointerEnter={onEnterArea}
          onPointerLeave={onLeaveArea}
        >
          <>
            {options.map((item, index) => {
              const isDisabled = optionDisabled && optionDisabled(item);
              const isSelected = item === option && !isInArea;

              return (
                <div
                  className={styles.listItem}
                  key={index}
                  onClick={(event): void => {
                    if (!isDisabled) {
                      onSelect(item);
                      setTimeout(() => inputRef.current?.focus());
                    } else {
                      event.stopPropagation();
                      event.preventDefault();
                    }
                  }}
                  onKeyDown={(event: KeyboardEvent<HTMLDivElement>): void => {
                    if (event.key === 'Enter') {
                      event.stopPropagation();
                      event.preventDefault();

                      if (!isDisabled) {
                        onSelect(item);
                        setTimeout(() => inputRef.current?.focus());
                        arrowClickHandler();
                      }
                    }
                  }}
                >
                  {renderOptionHandler(item, index, options.length)}
                  <div
                    className={classNames(styles.listItemBackground, {
                      [styles.selected]: isSelected,
                      [styles.disabled]: isDisabled,
                    })}
                  />
                </div>
              );
            })}
          </>
        </div>
      )}
    </div>
  );
}
