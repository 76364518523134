import React from 'react';
import styles from './loader.module.css';

export const Loader: React.FunctionComponent<{ isLoading: boolean }> = ({ isLoading }) => {
  return isLoading ? (
    <div className={styles.wrapper}>
      <span className={styles.loader}></span>
    </div>
  ) : null;
};
