import { BehaviorSubject, Subject } from 'rxjs';
import botcopyStyles from '!!raw-loader!@styles/botcopy-redefine.raw.css';
import { Debug } from '@utils/debug/debug.decorator';

interface ICustomEvent extends Event {
  detail?: {
    type: string;
  };
}

// TODO definitely typed botcopy
declare global {
  interface Window {
    Botcopy: {
      openWindow: () => void;
      closeWindow: () => void;
    };
  }
}

@Debug
export class BotcopyService {
  private _botcopyEventHandler = this._onBotcopyEvent.bind(this);
  public static readonly EventName = 'botcopy-events';
  public static readonly Styles = botcopyStyles;

  public isReady$ = new BehaviorSubject(false);
  public onClose$ = new Subject<void>();

  constructor(private _window: Window, private _document: Document, public isMobile = false) {}

  public get isEnabled(): boolean {
    const supportedEmbedderId = 'd7lcfheammjct';

    const nodeById = this._document.querySelector(`#botcopy-embedder-${supportedEmbedderId}`);
    const nodeByDataAttribute = this._document.querySelector(`[data-embedderid='${supportedEmbedderId}']`);

    return Boolean(nodeById) || Boolean(nodeByDataAttribute);
  }

  public get isOpen(): boolean {
    return !!this._document
      .getElementById('botcopy-widget-root')
      ?.shadowRoot?.querySelector('.botcopy--bot-chat-window');
  }

  public init(): void {
    if (this.isEnabled) {
      this._window.addEventListener(BotcopyService.EventName, this._botcopyEventHandler);
    }
  }

  public dispose(): void {
    if (this.isEnabled) {
      this._window.removeEventListener(BotcopyService.EventName, this._botcopyEventHandler);
    }
  }

  public openWindow(): void {
    if (this.isReady$.value) {
      try {
        this._window.Botcopy.openWindow();
      } catch (error) {
        console.error(error);
        this.onClose$.next();
      }
    }
  }

  public closeWindow(): void {
    if (this.isReady$.value) {
      this._closeBotSafely();
    }
  }

  private _closeBotSafely(): void {
    try {
      this._window.Botcopy.closeWindow();
    } catch (error) {
      console.error(error);
    }
  }

  private _onBotcopyEvent(e: ICustomEvent): void {
    switch (e.detail?.type) {
      case 'bc-initialized':
        this._appendStyles();
        this.isReady$.next(true);
        if (this.isMobile && this.isOpen) {
          this._closeBotSafely();
        }
        break;
      case 'bc-window-close':
        this.onClose$.next();
        break;
    }
  }

  private _appendStyles(): void {
    const botStyles = this._document.createElement('style');
    botStyles.innerHTML = BotcopyService.Styles;
    this._document.getElementById('botcopy-widget-root')?.shadowRoot?.appendChild(botStyles);
  }
}
