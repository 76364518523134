interface ISortable {
  index: number;
}

export function sortByIndex(a: ISortable, b: ISortable): number {
  if (a.index > b.index) {
    return 1;
  }
  if (a.index < b.index) {
    return -1;
  }
  return 0;
}
