import React from 'react';
import styles from './info-message.module.css';
import { AbstractStatusEnum } from '@models/abstract-status.enum';
import classNames from 'classnames';
import { Icon } from '@components/icon';

type Props = {
  type: AbstractStatusEnum;
  message: string;
};

export const InfoMessage: React.FunctionComponent<Props> = ({ type, message }) => (
  <div className={classNames(styles['info-message'], styles[type])}>
    <Icon name={type} sizes={[24]} className={styles.icon} />
    <div className={styles.content}>{message}</div>
  </div>
);
