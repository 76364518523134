import { RefObject, useEffect, useState } from 'react';

export const useArrowsToNavigate = (scrollRef: RefObject<HTMLElement>): (() => void) => {
  const [counter, setCounter] = useState(0);
  const updateCounter = (count: number): void => {
    let cnt = 0;
    const list = scrollRef?.current?.querySelectorAll('[data-message="message"]') as NodeList;

    if (list) {
      if (count > list.length - 1) {
        cnt = 0;
      } else if (count < 0) {
        cnt = list.length - 1;
      } else {
        cnt = count;
      }
    }
    setCounter(cnt);
    if (list[cnt]) {
      (list[cnt] as HTMLElement).focus();
    }
  };
  const onKeyDown = (e: KeyboardEvent): void => {
    switch (e.key) {
      case 'ArrowUp':
      case 'ArrowLeft':
        updateCounter(counter - 1);
        break;
      case 'ArrowDown':
      case 'ArrowRight':
        updateCounter(counter + 1);
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return (): void => {
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  const resetCounter = (): void => {
    setCounter(-1);
  };

  return resetCounter;
};
