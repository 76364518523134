import React from 'react';
import styles from './button.module.css';
import classNames from 'classnames';

type Props = {
  type?: 'button' | 'submit' | 'reset';
  primary?: boolean;
  compact?: boolean;
  narrow?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  disabled?: boolean;
  qaLocator?: string;
  ariaLabel?: string;
  tabIndex?: number;
  dataType?: string;
  isAriaDisable?: boolean;
};

export const Button: React.FunctionComponent<Props> = ({
  type = 'button',
  primary = false,
  compact = false,
  narrow = false,
  onClick,
  children,
  icon = null,
  disabled = false,
  qaLocator,
  ariaLabel,
  tabIndex = 0,
  dataType,
  isAriaDisable,
}) => {
  const className = classNames(styles.button, {
    [styles.disabled]: disabled,
    [styles.disabled]: isAriaDisable,
    [styles.compact]: compact,
    [styles.primary]: primary,
    [styles.narrow]: narrow,
  });
  return (
    <button
      onClick={onClick}
      className={className}
      type={type}
      disabled={disabled}
      data-qa={qaLocator ?? null}
      aria-label={ariaLabel}
      tabIndex={tabIndex}
      data-message={dataType}
      aria-disabled={isAriaDisable}
    >
      {icon}
      <span className={styles.children}>{children}</span>
    </button>
  );
};
