import { RefObject, useEffect, useRef } from 'react';
export const useFocusOnRef = (
  formRef?: RefObject<HTMLFormElement | HTMLDivElement | HTMLButtonElement>,
): RefObject<HTMLHeadingElement> => {
  const ref = useRef<HTMLHeadingElement | null>(null);

  useEffect((): void => {
    const hasFocusInside = formRef?.current?.matches(':focus-within');
    if (ref && !hasFocusInside) {
      ref?.current?.focus();
    }
  });

  return ref;
};
