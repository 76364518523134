import { createSlice } from '@reduxjs/toolkit';

const ofmSlice = createSlice({
  name: 'Ofm',
  initialState: { isAuth: false, authorizationData: {}, isError: false, isConnectionError: false },
  reducers: {
    setOfmAuthState: (state, { payload }) => {
      state.isAuth = payload.isAuth;
    },
    setOfmAuthorizationData: (state, { payload }) => {
      state.authorizationData = payload.authorizationData;
    },
    setOfmError: (state, { payload }) => {
      state.isError = payload;
    },
    setConnectionError: (state, { payload }) => {
      state.isConnectionError = payload;
    },
    resetOfmAuthState: (state) => {
      state.isAuth = false;
      state.authorizationData = {};
    },
  },
});

export const OfmSliceActions = ofmSlice.actions;
export const ofmReducer = ofmSlice.reducer;
