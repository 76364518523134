import { IStateSessionResponse, SessionStateEnum } from './../../api/session';
import { HTTPClient } from '@api/http-client';
import { ICCMessage, IMessageSelector } from '@api/messages';
import { IInitialIntentResponse, IJoinSessionResponse, ITwilioCredentials } from '@api/session';
import { ISkillResponse } from '@api/skills';
import { ApiErrorCodeEnum } from '@api/specialError';
import { isSpecialErrorResponse } from '@guards/is-special-error-response.guard';
import { AuthorEnum } from '@models/author.enum';
import { Intent } from '@models/intent/intent.model';
import { OfmSliceActions } from '@slices/ofm.slice';
import { v4 as uuidv4 } from 'uuid';
import {
  IAccountsAttributes,
  IMessageAttributes,
  IOtpCodeIsDeclinedInfo,
  IPhonesToChoose,
  IRestMessageAttributes,
} from '@models/message-attributes.interface';
import { MessageStatusEnum } from '@models/message-status.enum';
import { Message } from '@models/message.model';
import { Nullable } from '@models/nullable.type';
import { SkillEnum } from '@models/skill.enum';
import {
  ChatBotHiddenSystemMessageTypes,
  HideChipsButtonSystemMessageTypes,
  LiveChatHiddenSystemMessageTypes,
  PlacingToLoadingSystemMessageTypes,
  SystemMessageTypeEnum,
} from '@models/system-message-type.enum';
import { LoggingService } from '@services/logging/logging.service';
import { PersistentTimerService } from '@services/persistent-timer/persistent-timer.service';
import { QuickActionService } from '@services/quick-action/quick-action.service';
import { RouterService } from '@services/router/router.service';
import { SessionService } from '@services/session/session.service';
import { SettingsService } from '@services/settings/settings.service';
import { StatusService } from '@services/status/status.service';
import { SessionStorageService } from '@services/storage/session-storage.service';
import { Client, Conversation, Paginator, Participant, ParticipantUpdateReason } from '@twilio/conversations';
import { AsyncTaskQueue } from '@utils/async-task-queue';
import { Debug } from '@utils/debug/debug.decorator';
import { sortByIndex } from '@utils/sort-by-index';
import { TwilioClientBuilder } from '@utils/twilio-client-builder';
import { BehaviorSubject, Subject } from 'rxjs';
import { CarouselEnum } from '@models/intent/intents.interface';
import { ITwilioMessage } from '@models/twilio-message.interface';
import { ChatModeEnum, SeparatedChatModeEnum } from '@models/chat-mode.enum';
import { ITransferResponse } from '@services/livechat/livechat.models';
import { Store } from '@store';
import { ChatActionsType } from '@slices/chat.slice';
import { IParticipantAtributes } from '@models/participant-attributes.interface';
import { getIsShowStatusByTakeOver } from '@utils/helpers';
import { PrivacyPolicyService, PrivacyPolicyStateEnum } from '@services/privacy-policy/privacy-policy.service';
import { isMobile } from 'react-device-detect';
import { restoreIframeGracePeriod } from '@utils/restore-iframe-grace-period';
import { postLogOutCreds } from '@utils/post-iframe-creds';
import { IErrorLike } from '@models/error-like.interface';

@Debug
export class TwilioConversationsService {
  private readonly _messageAddedHandler = this._onMessageAdded.bind(this);
  private readonly _typingStartedHandler = this._onTypingStarted.bind(this);
  private readonly _typingEndedHandler = this._onTypingEnded.bind(this);
  private readonly _conversationJoinedHandler = this._onConversationJoined.bind(this);
  private readonly _tokenExpiringHandler = this._refreshTwilioToken.bind(this);
  private readonly _endChatHandler = this._endChat.bind(this);
  private readonly _participantUpdatedHandler = this._onParticipantUpdated.bind(this);
  private readonly _initHandler = this.init.bind(this);
  private readonly _goingOnlineHandler = this._onGoingOnline.bind(this);
  private readonly _gracePeriodEndedHandler = this._onGracePeriodEnded.bind(this);
  private readonly _restoreGraceTimerIfPersistHandler = this._restoreGraceTimerIfPersist.bind(this);
  private _messageByClientId = new Map<string, Message>();
  private _hiddenMessages = new Set<Message>(); // some messages, for example forms are being hide after submit or resolve
  private _client: Nullable<Client> = null;
  private _conversation: Nullable<Conversation> = null;
  private _resolveMessageTimestamp: Nullable<string> = null;
  private _lastReadByAgentMessageIndex = TwilioConversationsService.InitialAgentLastReadMessageIndex;
  private _paginator: Nullable<Paginator<ITwilioMessage>> = null;
  private _skillByMessage = new WeakMap<Message, SkillEnum>();
  private _buttonIdByMessage = new WeakMap<Message, string>();
  private _gracePeriodName = SessionStorageService.GracePeriod;
  private _pingTimerId = 0;
  private _pingInterval = 3 * 60 * 1000; // 3 minutes
  private _isNeedToJoinSession = false;
  private _conversationStartedMode: SeparatedChatModeEnum | null = null;
  private _isGracePeriodEnded = false;
  private _lastAgentMessageIndex = 0;
  private _refreshTwilioTokenInterval: NodeJS.Timeout | null = null;
  private _lastResolveMessageIndex = 0;
  private _isInitInProgress = false;
  // TODO rework all flags to state machine sequence
  public static readonly InitialAgentLastReadMessageIndex = -1;
  public isAgentTyping$ = new BehaviorSubject(false);
  public messages$ = new BehaviorSubject<Message[]>([]);
  public oldMessages$ = new BehaviorSubject<Message[]>([]);
  public canResolve$ = new BehaviorSubject(false);
  public isChatResolving$ = new BehaviorSubject(false);
  public loadingMessage$ = new BehaviorSubject('');
  public isInitialized$ = new BehaviorSubject(false);
  public twilioCredentials: Nullable<ITwilioCredentials> = null;
  public phonesToChoose$ = new BehaviorSubject<IPhonesToChoose>({});
  public transferResponseData$ = new BehaviorSubject<Nullable<ITransferResponse>>(null);
  public hiddenMessages$ = new BehaviorSubject<Message[]>([]);
  public otpCodeIsDeclinedInfo$ = new BehaviorSubject<Nullable<IOtpCodeIsDeclinedInfo>>(null);
  public messagesFetching$ = new BehaviorSubject(false);
  public unreadMessagesCount$ = new BehaviorSubject(0);
  public isTransferTransactionFailed$ = new Subject<void>();

  constructor(
    private _loggingService: LoggingService,
    private _sessionService: SessionService,
    private _sessionStorageService: SessionStorageService,
    private _asyncTaskQueue: AsyncTaskQueue,
    private _httpClient: HTTPClient,
    private _twilioClientBuilder: TwilioClientBuilder,
    private _settingsService: SettingsService,
    private _quickActionService: QuickActionService,
    private _routerService: RouterService,
    private _statusService: StatusService,
    private _persistentTimerService: PersistentTimerService,
    private _window: Window,
    private _store: Store,
    private _actions: ChatActionsType,
    private _privacyPolicyService: PrivacyPolicyService,
  ) {
    this.twilioCredentials = this._sessionStorageService.get<ITwilioCredentials>(
      SessionStorageService.TwilioCredentials,
    );
    this._statusService.onGoingOnline$.subscribe(this._goingOnlineHandler);
    this._sessionService.onTokenRefreshed$.subscribe({
      next: () => this._tokenExpiringHandler(),
      error: (error: unknown) => this._showSessionInitializationErrorHandler(error),
    });
    this._sessionService.onStartSession$.subscribe({
      next: () => {
        return;
      },
      error: (error: unknown) => this._showSessionInitializationErrorHandler(error),
    });
    this._sessionService.onEndSession$.subscribe(() => this._endChatHandler());
    this._privacyPolicyService.privacyState$.subscribe((value) => this._finishInitializationByPolicy(value));
    this._restoreGraceTimerIfPersist();
    restoreIframeGracePeriod(this._restoreGraceTimerIfPersistHandler);

    this._asyncTaskQueue.add(this._initHandler);
    this._conversationStartedMode = this._sessionStorageService.get(
      SessionStorageService.UserSentFirstMessageToChatType,
    );
    this.messages$.subscribe(() => this._updateNewMessagesCount());
  }

  private async _finishInitializationByPolicy(value: PrivacyPolicyStateEnum): Promise<void> {
    const isInitialized = this.isInitialized$.getValue();
    const isAccepted = value === PrivacyPolicyStateEnum.Accepted;
    const { ofm } = this._store.getState();

    if (!isInitialized && isAccepted && ofm.isAuth && !ofm.isError) {
      await this._finishSessionInitialization();
    }
  }

  private get _messages(): Message[] {
    return Array.from(this._messageByClientId.values());
  }

  private _onGoingOnline(): void {
    this._asyncTaskQueue.add(this._initHandler);
  }

  public onComponentRendered(): void {
    if (this._isGracePeriodEnded) {
      this._asyncTaskQueue.add(this._endChatHandler);
    } else {
      this._asyncTaskQueue.add(this._initHandler);
    }
  }

  private get _isSessionStored(): boolean {
    // eslint-disable-next-line no-console
    console.log(
      'isSessionStored',
      this._sessionService.isActive,
      this.twilioCredentials,
      this._sessionStorageService.get<ITwilioCredentials>(SessionStorageService.TwilioCredentials),
    );
    return this._sessionService.isActive && this.twilioCredentials !== null;
  }

  private get _isInitAllowed(): boolean {
    if (!this._settingsService.settings) {
      return false;
    }

    const { isOnline } = this._statusService;
    const { isChatOpen } = this._routerService;

    return isChatOpen && (!this._settingsService.isLiveChat || isOnline);
  }

  public async init(): Promise<void> {
    if (this._isInitAllowed) {
      this._isInitInProgress = true;
      try {
        this._httpClient.refreshApplicationContext();
        if (!this._isSessionStored) {
          await this._initSession();
        }

        const { ofm } = this._store.getState();
        const isPrivacyAccepted =
          this._privacyPolicyService.privacyState$.getValue() === PrivacyPolicyStateEnum.Accepted;
        // If anonymous chat we don't use the back flag to pause session initialization
        if ((!ofm.isAuth || isPrivacyAccepted) && !ofm.isError) {
          await this._finishSessionInitialization();
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        this._loggingService.error(error, 'Failed to initialize');
        this._showSessionInitializationErrorHandler(error);
      } finally {
        this._isInitInProgress = false;
        this._loggingService.info('Session started');
      }
    }
  }

  private _showSessionInitializationErrorHandler(error: unknown): void {
    const { ofm } = this._store.getState();
    if (ofm.isError) {
      return;
    }

    const isNoInternet = (error as IErrorLike).message === 'Network Error';
    this._store.dispatch(OfmSliceActions.setOfmError(true));

    this._addMessage(
      new Message(
        isNoInternet
          ? this._settingsService.settings.NoInternetMessage
          : this._settingsService.settings.UnexpectedErrorMessage,
        AuthorEnum.System,
        this._nextMessageIndex,
        new Date().toISOString(),
        uuidv4(),
        null,
        null,
      ),
      false,
    );
  }

  private _showConnectionErrorMessageHandler(): void {
    const { ofm } = this._store.getState();
    if (ofm.isConnectionError) {
      return;
    }
    this._store.dispatch(OfmSliceActions.setConnectionError(true));
    this._addMessage(
      new Message(
        this._settingsService.settings.NoInternetMessage,
        AuthorEnum.System,
        this._nextMessageIndex,
        new Date().toISOString(),
        uuidv4(),
        null,
        null,
      ),
      false,
    );
  }

  private async _finishSessionInitialization(): Promise<void> {
    try {
      const [sessionResult] = await Promise.all([this._joinSession(), this._initClient()]);
      this._startSession(sessionResult);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      this._loggingService.error(error, 'Failed to finish initialize');
      this._showSessionInitializationErrorHandler(error);
    }
  }

  private async _forceInit(): Promise<void> {
    try {
      await this._initSession();
      const [sessionResult] = await Promise.all([this._joinSession(), this._initClient()]);
      this._startSession(sessionResult);
    } catch (error) {
      this._loggingService.error(error, 'Failed to initialize');
      this._showSessionInitializationErrorHandler(error);
    }
  }

  private async _initSession(): Promise<void> {
    const { ofm } = this._store.getState();
    if (ofm.isError) {
      return;
    }

    const credentials = await this._sessionService.requestSessionHandler(
      this._settingsService.separatedMode$.value || undefined,
    );
    if (credentials) {
      if (ofm.isAuth) {
        postLogOutCreds({ credentials, token: this._sessionStorageService.get(SessionStorageService.AccessToken) });
      }
      this.twilioCredentials = this._sessionStorageService.save(SessionStorageService.TwilioCredentials, credentials);
      this._httpClient.addSessionIdHeader(credentials.GlobalChannelId);
    }
    this._isNeedToJoinSession = true;
  }

  public async updateLastReadMessageIndex(lastReadMessageIndex: number): Promise<void> {
    if (
      !this._conversation ||
      (this._conversation.lastReadMessageIndex !== null &&
        this._conversation.lastReadMessageIndex > lastReadMessageIndex)
    ) {
      return;
    }

    await this._conversation.updateLastReadMessageIndex(lastReadMessageIndex);
    this._updateNewMessagesCount();
  }

  public getLastReadMessageIndex(): number | null | undefined {
    return this._conversation?.lastReadMessageIndex;
  }

  private async _closeChannel(): Promise<void> {
    this.twilioCredentials = null;
    this._sessionStorageService.remove(SessionStorageService.TwilioCredentials);
    this._unBindConversationListeners();
    await this._closeClient();
  }

  private async _closeClient(): Promise<void> {
    await this._client?.shutdown();
    this._client = null;
  }

  private async _initClient(): Promise<void> {
    if (!this._client && this.twilioCredentials) {
      try {
        this._client = await this._twilioClientBuilder.create(this.twilioCredentials.TwilioAccessToken);
        this._bindClientListeners(this._client);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        this._loggingService.error(error, 'Failed to create twilio chat client');
        this._showSessionInitializationErrorHandler(error);
      }
    }
  }

  private _bindClientListeners(client: Client): void {
    client.on('conversationJoined', this._conversationJoinedHandler);
    client.on('tokenAboutToExpire', () => this._tokenExpiringHandler());
    client.on('tokenExpired', this._tokenExpiringHandler);
  }

  private _bindConversationListeners(conversation: Conversation): void {
    if (this._conversation) {
      return;
    }
    conversation.on('messageAdded', this._messageAddedHandler);
    conversation.on('typingStarted', this._typingStartedHandler);
    conversation.on('typingEnded', this._typingEndedHandler);
    conversation.on('participantUpdated', this._participantUpdatedHandler);
    this._conversation = conversation;
  }

  private _unBindConversationListeners(): void {
    if (!this._conversation) {
      return;
    }
    this._conversation.off('messageAdded', this._messageAddedHandler);
    this._conversation.off('typingStarted', this._typingStartedHandler);
    this._conversation.off('typingEnded', this._typingEndedHandler);
    this._conversation.off('participantUpdated', this._participantUpdatedHandler);
    this._conversation = null;
  }

  // Return the state if it's last page
  public async fetchPreviousMessages(): Promise<void> {
    const areMessagesFetching = this.messagesFetching$.getValue();
    const isInitialized = this.isInitialized$.getValue();

    if (isInitialized && !areMessagesFetching && this._paginator && this._paginator.hasPrevPage) {
      try {
        this.messagesFetching$.next(true);
        this._paginator = await this._paginator.prevPage();
        if (this._paginator) {
          await this._addMessages(this._paginator.items, true);
        }
      } catch (error) {
        this._loggingService.error(error, 'Failed to fetch previous messages');
        this._showSessionInitializationErrorHandler(error);
      } finally {
        this.messagesFetching$.next(false);
      }
    }
  }

  private async _initRefreshTwilioToken(): Promise<void> {
    await this._refreshTwilioToken();
    if (!this._refreshTwilioTokenInterval) {
      this._refreshTwilioTokenInterval = setInterval(() => {
        this._refreshTwilioToken(true);
      }, 10000);
    }
  }

  private async _fetchMessages(conversation: Conversation): Promise<void> {
    try {
      this.messagesFetching$.next(true);
      await this._initRefreshTwilioToken();
      this._paginator = (await conversation.getMessages()) as Paginator<ITwilioMessage>;
      if (this._paginator) {
        if (this._settingsService.isLiveChat && this._paginator.items.length > 0) {
          this._quickActionService.clearActions();
        }

        await this._addMessages(this._paginator.items);
        // after reload we mark chat as initialized if history is loaded
        this.isInitialized$.next(true);
      }
    } catch (error) {
      this._loggingService.error(error, 'Failed to fetch messages');
      this._showSessionInitializationErrorHandler(error);
    } finally {
      this.messagesFetching$.next(false);
    }
  }

  private async _addMessages(twilioMessages: ITwilioMessage[], isPreviousMessages = false): Promise<void> {
    if (twilioMessages.length === 0) {
      return;
    }
    const twilioMessagesById = new Map(twilioMessages.map((m) => [m.body, m]));

    const messageSelectors = twilioMessages.map((message: ITwilioMessage) => ({
      Id: message.body || '',
      TimeStamp: (message.attributes as IMessageAttributes).timeStamp,
    }));

    const ccMessages = await this.requestMessages(messageSelectors);
    const { ofm } = this._store.getState();
    // ccMessages comes in reverse order
    ccMessages.reverse().forEach((ccMessage, arrayIndex: number) => {
      const twilioMessage = twilioMessagesById.get(ccMessage.Id);

      if (!twilioMessage) {
        return;
      }

      const attributes = twilioMessage.attributes as IMessageAttributes;
      const isMessageHidden = this._isMessageHidden(attributes.type) || this._isMessagePlacedToLoading(attributes.type);

      // we should process last message to update chat status
      const isLastTwilioMessage = arrayIndex === ccMessages.length - 1;

      const message = this._createMessage(twilioMessage, ccMessage);
      this._setResolveButtonStatus();

      this._loggingService.info(`Added message: ${JSON.stringify(message)}`);
      this._processSpecialSystemMessage(attributes.type, twilioMessage.index);

      if (isMessageHidden && !isLastTwilioMessage) {
        return;
      }

      if (!isMessageHidden) {
        this._addMessage(message, true);
      }

      if (isLastTwilioMessage && !isPreviousMessages) {
        this._updateChatStatus(message, attributes);
      }
    });

    this.messages$.next(this._messages);

    const lastMessage = this._messages.find((message) => message.index === this._lastMessageIndex);
    const isAuthTechnicalMessage = ofm.isAuth && lastMessage?.attributes?.hideChipsButtonInAuthMode;
    if (lastMessage && !isAuthTechnicalMessage) {
      this._addBotIntentActions(lastMessage);
    }
  }

  private _addBotIntentActions(message: Message): void {
    if (message?.intent?.quickReplies.length && message?.index >= this._lastMessageIndex) {
      this._quickActionService.addBotIntentActions(
        message.intent.quickReplies,
        message.attributes as IRestMessageAttributes,
      );
    }
  }

  private _createMessage(twilioMessage: ITwilioMessage, ccMessage: Nullable<ICCMessage>): Message {
    const twilioAttributes = twilioMessage.attributes as IMessageAttributes;
    const attributes = this._mergeMessageAttributes(twilioAttributes, ccMessage);
    const messageStatus =
      this._lastReadByAgentMessageIndex >= twilioMessage.index ? MessageStatusEnum.Read : MessageStatusEnum.Delivered;
    const author = TwilioConversationsService.GetTwilioMessageAuthor(twilioMessage);
    const clientId = author === AuthorEnum.System ? twilioMessage.body : twilioAttributes.clientMessageId;

    const message = new Message(
      ccMessage?.Body || '',
      author,
      twilioMessage.index,
      twilioAttributes.timeStamp,
      clientId || '',
      messageStatus,
      attributes,
    );

    if (attributes?.type === SystemMessageTypeEnum.ShowAccountsRequest) {
      const accounts = attributes?.accounts;
      message.intent.texts = [];

      if (accounts) {
        message.intent.carousel = {
          type: CarouselEnum.Balance,
          items: accounts,
        };
      }
    }

    return message;
  }

  private _mergeMessageAttributes(
    twilioAttributes: IMessageAttributes,
    ccMessage: Nullable<ICCMessage>,
  ): IMessageAttributes {
    if (ccMessage && ccMessage.Attributes) {
      try {
        const ccAttributes = JSON.parse(ccMessage.Attributes);
        return { ...(twilioAttributes || {}), ...(ccAttributes || {}) };
      } catch (e) {
        return twilioAttributes;
      }
    }
    return twilioAttributes;
  }

  private async _refreshTwilioToken(force = false): Promise<void> {
    if (!this._client || (!this._sessionService.isActive && !force)) {
      return;
    }
    try {
      const { TwilioAccessToken } = await this._httpClient.refreshTwilioToken();
      if (TwilioAccessToken) {
        const credentials = this._sessionStorageService.get<ITwilioCredentials>(
          SessionStorageService.TwilioCredentials,
        );
        this._sessionStorageService.save(SessionStorageService.TwilioCredentials, {
          ...credentials,
          TwilioAccessToken,
        });
        const client = await this._client.updateToken(TwilioAccessToken);
        this._client = client;
        const { ofm } = this._store.getState();
        if (ofm.isAuth) {
          postLogOutCreds({
            credentials: { ...credentials, TwilioAccessToken },
            token: this._sessionStorageService.get(SessionStorageService.AccessToken),
          });
        }
      } else {
        this._loggingService.error('Failed to refresh twilio access token');
      }
    } catch (error) {
      this._loggingService.error(error, 'Failed to refresh twilio access token');
      this._showConnectionErrorMessageHandler();
    }
  }

  public async setTyping(): Promise<void> {
    if (this._conversation) {
      await this._conversation.typing();
    }
  }

  private _isSameModeOpened(mode: SeparatedChatModeEnum): boolean {
    const isNotSeparatedMode = this._settingsService.settings.Mode !== ChatModeEnum.SeparatedLiveChatBot;
    const wasAgentChatOpenedAgain =
      this._settingsService.separatedMode$.value === SeparatedChatModeEnum.LiveChat &&
      mode === SeparatedChatModeEnum.LiveChat;

    const wasBotChatOpenedAgain =
      this._settingsService.separatedMode$.value === SeparatedChatModeEnum.LiveChatBot &&
      mode === SeparatedChatModeEnum.LiveChatBot;

    return isNotSeparatedMode || wasAgentChatOpenedAgain || wasBotChatOpenedAgain;
  }

  // https://cf.mfmnow.com/display/CB/CCVLC-3388
  public async openChatByMenuButton(clickedMode: SeparatedChatModeEnum): Promise<void> {
    const isAgentChatButtonClicked = clickedMode === SeparatedChatModeEnum.LiveChat;
    const isBotChatButtonClicked = clickedMode === SeparatedChatModeEnum.LiveChatBot;
    const isBotConversationStarted = this._conversationStartedMode === SeparatedChatModeEnum.LiveChatBot;
    const isAgentConversationStarted = this._conversationStartedMode === SeparatedChatModeEnum.LiveChat;
    const isConversationStarted = !!this._conversationStartedMode;

    if (this._isSameModeOpened(clickedMode)) {
      this._routerService.openChat();
      return;
    }

    if (isBotConversationStarted && isAgentChatButtonClicked) {
      this._routerService.openChat(SeparatedChatModeEnum.LiveChatBot);
      if (!this.canResolve$.value && !this.loadingMessage$.value && !this._quickActionService.isDialogFlowActions) {
        this.submitUserMessage({ text: this._settingsService.settings.AgentSearchMessage });
      }
      return;
    }

    if (isAgentConversationStarted && isAgentChatButtonClicked) {
      this._routerService.openChat(SeparatedChatModeEnum.LiveChat);
      return;
    }

    if (!isConversationStarted && isAgentChatButtonClicked && this._statusService.isOnline) {
      this._routerService.openChat(SeparatedChatModeEnum.LiveChat);
      this._resetChatHistoryBeforeNewSession();
      this._asyncTaskQueue.add(() => this._forceInit());
      return;
    }

    if (!isConversationStarted && isAgentChatButtonClicked && !this._statusService.isOnline) {
      this._resetChatHistoryBeforeNewSession();
      this._routerService.openChat(SeparatedChatModeEnum.LiveChat);
      return;
    }

    if (isBotConversationStarted && isBotChatButtonClicked) {
      this._routerService.openChat(SeparatedChatModeEnum.LiveChatBot);
      return;
    }

    if (isAgentConversationStarted && isBotChatButtonClicked) {
      this._routerService.openChat(SeparatedChatModeEnum.LiveChat);
      return;
    }

    if (!isConversationStarted && isBotChatButtonClicked) {
      this._routerService.openChat(SeparatedChatModeEnum.LiveChatBot);
      this._resetChatHistoryBeforeNewSession();
      this._asyncTaskQueue.add(() => this._forceInit());
    }
  }

  private _resetChatHistoryBeforeNewSession(): void {
    this._cancelPing();
    this._resolveMessageTimestamp = null;
    this._paginator = null;
    this.loadingMessage$.next('');
    this._isGracePeriodEnded = false;
    this.isInitialized$.next(false);
    this._sessionService.closeSession();
    this._messageByClientId.clear();
    this._quickActionService.clearActions();
    this._quickActionService.clearInitialMessage();
    this.messages$.next([]);
    this.oldMessages$.next([]);
  }

  private _setConversationIsStarted(): void {
    if (!this._conversationStartedMode) {
      this._conversationStartedMode = this._settingsService.separatedMode$.value;
      this._sessionStorageService.save(
        SessionStorageService.UserSentFirstMessageToChatType,
        this._conversationStartedMode,
      );
    }
  }

  public async submitUserMessage({
    text,
    skill,
    buttonId,
  }: {
    text: string;
    skill?: SkillEnum;
    buttonId?: string;
  }): Promise<void> {
    this._setConversationIsStarted();
    const message = new Message(text, AuthorEnum.User, this._nextMessageIndex);
    this._addMessage(message);

    if (skill) {
      this._skillByMessage.set(message, skill);
    }

    if (buttonId) {
      this._buttonIdByMessage.set(message, buttonId);
    }

    await this._scheduleMessage(message);
  }

  private async _scheduleMessage(message: Message): Promise<void> {
    this._quickActionService.clearActions();
    this._stopGracePeriodTimer();
    this._asyncTaskQueue.add(async () => this._sendMessage(message));
  }

  private async _onMessageSendError(message: Message, error: unknown): Promise<void> {
    if (isSpecialErrorResponse(error) && error.Error.Code === ApiErrorCodeEnum.NoChannelPermission) {
      this._asyncTaskQueue.add(this._endChatHandler);
      return;
    }

    this._loggingService.error(error, 'Failed to send message');
    message.status = MessageStatusEnum.DeliveryFailed;
    message.isRejected = true;
    this.messages$.next(this._messages);
  }

  private _addMessage(message: Message, delayUpdate = false): void {
    if (message.text || message.intent?.carousel) {
      this._messageByClientId.set(message.clientId, message);

      if (!delayUpdate) {
        this.messages$.next(this._messages);
      }
    }
  }

  private async _sendMessage(message: Message): Promise<void> {
    try {
      if (!this.twilioCredentials) {
        throw new Error('No twilio credentials found');
      }

      const body = {
        GlobalChannelId: this.twilioCredentials.GlobalChannelId,
        TwilioChannelId: this.twilioCredentials.TwilioChannelId,
        Body: message.text,
        Attributes: JSON.stringify({
          timeStamp: message.timestamp,
          isSpecialist: false,
          clientMessageId: message.clientId,
          skill: this._skillByMessage.get(message) ?? null,
          buttonId: this._buttonIdByMessage.get(message) ?? null,
        }),
      };

      const response = await this._httpClient.sendMessage(body);

      if (isSpecialErrorResponse(response)) {
        throw response;
      }

      message.status = MessageStatusEnum.Delivered;
      if (message.isRejected) {
        message.index = this._nextMessageIndex;
      }
      this.messages$.next(this._messages);
    } catch (error) {
      this._onMessageSendError(message, error);
    }
  }

  private async _joinSession(): Promise<Nullable<IJoinSessionResponse>> {
    let result = null;
    if (this._isNeedToJoinSession) {
      try {
        result = await this._httpClient.joinSession();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        this._loggingService.error(error, 'Failed to join to session');
        this._showSessionInitializationErrorHandler(error);
      } finally {
        this._isNeedToJoinSession = false;
      }
    }
    await this._ping();
    return result;
  }

  private async _stateSession(): Promise<Nullable<IStateSessionResponse>> {
    let result = null;
    try {
      result = await this._httpClient.stateSession();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      this._loggingService.error(error, 'Failed to get session state');
      this.canResolve$.next(false);
      this._showSessionInitializationErrorHandler(error);
    }
    return result;
  }

  private _startSession(response: Nullable<IJoinSessionResponse>): void {
    if (response) {
      const { InitialIntent, LiveChatSkills } = response;
      if (InitialIntent) {
        this._addBotInitialMessage(InitialIntent);
      } else if (LiveChatSkills) {
        this._sessionStorageService.save(SessionStorageService.LiveChatSkills, LiveChatSkills);
        this._quickActionService.addSkillActions(LiveChatSkills);
      }
      // At first init we can mark chat initialized if we got intent or skills.
      this.isInitialized$.next(true);

      if (response.IsChatWithAgent) {
        this.canResolve$.next(true);
      }
    } else {
      const liveChatSkills = this._sessionStorageService.get<ISkillResponse>(SessionStorageService.LiveChatSkills);
      if (liveChatSkills) {
        this._quickActionService.addSkillActions(liveChatSkills);
      }
    }
  }

  private _addBotInitialMessage({ Body, Attributes }: IInitialIntentResponse): void {
    const isChatBot = !this._settingsService.isLiveChat;
    let attributesObj: Nullable<IRestMessageAttributes> = null;

    try {
      attributesObj = JSON.parse(Attributes);
    } catch (error) {
      this._loggingService.error(error, 'Failed to parse attributes');
    }
    if (Body && (isChatBot || this._settingsService.settings.UseIntentsForLiveChat)) {
      const message = new Message(
        Body,
        AuthorEnum.Bot,
        this._nextMessageIndex,
        undefined,
        attributesObj?.clientMessageId ?? undefined,
        null,
      );
      this._addMessage(message);
      this.messages$.next(this._messages);
      this._quickActionService.addBotIntentActions(message.intent.quickReplies, attributesObj);
    }
  }

  public isLastPage(): boolean {
    return !this._paginator || !this._paginator?.hasPrevPage;
  }

  public async resentMessage(message: Message): Promise<void> {
    message.status = MessageStatusEnum.Sending;
    this.messages$.next(this._messages);

    await this._scheduleMessage(message);
  }

  public async requestMessages(MessageSelectors: IMessageSelector[]): Promise<ICCMessage[]> {
    if (!this.twilioCredentials) {
      const error = 'Do not have twilio credentials';
      this._loggingService.error(error);
      throw new Error(error);
    }

    try {
      const { Messages } = await this._httpClient.messages({
        GlobalChannelId: this.twilioCredentials.GlobalChannelId,
        TwilioChannelId: this.twilioCredentials.TwilioChannelId,
        MessageSelectors,
      });
      return Messages;
    } catch (error) {
      this._loggingService.error(error, 'Failed to get message bodies');
      this._showSessionInitializationErrorHandler(error);
      throw error;
    }
  }

  public async resolveChatAndSetGracePeriod(): Promise<void> {
    await this._resolveChat();
    this._setGracePeriodTimer();
  }

  private async _resolveChat(): Promise<void> {
    this.canResolve$.next(false);
    this._conversationStartedMode = null;
    await this._submitResolve();
    this._hideElderContactInfoFormsByIndex(this._lastMessageIndex + 1);
  }

  private async _submitResolve(): Promise<void> {
    if (!this.twilioCredentials || this.isChatResolving$.value) {
      return;
    }

    const { TwilioChannelId, GlobalChannelId } = this.twilioCredentials;
    try {
      this.isChatResolving$.next(true);
      this._loggingService.info(
        `starting resolve the chat TwilioChannelId=${TwilioChannelId}, GlobalChannelId=${GlobalChannelId}`,
      );

      if (!this._resolveMessageTimestamp) {
        const { MessageTimeStamp } = await this._httpClient.sendSystemMessage(
          SystemMessageTypeEnum.MemberManualResolve,
          this.twilioCredentials,
        );
        this._resolveMessageTimestamp = MessageTimeStamp || null;
      }

      this.loadingMessage$.next('');

      const didSpecialistAnswered = this._messages.some((message) => message.author === AuthorEnum.Specialist);
      if (this._settingsService.settings.RatingSettings.IsEnabled && didSpecialistAnswered) {
        this._routerService.openRating();
      } else {
        this._resolveMessageTimestamp = null;
      }
    } catch (error) {
      this._loggingService.error(error, 'Failed to resolve chat');
    } finally {
      this.isChatResolving$.next(false);
      this._loggingService.info(
        `finished resolving the chat TwilioChannelId=${TwilioChannelId}, GlobalChannelId=${GlobalChannelId}`,
      );
    }
  }

  public async submitRating(rate: number, comment: string, question: string): Promise<void> {
    if (this.twilioCredentials && this._resolveMessageTimestamp) {
      await this._httpClient.rate({
        MessageGlobalChannelId: this.twilioCredentials.GlobalChannelId,
        MessageTimeStamp: this._resolveMessageTimestamp,
        Ratings: [
          {
            Question: question,
            Rate: rate,
            Comment: comment,
          },
        ],
      });
    }

    this.endRating();
  }

  public async endRating(): Promise<void> {
    this._resolveMessageTimestamp = null;
    this._routerService.openChat();
  }

  private async _endChat(withClearHistory = false): Promise<void> {
    this.isInitialized$.next(false);
    this._cancelPing();
    await this._closeChannel();
    this._sessionService.closeSession();
    this._resolveMessageTimestamp = null;
    this.loadingMessage$.next('');
    this._isGracePeriodEnded = false;
    this._hideElderContactInfoFormsByIndex(this._lastMessageIndex);
    if (withClearHistory) {
      this._quickActionService.clearActions();
    }
    // we will run init in onGoingOnline subscription
    const isOnline = await this._statusService.requestStatus();
    if (!isOnline) {
      this._quickActionService.clearActions();
    }

    const messageHistory = this._messages.slice().sort(sortByIndex);
    this._messageByClientId.clear();
    this.messages$.next([]);
    this.oldMessages$.next(withClearHistory ? [] : [...this.oldMessages$.value, ...messageHistory]);
  }

  public endChat(withClearHistory = false): void {
    this._asyncTaskQueue.add(() => this._endChatHandler(withClearHistory));
  }

  private async _ping(): Promise<void> {
    if (!this._pingTimerId && this._sessionService.isActive) {
      try {
        await this._httpClient.sendSystemMessage(SystemMessageTypeEnum.Ping, this.twilioCredentials);
        this._pingTimerId = this._window.setTimeout(() => {
          this._cancelPing();
          this._ping();
        }, this._pingInterval);
      } catch (error) {
        this._loggingService.error(error, 'Failed to send ping');
      }
    }
  }

  private _cancelPing(): void {
    this._window.clearTimeout(this._pingTimerId);
    this._pingTimerId = 0;
  }

  private async _onMessageAdded(twilioMessage: ITwilioMessage): Promise<void> {
    if (this.isChatResolving$.value || !this.isInitialized$.value) {
      return;
    }

    const localReceiveMessageUtcTime = new Date().toISOString();
    const twilioMessageAttributes = twilioMessage.attributes as IMessageAttributes;
    const messageLogData = {
      sid: twilioMessage.sid,
      twilioDateCreated: twilioMessage.dateCreated,
      body: twilioMessage.body,
      clientMessageId: twilioMessageAttributes.clientMessageId,
    };
    this._loggingService.info(
      `Receive message from twilio. localReceiveMessageUtcTime: ${localReceiveMessageUtcTime}, message: ${JSON.stringify(
        messageLogData,
      )}`,
    );

    const message = await this._createMessageByTwilioMessage(twilioMessage);
    const attributes = message.attributes as IMessageAttributes;

    this._updateChatStatus(message, attributes);

    const isMessageHidden = this._isMessageHidden(attributes.type) || this._isMessagePlacedToLoading(attributes.type);

    this._setResolveButtonStatus();
    this._processSpecialSystemMessage(attributes.type, message.index);

    if (!isMessageHidden) {
      if (message.author !== AuthorEnum.User) {
        this._quickActionService.clearActions();
      }
      this._addMessage(message);
    }
    const isMessageAddedAfterInitialization =
      !this._isGracePeriodInProgress && message.author === AuthorEnum.Specialist && !this.canResolve$.value;

    if (isMessageAddedAfterInitialization) {
      this.canResolve$.next(true);
    }

    if (message.author === AuthorEnum.System) {
      await this._processSystemMessage(attributes, message);
    }

    const { ofm } = this._store.getState();
    const isAuthTechnicalMessage = ofm.isAuth && attributes.hideChipsButtonInAuthMode;

    if (!isAuthTechnicalMessage) {
      this._addBotIntentActions(message);
    }
  }

  private _updateChatStatus(message: Message, messageAttributes: IMessageAttributes): void {
    const isShowStatusByTakeOver = getIsShowStatusByTakeOver(this.messages$.value);
    if (this._isMessagePlacedToLoading(messageAttributes.type) && !isShowStatusByTakeOver) {
      this.loadingMessage$.next(message.text);
    } else if (message.author !== AuthorEnum.User) {
      this.loadingMessage$.next('');
    }
  }

  public static GetTwilioMessageAuthor(message: ITwilioMessage): AuthorEnum {
    if (message.author === 'system') {
      return AuthorEnum.System;
    }
    if ((message.attributes as IMessageAttributes).isBot) {
      return AuthorEnum.Bot;
    }
    if ((message.attributes as IMessageAttributes).isSpecialist) {
      return AuthorEnum.Specialist;
    }
    // Now we have no difference between specialist an manager for user
    if ((message.attributes as IMessageAttributes).isManager) {
      return AuthorEnum.Specialist;
    }
    return AuthorEnum.User;
  }

  private _onTypingStarted(): void {
    this.isAgentTyping$.next(true);
  }

  private _onTypingEnded(): void {
    this.isAgentTyping$.next(false);
  }

  private _isSelfIdentity(identity: string): boolean {
    return identity === this._client?.user.identity;
  }

  private _onParticipantUpdated(data: { participant: Participant; updateReasons: ParticipantUpdateReason[] }): void {
    if (this._isSelfIdentity(data.participant.identity || '')) {
      return;
    }

    const lastReadMessageIndex = data.participant?.lastReadMessageIndex;
    const participantAttributes = data.participant?.attributes as unknown as IParticipantAtributes;

    if (
      lastReadMessageIndex &&
      this._lastReadByAgentMessageIndex < lastReadMessageIndex &&
      participantAttributes?.CanReadMessages
    ) {
      this._lastReadByAgentMessageIndex = lastReadMessageIndex;

      this._messages
        .filter((m) => m.index <= this._lastReadByAgentMessageIndex && m.status !== MessageStatusEnum.DeliveryFailed)
        .forEach((m) => {
          m.status = MessageStatusEnum.Read;
        });
      this.messages$.next(this._messages);
    }
  }

  public async sendLogOutFromOfmSystemMessage(credentials: Nullable<ITwilioCredentials>, token: string): Promise<void> {
    if (!credentials) {
      return;
    }

    await this._httpClient.sendSystemMessage(
      SystemMessageTypeEnum.UserLoggedOutFromOfm,
      credentials,
      {},
      {},
      {
        'X-AST-ContactCenter-Auth-Token': token,
        'X-AST-SessionId': credentials?.GlobalChannelId,
      },
    );
    this._sessionStorageService.remove(SessionStorageService.LogOutCreds);
  }

  private async _onConversationJoined(conversation: Conversation): Promise<void> {
    this._bindConversationListeners(conversation);
    this._lastReadByAgentMessageIndex = await this._getAgentLastReadMessageIndex(conversation);
    // in authorized chat we need its history anyway
    const { ofm } = this._store.getState();
    if ((!this._isNeedToJoinSession || ofm.isAuth) && !ofm.isError) {
      await this._fetchMessages(conversation);
    }
  }

  private async _getAgentLastReadMessageIndex(conversation: Conversation): Promise<number> {
    //TODO: check that agent not participant after support member read message index
    const participants = await conversation.getParticipants();
    if (participants?.length > 1) {
      return Math.max(
        ...(participants
          .filter((p) => !this._isSelfIdentity(p.identity || ''))
          .map((p) => p.lastReadMessageIndex) as number[]),
      );
    }

    return TwilioConversationsService.InitialAgentLastReadMessageIndex;
  }

  private async _processSystemMessage(attributes: IMessageAttributes, message?: Message): Promise<void> {
    switch (attributes.type) {
      case SystemMessageTypeEnum.AuthFailedRequest:
      case SystemMessageTypeEnum.ChooseOtpPhoneFailedRequest:
      case SystemMessageTypeEnum.BamOtpCodeVerifyFailedRequest:
      case SystemMessageTypeEnum.BamOtpCodeSendFailedRequest:
      case SystemMessageTypeEnum.AuthAcceptedRequest:
      case SystemMessageTypeEnum.TransferFailedRequest:
        this._routerService.openChat();
        if (SystemMessageTypeEnum.TransferFailedRequest === attributes.type) {
          this.isTransferTransactionFailed$.next();
        }
        break;
      case SystemMessageTypeEnum.OtpCodeIsDeclinedRequest: {
        if (attributes.otpCodeIsDeclinedInfo) {
          if (attributes.otpCodeIsDeclinedInfo?.CanContinueResendOtp) {
            this.otpCodeIsDeclinedInfo$.next(attributes.otpCodeIsDeclinedInfo);
          } else {
            this._routerService.openChat();
          }
        }
        break;
      }

      case SystemMessageTypeEnum.SpecialistManualResolve:
        this._resolveMessageTimestamp = attributes.timeStamp;
        await this.resolveChatAndSetGracePeriod();
        break;
      case SystemMessageTypeEnum.ShowAccountsRequest: {
        this._setAccountsBySystemMessage(attributes, message);
        break;
      }
      case SystemMessageTypeEnum.MemberInactivityResolve:
        this.canResolve$.next(false);
        this._conversationStartedMode = null;
        this._setGracePeriodTimer();
        break;
      case SystemMessageTypeEnum.ShowTransferRequest: {
        if (attributes.showTransferInfo) {
          this.transferResponseData$.next(attributes.showTransferInfo);
        }
        this._routerService.openTransferForm();
        break;
      }
      case SystemMessageTypeEnum.CustomerIdentificationFormRequest:
        this._routerService.openCustomerIdentificationForm();
        break;
      case SystemMessageTypeEnum.TransferCompletedRequest:
        this._routerService.openTransferSuccess();
        break;
      case SystemMessageTypeEnum.ChooseOtpPhoneRequest: {
        if (attributes.otpPhones) {
          this._store.dispatch(
            this._actions.setPhonesToChoose(
              attributes.otpPhones.reduce<IPhonesToChoose>((acc, next) => {
                acc[next.PhoneToShow] = next.EncryptedPhone || null;
                return acc;
              }, {}),
            ),
          );
        }

        this._routerService.openChoosePhone();
        break;
      }
      case SystemMessageTypeEnum.OtpCodeRequest:
        this._routerService.openSmsCodeForm();
        break;
      case SystemMessageTypeEnum.WorkflowTimeout:
        if (this._settingsService.isLiveChat) {
          await this._resolveChat();
          await this._endChat();
        }
        break;
    }
  }

  private _processSpecialSystemMessage(type: SystemMessageTypeEnum, index: number): void {
    if (HideChipsButtonSystemMessageTypes.includes(type)) {
      this._quickActionService.clearActions();
    }

    switch (type) {
      case SystemMessageTypeEnum.RequestAnonymCustomerContactInfo:
      case SystemMessageTypeEnum.ResponseAnonymCustomerContactInfo:
      case SystemMessageTypeEnum.SpecialistManualResolve:
      case SystemMessageTypeEnum.MemberManualResolve:
      case SystemMessageTypeEnum.MemberInactivityResolve:
        this._hideElderContactInfoFormsByIndex(index);
        if (type === SystemMessageTypeEnum.RequestAnonymCustomerContactInfo) {
          this._updateNewMessagesCount();
        }
        break;
      case SystemMessageTypeEnum.OfmLoginNeeded: {
        const ofmInput = document.querySelector<HTMLInputElement>('[data-stable-name="UserNameInput"]');
        if (ofmInput) {
          ofmInput.focus();
        }
        if (isMobile) {
          this._routerService.closeApp();
          this._sessionStorageService.save(SessionStorageService.IsChatOpen, true);
        }

        break;
      }
    }
  }

  private _hideElderContactInfoFormsByIndex(index: number): void {
    this._messageByClientId.forEach((message) => {
      const isFormMessage = message.attributes?.type === SystemMessageTypeEnum.RequestAnonymCustomerContactInfo;
      const isElder = message.index < index;
      if (isFormMessage && isElder) {
        this._hiddenMessages.add(message);
      }
    });
    this.hiddenMessages$.next(Array.from(this._hiddenMessages.values()));
  }

  private _setAccountsBySystemMessage(attributes: IAccountsAttributes, message?: Message): void {
    this._routerService.openChat();
    if (message) {
      const newIntent = new Intent('');
      newIntent.carousel = {
        type: CarouselEnum.Balance,
        items: attributes.accounts || [],
      };
      this._addMessage({
        ...message,
        text: '',
        intent: newIntent,
      });
    }
  }

  private async _createMessageByTwilioMessage(twilioMessage: ITwilioMessage): Promise<Message> {
    const attributes = twilioMessage.attributes as IMessageAttributes;
    let ccMessage: Nullable<ICCMessage> = null;
    const { clientMessageId } = attributes;

    if (clientMessageId) {
      try {
        const [message] = await this.requestMessages([
          {
            Id: twilioMessage.body || '',
            TimeStamp: attributes.timeStamp,
          },
        ]);
        ccMessage = message;
      } catch {
        // we're catching exceptions in requestMessages
      }
    }

    return this._createMessage(twilioMessage, ccMessage);
  }

  private get _lastMessageIndex(): number {
    return this._messages.length ? Math.max(...this._messages.map((p) => p.index)) : 0;
  }

  private get _nextMessageIndex(): number {
    return this._lastMessageIndex + 1;
  }

  private _isMessagePlacedToLoading(type: SystemMessageTypeEnum): boolean {
    return PlacingToLoadingSystemMessageTypes.includes(type);
  }

  private _isMessageHidden(type: SystemMessageTypeEnum): boolean {
    const types =
      this._settingsService.isLiveChat && !this._settingsService.settings.UseIntentsForLiveChat
        ? LiveChatHiddenSystemMessageTypes
        : ChatBotHiddenSystemMessageTypes;
    return types.includes(type);
  }

  private _setGracePeriodTimer(): void {
    const timeout = this._settingsService.settings.GracePeriodSeconds * 1000;
    this._persistentTimerService.setTimer(this._gracePeriodName, this._gracePeriodEndedHandler, timeout);
  }

  private _restoreGraceTimerIfPersist(timeout?: number): void {
    this._persistentTimerService.restoreTimerIfPersist(this._gracePeriodName, this._gracePeriodEndedHandler, timeout);
  }

  private _onGracePeriodEnded(): void {
    if (this._routerService.isChatOpen) {
      this._asyncTaskQueue.add(this._endChatHandler);
    } else {
      this._isGracePeriodEnded = true;
    }
  }

  private _stopGracePeriodTimer(): void {
    this._persistentTimerService.resetTimer(this._gracePeriodName);
  }

  private get _isGracePeriodInProgress(): boolean {
    return this._persistentTimerService.isTimerRunning(this._gracePeriodName);
  }

  private async _setResolveButtonStatus(): Promise<void> {
    const sessionState = await this._stateSession();
    this.canResolve$.next(sessionState !== null && sessionState.SessionState === SessionStateEnum.Agent);
  }

  private _updateNewMessagesCount(): void {
    const lastReadMessage: Message | undefined = this.messages$.value.find(
      ({ index }) => index === this._conversation?.lastReadMessageIndex,
    );
    if (lastReadMessage) {
      const sortedMessages = this.messages$.value
        .slice()
        .filter((message) => message.status !== MessageStatusEnum.DeliveryFailed)
        .sort(sortByIndex);
      const indexOfLastReadMessage = sortedMessages.indexOf(lastReadMessage);
      const unReadMessagesCount = sortedMessages.slice(indexOfLastReadMessage + 1)?.length;
      this.unreadMessagesCount$.next(unReadMessagesCount);
    }
  }

  public cancelRequestsOnInit(): void {
    if (this._isInitInProgress) {
      this._httpClient.cancelRequests();
    }
  }
}
