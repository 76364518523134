// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes resolve__fadeIn--1Orp4 {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n\n.resolve__resolve--1FU9e {\n  background-color: var(--color-background);\n  padding: 16px 24px;\n  animation: resolve__fadeIn--1Orp4 0.5s;\n  position: relative;\n}\n\n.resolve__resolve--1FU9e:after {\n  position: absolute;\n  content: '';\n  width: 100%;\n  height: 1px;\n  bottom: 0;\n  left: 0;\n  background-color: var(--color-chat-divider);\n}\n\n.resolve__resolve--1FU9e button:hover {\n  transition: all 0.3s ease-in-out;\n  color: var(--color-primary-contrast);\n  background-color: var(--color-primary);\n  border-color: var(--color-primary);\n}\n", "",{"version":3,"sources":["webpack://./src/components/chat/resolve/resolve.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE,yCAAyC;EACzC,kBAAkB;EAClB,sCAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,WAAW;EACX,SAAS;EACT,OAAO;EACP,2CAA2C;AAC7C;;AAEA;EACE,gCAAgC;EAChC,oCAAoC;EACpC,sCAAsC;EACtC,kCAAkC;AACpC","sourcesContent":["@keyframes fadeIn {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n\n.resolve {\n  background-color: var(--color-background);\n  padding: 16px 24px;\n  animation: fadeIn 0.5s;\n  position: relative;\n}\n\n.resolve:after {\n  position: absolute;\n  content: '';\n  width: 100%;\n  height: 1px;\n  bottom: 0;\n  left: 0;\n  background-color: var(--color-chat-divider);\n}\n\n.resolve button:hover {\n  transition: all 0.3s ease-in-out;\n  color: var(--color-primary-contrast);\n  background-color: var(--color-primary);\n  border-color: var(--color-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resolve": "resolve__resolve--1FU9e",
	"fadeIn": "resolve__fadeIn--1Orp4"
};
export default ___CSS_LOADER_EXPORT___;
